import { SocialMediaServiceEnum } from '../api/client'

export function getSocialMediaServiceEnum(
    service: string
): SocialMediaServiceEnum | undefined {
    // The values on the left are from arwen.SocialMediaService.shortName
    const serviceMap: Record<string, SocialMediaServiceEnum> = {
        twitter: SocialMediaServiceEnum.Twitter,
        instagram: SocialMediaServiceEnum.Instagram,
        facebook: SocialMediaServiceEnum.Facebook,
        youtube: SocialMediaServiceEnum.Youtube,
        tikapi: SocialMediaServiceEnum.Tikapi,
        tiktokad: SocialMediaServiceEnum.Tiktokad,
        tiktokuser: SocialMediaServiceEnum.Tiktokuser,
    }

    const enumValue = serviceMap[service.toLowerCase()]

    if (!enumValue) {
        console.error(`Error: ${service} is not a valid social media service.`)
        return undefined
    }

    return enumValue
}
