import React, { useState } from 'react'

import InstagramAuthorisationButton from '../components/authorise/platforms/InstagramAuthorisationButton'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

/**
 * This page is used to add a Facebook account to Arwen.
 */
function NewFacebookAccountPage() {
    const [isBusinessProfile, setIsBusinessProfile] = useState<boolean>(false)
    const [isLinkedToFacebookPage, setIsLinkedToFacebookPage] =
        useState<boolean>(false)

    const handleBusinessProfileChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsBusinessProfile(event.target.checked)
    }

    const handleLinkedToFacebookPageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsLinkedToFacebookPage(event.target.checked)
    }

    // We block the button using a div with a higher z-index than the button, unless both
    // checkboxes are checked.
    const areBothChecked = isBusinessProfile && isLinkedToFacebookPage

    return (
        <TextPageContainer>
            <TextPageHeader title="Add Instagram profile" />
            <p>Click below to add an Instagram profile to Arwen. </p>
            <p>
                <iframe
                    src="https://www.youtube.com/embed/WtQ72eVjORg?si=DnUnZK_zpJRfrVc3"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    className="md:w-[560px] md:h-[315px]"
                ></iframe>
            </p>
            <div className="mb-2">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={isBusinessProfile}
                        onChange={handleBusinessProfileChange}
                        className="form-checkbox"
                    />
                    <span>My account is a business profile</span>
                </label>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={isLinkedToFacebookPage}
                        onChange={handleLinkedToFacebookPageChange}
                        className="form-checkbox"
                    />
                    <span>My account is linked to a Facebook page</span>
                </label>
            </div>
            <div className="relative">
                {!areBothChecked && (
                    <div className="absolute inset-0 bg-white opacity-50 cursor-not-allowed z-10"></div>
                )}
                <InstagramAuthorisationButton />
            </div>
        </TextPageContainer>
    )
}

export default NewFacebookAccountPage
