import { useRecoilState } from 'recoil'
import { useDeleteAccountMutation } from '../api/client'
import useQueryParams from '../hooks/useQueryParams'
import { shouldRefetchAccountsState } from '../store/UIStore'
import { SyncLoader } from 'react-spinners'
import { useNavigate } from 'react-router'

type Props = {
    setVisible: (visible: boolean) => void
    accountId: number
}

function DeleteAccountModal(props: Props) {
    const { setVisible, accountId } = props
    const history = useNavigate()

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [shouldRefetchAccounts, setShouldRefetchAccounts] = useRecoilState(
        shouldRefetchAccountsState
    )

    const [deleteAccountMutation, { loading }] = useDeleteAccountMutation({
        onCompleted: () => {
            setShouldRefetchAccounts(true)
        },
    })

    const query = useQueryParams()
    let authCode: string

    const state = query.get('state')

    //facebook
    if (state) {
        authCode = query.get('code')!
    }

    const deleteAccountAction = async () => {
        await deleteAccountMutation({
            variables: {
                accountId: accountId,
                authCode: authCode,
            },
            onCompleted: () => {
                history('/')
            },
        })
    }

    async function handleDeleteClick() {
        await deleteAccountAction()
        setVisible(false)
    }

    function handleCancelClick() {
        setVisible(false)
    }

    return (
        <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                className="h-6 w-6 text-red-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                                className="text-lg leading-6  text-gray-950"
                                id="modal-title"
                            >
                                Delete account
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to delete this
                                    account? This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            onClick={async () => await handleDeleteClick()}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base  text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            {loading && <SyncLoader color="#fff" size="4px" />}
                            {!loading && <>Delete</>}
                        </button>
                        <button
                            type="button"
                            onClick={() => handleCancelClick()}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccountModal
