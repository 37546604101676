import { NewAccountButtons } from './AddAccountDropDown'

function AccountsEmptyState() {
    return (
        <div className="text-center w-full">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
            </svg>

            <h3 className="mt-2 text-sm  text-gray-950">No accounts found</h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by adding a social media account
            </p>
            <div className="mt-6">
                <NewAccountButtons />
            </div>
        </div>
    )
}

export default AccountsEmptyState
