import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import {
    AccountRecord,
    useCheckMetaSubscriptionForAccountQuery,
} from '../../api/client'
import { Link } from 'react-router-dom'

type Props = {
    account: AccountRecord
}

function MetaAccountSubscriptionStatus(props: Props) {
    const { account } = props

    const { data, loading, error } = useCheckMetaSubscriptionForAccountQuery({
        variables: { accountId: account.id },
    })

    if (loading) return <div>Checking subscription status...</div>
    if (error)
        return (
            <div className="text-red-500">
                Error checking subscription status.{' '}
                <Link
                    className={`text-primary-600 p-2 underline `}
                    to={`/account/${account.id}/settings/validation`}
                >
                    Click here to try again
                </Link>
            </div>
        )

    const isSubscribed = data?.checkMetaSubscriptionForAccount

    return (
        <>
            <div className="flex items-center space-x-2 justify-center">
                {isSubscribed ? (
                    <CheckCircleIcon
                        className="h-6 w-6 text-green-500"
                        aria-hidden="true"
                    />
                ) : (
                    <XCircleIcon
                        className="h-6 w-6 text-red-500"
                        aria-hidden="true"
                    />
                )}
                <span className="text-gray-700">
                    {isSubscribed ? (
                        'Has subscription'
                    ) : (
                        <span>
                            Subscription missing!
                            <Link
                                className={`text-primary-600 p-2 underline `}
                                to={`/account/${account.id}/settings/validation`}
                            >
                                Go here to create one
                            </Link>
                        </span>
                    )}
                </span>
            </div>
        </>
    )
}

export default MetaAccountSubscriptionStatus
