import {
    ContentClassificationView,
    ContentMediaView,
    Maybe,
} from '../../../api/client'

type Props = {
    media: ContentMediaView[]
}

const MINIMUM_SIGNIFICANT_CLASSIFICATION = 0.5

export default function ImageAbuseSummary(props: Props) {
    const { media } = props

    if (!!!media) {
        return <></>
    }

    function isClassificationSignificant(
        classification: Maybe<ContentClassificationView>
    ) {
        return (
            classification?.classification &&
            classification?.classification > MINIMUM_SIGNIFICANT_CLASSIFICATION
        )
    }

    /**
     * Converst the classifer name from ImageXXXXX to a display name by remmoving the Image prefix.
     * @param classifierName The image classifier name from the database in the form of ImageXXXXXX
     */
    function convertClassifierName(classifierName?: string) {
        return classifierName && classifierName.replace('Image', '')
    }

    function convertScoreToDisplayPercentage(
        percentage: number | undefined | null
    ) {
        return percentage && Math.round(percentage * 100)
    }

    return (
        <div className="flex flex-row">
            {media?.map((media, index) => {
                if (media.publicUrl) {
                    let significationClassifications =
                        media.mediaClassifications?.filter(
                            isClassificationSignificant
                        )
                    return (
                        <div
                            className="pr-1 contents"
                            key={media.serviceMediaId}
                        >
                            <a
                                href={media.publicUrl}
                                className="text-primary-600 underline mr-2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {media.publicUrl && (
                                    <img
                                        src={media.publicUrl}
                                        className="max-h-36 max-w-36"
                                        alt="Media item attached to the content - no other details available"
                                    />
                                )}
                                {!media.publicUrl && (
                                    <span>Image {index + 1}</span>
                                )}
                            </a>

                            <div className="relative -mt-6 flex flex-col bg-black bg-opacity-90 text-white">
                                {significationClassifications
                                    ?.sort((a, b) =>
                                        a &&
                                        b &&
                                        a.classification &&
                                        b.classification
                                            ? b?.classification -
                                              a?.classification
                                            : 0
                                    )
                                    .map((classification) => {
                                        return (
                                            <span
                                                key={
                                                    classification?.classifierName
                                                }
                                            >
                                                {' '}
                                                {convertClassifierName(
                                                    classification?.classifierName
                                                )}{' '}
                                                {convertScoreToDisplayPercentage(
                                                    classification?.classification
                                                )}
                                                %
                                            </span>
                                        )
                                    })}
                            </div>
                        </div>
                    )
                }

                return <></>
            })}
        </div>
    )
}
