import { useRecoilState } from 'recoil'
import { userState } from '../store/DomainStore'

import {
    useGetDashboardIdQuery,
    useGetTableauTokenQuery,
    useGetTeamsQuery,
} from '../api/client'
import { useEffect, useRef, useState } from 'react'

import {
    TableauViz,
    PrintScaling,
    PrintPageSize,
    PrintOrientation,
    TableauEventType,
} from '../assets/vendor/tableau-embedding-3.6.0.min.js'
import SimpleLoader from '../components/SimpleLoader'
import ReportsNotEnabled from '../components/report/ReportsNotEnabled'
import { format, sub } from 'date-fns'
import DateTimePickerContainer from '../components/content/search/DateTimePickerContainer'
import GenericErrorMessage from '../components/errors/GenericErrorMessage'

// Returned when the user is not found in Tableau
const VIZ_ERROR_AUTH_FAILED = 'auth-failed'

//FIXME:
// There is a lot of duplication of Tableau code between this and the ReportPage
// Ideally this would be shared but we're not doing that now for simplicity and speed.

interface Props {
    admin: boolean
}

function ReportPage(props: Props) {
    const [user] = useRecoilState(userState)
    const { data, loading, refetch } = useGetTableauTokenQuery()
    const vizRef = useRef(null)
    const { data: dashboardUrl, loading: urlLoading } = useGetDashboardIdQuery()

    const [teamName, setTeamName] = useState('')

    const { data: getTeamsData, loading: getTeamsLoading } = useGetTeamsQuery()
    const [startDate, setStartDate] = useState(sub(new Date(), { days: 7 }))

    const [endDate, setEndDate] = useState(new Date())

    const [isInteractive, setIsInteractive] = useState(false)
    const [inErrorState, setInErrorState] = useState(false)

    /**
     * Scenarios:
     * - user does not have a dashboard enabled - this is the way to enable for specific users - DO NOT SHOW REPORT PAGE or ASK THEM IF THEY WANT TO ENABLE and get in contact with us
     * - user has dashboard enabled but no dashboard id - this is an error - should have a dashboard id - direct them to tech support
     * - user has dashboard enabled and dashboard id BUT user does not have a Tableau user. From a process perspective this should never be the case. - direct them to tech support
     * - user has dash enabled and id and Tableau user but the dash is not found - direct them to tech support
     * - user has dash enabled, id and Tableau user, dash is found - normal, successful usage
     * - there was a network error or some other unspecified error - ask to try again and then direct them to tech support.
     *
     * The errors: "no Tableau user" and "dash not found" and other unspecified network or Tableau permissions errors both derive from the Tableau Embedding API - we need to capture these errors and handle
     *
     * Essential documentation:
     * Tableau Embedding API v3 Documentation - https://help.tableau.com/current/api/embedding_api/en-us/index.html
     * Tableau Embedding API v3 Samples - https://github.com/tableau/embedding-api-v3-samples#embedding-api-v3-samples
     * Tableau Embedding API v3 Reference - https://help.tableau.com/current/api/embedding_api/en-us/reference/index.html
     *
     * We will be using the Javascript API rather than the Web Component as the API provides more flexibility and control.
     * For example with the Javascript API we can catch and properly handle Signin errors.
     */

    /**
     * Knowing when the visualisation becomes interactive is important as it allows us to control
     * what we display when. By hiding the Tableau loading screen and showing the Arwen loader
     * we make it look more like Arwen native functionality..
     */
    function onFirstInteractive() {
        setInErrorState(false)
        setIsInteractive(true)
    }

    /**
     * We only explicitly handle the auth-failed error. All other errors are handled by the default error handler.
     * @param error the viz load error object. The Tableau documentation on this is not very thorough and I deduced the following from inspection.
     */
    function onVizLoadError(error: any) {
        // @ts-ignore
        const visLoadError: TableauEventType.VizLoadError = error?.detail
        if (visLoadError._errorCode === VIZ_ERROR_AUTH_FAILED) {
            refetch()
        } else {
            setInErrorState(true)
        }
    }

    const token = data?.getTableauToken
    const url = dashboardUrl?.getDashboardId

    useEffect(() => {
        if (!!token && !!url && !!vizRef.current) {
            const viz = new TableauViz()
            viz.width = 1232
            viz.width = 827

            viz.hideTabs = true

            const startDateParameter = document.createElement('viz-parameter')
            startDateParameter.setAttribute('name', 'StartDate')
            startDateParameter.setAttribute(
                'value',
                format(startDate, 'yyyy-MM-dd hh:mm:ss')
            )
            // @ts-ignore
            viz.appendChild(startDateParameter)

            const endDateParameter = document.createElement('viz-parameter')
            endDateParameter.setAttribute('name', 'EndDate')
            endDateParameter.setAttribute(
                'value',
                format(endDate, 'yyyy-MM-dd hh:mm:ss')
            )
            viz.append(endDateParameter)

            if (teamName === '') {
                setTeamName(
                    getTeamsData?.getTeams ? getTeamsData?.getTeams[0].name : ''
                )
            }

            // We set the team name here as admins need a team selected in order to show data. By default we choose the first in the list
            // The parameter does not affect the view for non-admins.
            if (teamName !== '') {
                const teamNameParameter =
                    document.createElement('viz-parameter')
                teamNameParameter.setAttribute('name', 'Team Name')
                teamNameParameter.setAttribute('value', teamName)
                viz.append(teamNameParameter)
            }

            // DC: I could not find a way to detect the error when the dashboard url is incorrect
            viz.src = url
            viz.toolbar = 'hidden'
            viz.token = token
            viz.device = 'desktop'

            viz.addEventListener(
                TableauEventType.FirstInteractive,
                onFirstInteractive
            )
            viz.addEventListener(TableauEventType.VizLoadError, onVizLoadError)

            // @ts-ignore
            if (vizRef.current.hasChildNodes()) {
                // @ts-ignore
                vizRef.current.removeChild(vizRef.current.childNodes[0])
            }
            // @ts-ignore
            vizRef.current.appendChild(viz)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, url, teamName, startDate, endDate, inErrorState])

    const exportPDFOptions = {
        scaling: PrintScaling.AtMost1PageWide,
        pageSize: PrintPageSize.A4,
        orientation: PrintOrientation.Portrait,
    }

    // TODO: this is too specific an error - it should be more generic. for example this will happen when the dashboard id associated with the user is incorrect.
    const reportLoadingErrorDisplay = (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24  lg:px-8">
            <div className="max-w-max">
                <GenericErrorMessage message="There was an error generating your report." />
            </div>
        </div>
    )

    async function exportPDF() {
        // @ts-ignore
        const publishedSheetsInfo =
            // @ts-ignore
            vizRef.current.childNodes[0].workbook.publishedSheetsInfo
        const selectedWorkbookSheetsForExport = Array.from(
            publishedSheetsInfo,
            (publishedSheetInfo: any) => publishedSheetInfo.name
        )
        // @ts-ignore
        await vizRef.current.childNodes[0].exportPDFAsync(
            selectedWorkbookSheetsForExport,
            exportPDFOptions
        )
    }

    let onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let teamName = event.target.value
        setTeamName(teamName)
        setIsInteractive(false)
    }

    const teamsDropdown = (
        <div className="text-center text-gray-950 items-center flex ">
            <div className=""> </div>
            <select
                id="visibleSocialMediaServices"
                name="visibleSocialMediaServices"
                className="shadow-md  hover:cursor-pointer block w-full pl-3 pr-10 py-2 text-sm hover:bg-gray-100  border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                defaultValue={''}
                onChange={onChangeHandler}
            >
                {getTeamsData?.getTeams?.map((team, index) => (
                    <option key={index} value={team.name}>
                        {team.name}
                    </option>
                ))}
            </select>
        </div>
    )

    const pdfButton = (
        <button
            className="inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
            text-white bg-primary hover:bg-primary-800 "
            onClick={() => exportPDF()}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
            </svg>
            Download Report
        </button>
    )

    return (
        <>
            {loading || urlLoading || getTeamsLoading ? (
                // Hide the Tableau embedded object until: the token has been fetched and the visualisation is interactive.
                <div className="w-full absolute bg-white h-full mt-1 z-30 ">
                    <div className="mt-20">
                        {inErrorState ? (
                            reportLoadingErrorDisplay
                        ) : (
                            <SimpleLoader loading={loading} />
                        )}
                    </div>
                </div>
            ) : null}

            {user.dashboardEnabled &&
                data?.getTableauToken &&
                dashboardUrl?.getDashboardId &&
                !inErrorState && (
                    <div className="w-full bg-white pt-8 pl-8 flex flex-col items-center">
                        <div className="flex flex-row flex-wrap gap-x-4 gap-y-4">
                            {props.admin ? teamsDropdown : null}
                            <DateTimePickerContainer
                                startDateTime={startDate}
                                setStartDateTime={(startDateTime) => {
                                    setIsInteractive(false)
                                    setStartDate(startDateTime)
                                }}
                                endDateTime={endDate}
                                setEndDateTime={(endDateTime) => {
                                    setIsInteractive(false)
                                    setEndDate(endDateTime)
                                }}
                                onDateRangeChange={(
                                    startDateTime,
                                    endDateTime
                                ) => {
                                    setIsInteractive(false)
                                    setStartDate(startDateTime)
                                    setEndDate(endDateTime)
                                }}
                            />

                            {pdfButton}
                        </div>
                        {!isInteractive ? (
                            <div className="text-lg bg-primary-600 rounded-sm p-2 px-4 mt-4 text-white animate-pulse">
                                We are generating your report - this can take up
                                to 30 seconds.
                            </div>
                        ) : null}
                        <div
                            className="vizDiv  bg-white mb-20 mt-12"
                            ref={vizRef}
                        ></div>
                    </div>
                )}
            {!user.dashboardEnabled && <ReportsNotEnabled />}
        </>
    )
}

export default ReportPage
