export type ClassifierSearchParam = {
    classifierName: string
    threshold?: number
}

export function getClassifierSearchParam(
    classifierName: string,
    threshold?: number
): ClassifierSearchParam {
    return {
        classifierName: classifierName,
        threshold: threshold,
    }
}
