import { useParams } from 'react-router-dom'
import ModerationContent from '../components/moderation/ModerationContent'

function ModerationBoundariesPage() {
    let { accountId: accountIdString } = useParams()
    const accountId = Number(accountIdString)

    return (
        <div className="bg-white p-12  h-full">
            <ModerationContent accountId={accountId} />
        </div>
    )
}

export default ModerationBoundariesPage
