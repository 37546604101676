import { useState } from 'react'
import {
    AccountRecord,
    useUpdateLlmClassificationEnabledMutation,
} from '../../api/client'
import Toggle from './Toggle'

const LlmClassificationToggle = ({ account }: { account: AccountRecord }) => {
    const [updateLlmClassificationEnabled, { loading }] =
        useUpdateLlmClassificationEnabledMutation()

    const [enabled, setEnabled] = useState(account.llmClassificationEnabled)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateLlmClassificationEnabled({
            variables: {
                accountId: account.id,
                llmClassificationEnabled: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`llm-classification-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="LLM classification enabled"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enables and disables LLM classification and moderation"
            size="large"
        />
    )
}

export default LlmClassificationToggle
