import { Transition } from '@headlessui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../util/classNames'

type Props = {
    item: any
    sidebarOpen: boolean
}
export default function NavigationItem(props: Props) {
    const { item, sidebarOpen } = props

    return (
        <a
            key={item.name}
            href={item.href}
            target={item.target}
            className={classNames(
                item.current
                    ? 'bg-gray-100 text-gray-950'
                    : ' hover:bg-gray-50 hover:text-gray-950',
                'group flex items-center px-2 pl-4 py-2  rounded-md w-64'
            )}
        >
            <item.icon
                className={classNames(
                    item.current ? '' : ' ',
                    'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
            />

            <Transition
                show={sidebarOpen}
                enter="transition-opacity duration-250"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-250"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="flex flex-row">
                    {item.name}

                    {item.target === '_blank' && (
                        <>
                            <span className="sr-only">
                                (opens in a new tab)
                            </span>
                            <ArrowTopRightOnSquareIcon className="ml-2 w-4 inline" />
                        </>
                    )}
                </div>
            </Transition>
        </a>
    )
}
