type Props = {
    size?: number
}

const DEFAULT_SIZE = 8

export default function Spinner(props: Props) {
    const { size } = props

    const actualSize = size ? size : DEFAULT_SIZE

    return (
        <div
            className={`w-${actualSize} h-${actualSize} border-gray-700 inline-block animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] `}
            role="status"
        >
            <span className=" !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
        </div>
    )
}
