import { RankingInfo } from '@tanstack/match-sorter-utils'
import {
    FilterFn,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { formatISO9075, parseISO } from 'date-fns'

import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    TeamView,
    useDeleteTeamMutation,
    useGetTeamsQuery,
} from '../../api/client'
import SearchInput from '../../components/SearchInput'
import SimplePagination from '../../components/table/SimplePagination'
import SimpleTable from '../../components/table/SimpleTable'
import { fuzzyFilter, fuzzySort } from '../../components/table/helpers'
import Spinner from '../../components/Spinner'
import { toastError } from '../../components/Notification'

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const columnHelper = createColumnHelper<TeamView>()

function TeamAdminPage() {
    const location = useLocation()

    const {
        data: getTeamsData,
        loading: getTeamsLoading,
        refetch,
    } = useGetTeamsQuery()

    const [deleteTeamMutation, { loading: deleteTeamLoading }] =
        useDeleteTeamMutation({
            onError: (error) => {
                // TODO: Add handling for different error scenarios e.g. deleted content or unauthorised account
                toastError(error.message)
            },
        })

    // When the location changes, e.g. navigating back from creating a new team, then we should refetch.
    useEffect(
        () => {
            refetch()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    )

    function DeleteCell(info: any) {
        const team = info.row.original as TeamView
        return (
            <TrashIcon
                className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer"
                onClick={async () => {
                    await deleteTeamMutation({ variables: { teamId: team.id } })
                    await refetch()
                }}
            />
        )
    }

    function EditCell(info: any) {
        const team = info.row.original as TeamView
        return (
            <a
                href={`#/admin/team/edit/${team.id}`}
                className="text-primary-600 underline"
            >
                <PencilIcon className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer" />
            </a>
        )
    }

    const columns = [
        columnHelper.accessor('id', {
            header: () => 'Id',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('name', {
            header: () => 'Name',
            cell: (info) => info.getValue(),
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
        }),
        columnHelper.accessor('createdAt', {
            header: () => 'Created',
            cell: (info) => (
                <span>{formatISO9075(parseISO(info.getValue()))}</span>
            ),
            sortingFn: fuzzySort,
        }),
        columnHelper.display({
            id: 'edit',
            cell: (props) => <EditCell row={props.row} />,
        }),
        columnHelper.display({
            id: 'delete',
            cell: (props) => <DeleteCell row={props.row} />,
        }),
    ]

    const [globalFilter, setGlobalFilter] = useState('')
    const [data, setData] = useState<TeamView[]>([])

    useEffect(() => {
        if (getTeamsData?.getTeams) {
            setData(getTeamsData?.getTeams)
        }
    }, [getTeamsData])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <>
            <div className="m-10 prose prose-stone">
                <h2 className="text-gray-950 initial">Team admin page</h2>
                <p>This page lets you create and delete teams.</p>

                <div className="flex flex-row items-baseline">
                    <SearchInput
                        keyword={globalFilter ?? ''}
                        onKeywordChange={(value) =>
                            setGlobalFilter(String(value))
                        }
                        onSearch={(value) => setGlobalFilter(String(value))}
                    />
                    <div>
                        <a
                            href="#/admin/team/create"
                            className="text-primary-600 underline mx-4 text-md"
                        >
                            Create team
                        </a>
                    </div>
                    <div>
                        {(getTeamsLoading || deleteTeamLoading) && (
                            <Spinner size={6} />
                        )}
                    </div>
                </div>
            </div>
            <div className="mx-10  prose prose-stone ">
                <SimpleTable table={table} />
                <SimplePagination table={table} />
            </div>
        </>
    )
}

export default TeamAdminPage
