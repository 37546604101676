import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'

type Props = {
    value: ArwenListBoxItem
    options: ArwenListBoxItem[]
    onSelect: (optionName: ArwenListBoxItem) => void
    // TailwindCSS width classes
    widthClass: string
}

export type ArwenListBoxItem = {
    id: string
    name: string
    icon?: string
}

export default function ArwenListBox(props: Props) {
    const { value, options, onSelect, widthClass } = props

    const selectedOptionDisplay = (
        <>
            {value.icon && <i className={`fab ${value.icon} w-6 mr-2`}></i>}
            <span>{value.name}</span>
        </>
    )
    return (
        <Listbox
            value={value}
            onChange={(event) => {
                onSelect(event)
            }}
        >
            <div className={`${widthClass}`}>
                <ListboxButton className="relative shadow-md hover:cursor-pointer block w-full min-w-40 pl-3 pr-10 py-2 text-sm bg-white hover:bg-gray-100 border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md">
                    <span className="block truncate">
                        {selectedOptionDisplay || 'Select...'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <ListboxOptions
                        className={`${widthClass} absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                        {options.map((option, index) => (
                            <ListboxOption
                                key={index}
                                className={({ active }) =>
                                    `relative cursor-default select-none text-sm py-2 pl-4 pr-4 list-none ${
                                        active
                                            ? 'bg-primary text-white'
                                            : 'text-gray-950'
                                    }`
                                }
                                value={option}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <span
                                            className={`block truncate pl-6 ${
                                                selected ? '' : 'font-normal'
                                            }`}
                                        >
                                            {option.icon && (
                                                <i
                                                    className={`fab ${option.icon} w-6 `}
                                                ></i>
                                            )}
                                            {option.name}
                                        </span>
                                        {selected ? (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                    active
                                                        ? 'text-white'
                                                        : 'text-primary'
                                                }`}
                                            >
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Transition>
            </div>
        </Listbox>
    )
}
