import { SocialMediaServiceEnum } from '../api/client'

/**
 * The social media services that we support, with display friendly names.
 *
 * We need this because the GraphQL enum doesn't have "all" and it shouldn't.
 */
export enum SocialMediaServiceFilterOptions {
    All = 'All platforms',
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    TikApi = 'TikApi',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
    TikTok_Ad = 'TikTok Ad',
    TikTok_User = 'TikTok User',
}

export const PROFILE_PARAM_NAME = 'platform'

/**
 * The drop-down uses display-friendly names for the social media services.
 * We need to map those to actual, valid GraphQL enum values. There's probably
 * a better way.
 */
export function dropDownToGraphQLEnum(
    displayEnum: string
): SocialMediaServiceEnum | undefined {
    let socialMediaService: SocialMediaServiceEnum | undefined = undefined
    switch (displayEnum) {
        case SocialMediaServiceFilterOptions.All:
            // Leave it as undefined because there's no All enum variant on
            // the GraphQL schema.
            break
        case SocialMediaServiceFilterOptions.Facebook:
            socialMediaService = SocialMediaServiceEnum.Facebook
            break
        case SocialMediaServiceFilterOptions.Instagram:
            socialMediaService = SocialMediaServiceEnum.Instagram
            break
        case SocialMediaServiceFilterOptions.TikApi:
            socialMediaService = SocialMediaServiceEnum.Tikapi
            break
        case SocialMediaServiceFilterOptions.Twitter:
            socialMediaService = SocialMediaServiceEnum.Twitter
            break
        case SocialMediaServiceFilterOptions.YouTube:
            socialMediaService = SocialMediaServiceEnum.Youtube
            break
        case SocialMediaServiceFilterOptions.TikTok_Ad:
            socialMediaService = SocialMediaServiceEnum.Tiktokad
            break
        case SocialMediaServiceFilterOptions.TikTok_User:
            socialMediaService = SocialMediaServiceEnum.Tiktokuser
            break
        case null:
            // Nothing selected, so leave it as undefined.
            break
        default:
            console.error(`Unknown social media service ${displayEnum}`)
    }
    return socialMediaService
}
