import { ContentOutcomeView } from '../../api/client'
import { ConditionalCopyToClipboard } from '../CopyToClipboard'
import InstagramProfileId from './InstagramProfileId'

type Props = {
    content: ContentOutcomeView
}

/**
 * Displays the Profile ID for the content author.
 *
 * This is usually just `content.serviceAuthorId` but for Instagram content
 * it's a bit more complicated. But that's delegated to `InstagramProfileId`.
 */
export default function ProfileId({ content }: Props) {
    switch (content.socialMediaServiceName.toLowerCase()) {
        case 'instagram':
            return <InstagramProfileId contentId={content.id} />
        default:
            return (
                <ConditionalCopyToClipboard
                    text={content.serviceAuthorId}
                    fallback="This field is not set"
                />
            )
    }
}
