import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import PoweredByStripeLogo from '../../assets/Powered_by_Stripe_white.svg'
import ArwenLogo from '../../assets/brand/arwen-logo-white-large.png'
import CreditCardLogos from '../../assets/credit-card-logos.png'

function buildChecklistItem(text: string) {
    return (
        <div className="relative flex items-center group mb-2 mx-4 xl:mb-4">
            <span className="h-9 flex items-center">
                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary-600 rounded-full">
                    <CheckCircleIcon
                        className="w-8 h-8 text-white"
                        aria-hidden="true"
                    />
                </span>
            </span>
            <span className="ml-4 min-w-0 flex flex-col items-center ">
                <span className="text-l font-semibold tracking-wide xl:text-xl">
                    {text}
                </span>
            </span>
        </div>
    )
}

function buildProgressList(stepNumber: number, text: string) {
    return (
        <div
            className="px-2 py-4 flex flex-col items-center text-sm  xl:text-lg xl:px-4"
            aria-current="step"
        >
            <span className="mb-4 flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-white rounded-full">
                <span className="text-white text-lg">{stepNumber}</span>
            </span>
            <div className="text-center font-bold">{text}</div>
        </div>
    )
}

type Props = {
    authenticator: React.ReactNode
}

function TrialWelcome(props: Props) {
    const { authenticator } = props

    return (
        <>
            <div className="flex flex-col flex-1 bg-primary text-white h-screen items-center">
                <div className="h-full flex flex-col items-center justify-between">
                    <div className="flex flex-col items-center">
                        <img
                            alt="logo"
                            src={ArwenLogo}
                            className="mt-8 lg:mt-24 mx-4 mb-10 align-middle xl:mb-16"
                            width="300"
                        />
                        <h1 className="font-bold mb-10 text-xl xl:text-3xl xl:mb-16">
                            Create your 90-day trial account
                        </h1>
                        <div className="mb-8 xl:mb-12">
                            {buildChecklistItem('No obligation trial')}
                            {buildChecklistItem('Cancel at any time')}
                            {buildChecklistItem(
                                'You will not be charged during your trial period'
                            )}
                        </div>
                        <div className="flex flex-row">
                            {buildProgressList(1, 'Create your free account')}
                            {buildProgressList(
                                2,
                                'Securely add your social media profiles'
                            )}
                            {buildProgressList(3, 'Start using Arwen')}
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="flex flex-row mt-10 mb-4 align-middle w-80 justify-between">
                            <div className="flex flex-row text-xs items-center">
                                <LockClosedIcon className="w-10 h-10" />
                                <div className="w-32 px-2">
                                    Guaranteed <strong>safe and secure</strong>{' '}
                                </div>
                            </div>
                            <img
                                alt="logo"
                                src={PoweredByStripeLogo}
                                className=""
                                width="125"
                            />
                        </div>
                        <div>
                            <img
                                alt="logo"
                                src={CreditCardLogos}
                                className="mb-10"
                                width="350"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-16 lg:mt-0 flex-1 flex justify-center mb-12">
                {authenticator}
            </div>
        </>
    )
}

export default TrialWelcome
