/**
 * All social platforms have actions we can take. We let some of those actions happen
 * automatically, i.e. no clicking involved. The functions in this file encapsulate
 * which platforms perform which actions.
 *
 * TODO: this is similar to `frontend/src/components/content/actions.ts`. Really we need a
 * type that encapsulates all of this in one place.
 */

export enum AutoAction {
    Hide = 'hide',
    Delete = 'delete',
    Mute = 'mute',
}

export function getAutoActions(contentProvider: string): AutoAction[] {
    switch (contentProvider.toLowerCase()) {
        case 'twitter':
            return getTwitterAutoActions()
        case 'instagram':
            return getInstagramAutoActions()
        case 'facebook':
            return getFacebookAutoActions()
        case 'youtube':
            return getYouTubeAutoActions()
        case 'tikapi':
            return getTikApiAutoActions()
        case 'tiktok ad':
            return getTikTokAdAutoActions()
        case 'tiktok user':
            return getTikTokUserAutoActions()
        default:
            throw new Error(
                `Unknown content provider when trying to get auto actions! Content provider was ${contentProvider}`
            )
    }
}

/**
 * TODO: would someone please put an explanation of this logic here? I don't get it.
 */
export function getTwitterAutoActions(): AutoAction[] {
    return [AutoAction.Mute, AutoAction.Hide]
}

export function getInstagramAutoActions(): AutoAction[] {
    return [AutoAction.Hide, AutoAction.Delete]
}

export function getFacebookAutoActions(): AutoAction[] {
    return [AutoAction.Hide, AutoAction.Delete]
}

export function getYouTubeAutoActions(): AutoAction[] {
    return [AutoAction.Hide]
}

export function getTikApiAutoActions(): AutoAction[] {
    return [AutoAction.Delete]
}

export function getTikTokAdAutoActions(): AutoAction[] {
    return [AutoAction.Hide]
}

export function getTikTokUserAutoActions(): AutoAction[] {
    return [AutoAction.Hide]
}
