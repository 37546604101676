import { formatDistanceToNow } from 'date-fns'
import { ContentActionView } from '../../api/client'
import { getInLocalTime } from '../../util/timeHelpers'
import ArwenUser from './ArwenUser'

type Props = {
    action: ContentActionView
}

/**
 * Displays the metadata for an item of content when displayed in the conversation modal.
 */
export default function ReplyMetadata(props: Props) {
    const { action } = props

    let userDisplay = <div></div>
    if (action.userId) {
        userDisplay = <ArwenUser user_id={action.userId} />
    }

    return (
        <span className="text-gray-900 flex flex-row justify-end">
            {userDisplay} <div className="px-1 text-gray-700">·</div>
            <div className="text-gray-700">
                {formatDistanceToNow(getInLocalTime(action.actionOn), {
                    addSuffix: true,
                })}
            </div>
        </span>
    )
}
