import { useState } from 'react'
import {
    AccountRecord,
    useUpdateIntelligenceEnabledMutation,
} from '../../api/client'
import Toggle from './Toggle'

const IntelligenceEnabledToggle = ({ account }: { account: AccountRecord }) => {
    const [updateIntelligenceEnabled, { loading }] =
        useUpdateIntelligenceEnabledMutation()

    const [enabled, setEnabled] = useState(account.intelligenceEnabled)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateIntelligenceEnabled({
            variables: {
                accountId: account.id,
                intelligenceEnabled: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`intelligence-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="Emotion classification"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enables and disables Emotion classification."
            size="large"
        />
    )
}

export default IntelligenceEnabledToggle
