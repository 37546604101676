import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { AccountRecord, useSearchAccountsQuery } from '../api/client'
import AccountRow from '../components/AccountRow'
import AccountsEmptyState from '../components/AccountsEmptyState'
import FetchTimePeriodDropDown from '../components/FetchTimePeriodDropDown'
import Pagination from '../components/Pagination'
import SearchInput from '../components/SearchInput'
import { isUserAdminState } from '../store/DomainStore'
import {
    selectedAccountState,
    shouldRefetchAccountsState,
} from '../store/UIStore'

import ReactTooltip from 'react-tooltip'
import PlatformDropdown from '../components/PlatformDropdown'
import { useTitle } from '../components/TitleProvider'
import Toggle from '../components/toggles/Toggle'
import {
    decodeIdsQueryParam,
    encodeStringQueryParam,
    KEYWORD_QUERY_PARAM,
    LIMIT_QUERY_PARAM,
    OFFSET_QUERY_PARAM,
    TEAMS_QUERY_PARAM,
} from '../models/QueryParameters'
import {
    PROFILE_PARAM_NAME,
    SocialMediaServiceFilterOptions,
    dropDownToGraphQLEnum,
} from '../models/SocialMediaServiceSearch'
import TeamFilter from '../components/content/search/TeamFilter'
import mixpanel from 'mixpanel-browser'

const DEFAULT_LIMIT = 10

function AccountsPage() {
    useTitle('Profiles')
    // If we get here then we want to reset the onboarding state.
    localStorage.setItem('onboarding', 'false')

    const [searchParams, setSearchParams] = useSearchParams()
    const [showAdminFeatures, setShowAdminFeatures] = useState(false)
    /**
     * We treat the URL as state for our query parameters.
     *
     * We should do this for all query parameters. This will mean that deep linkin
     * is possible and refreshing the page takes you back to the same place.
     */

    /**
     * Retrieve all the query parameters from the URL
     */
    const keyword = searchParams.get(KEYWORD_QUERY_PARAM) || ''
    function handleKeywordChange(keyword: string): void {
        const newSearchParams = new URLSearchParams(searchParams.toString())
        newSearchParams.set(KEYWORD_QUERY_PARAM, keyword)
        newSearchParams.set(OFFSET_QUERY_PARAM, String(0))
        newSearchParams.set(LIMIT_QUERY_PARAM, String(DEFAULT_LIMIT))
        setSearchParams(newSearchParams)
    }

    const teamIdsQueryString = searchParams.get(TEAMS_QUERY_PARAM)
    const teamIds = decodeIdsQueryParam(teamIdsQueryString || '')

    const offset = Number(searchParams.get(OFFSET_QUERY_PARAM)) || 0
    const limit = Number(searchParams.get(LIMIT_QUERY_PARAM)) || DEFAULT_LIMIT

    // DC: commented out until authorisation status is properly determined.
    // const authorisationStatusFilterParam = searchParams.get(
    //     AUTH_STATUS_PARAM_NAME
    // ) as keyof typeof AuthorisationStatusFilterOptions
    // let authorisationStatusFilter = dropDownToGraphQLAuthorisationStatusEnum(
    //     authorisationStatusFilterParam
    // )

    const socialMediaServiceParam = searchParams.get(
        PROFILE_PARAM_NAME
    ) as keyof typeof SocialMediaServiceFilterOptions
    let socialMediaService = dropDownToGraphQLEnum(socialMediaServiceParam)
    let socialMediaServices = (socialMediaService && [socialMediaService]) || []

    // Fetch the initial batch of data
    const { data, loading, fetchMore } = useSearchAccountsQuery({
        variables: {
            params: {
                offset,
                limit,
                keyword,
                socialMediaServices: socialMediaServices,
                // authorisationStatus: authorisationStatusFilter,
                teamIds: teamIds,
            },
        },
        onError: (error: any) => {
            throw Error(error.message)
        },
    })

    // Is the user an admin?
    const [admin] = useRecoilState(isUserAdminState)

    const [shouldRefetchAccounts, setShouldRefetchAccounts] = useRecoilState(
        shouldRefetchAccountsState
    )
    const setSelectedAccount = useSetRecoilState(selectedAccountState)

    useEffect(() => {
        setSelectedAccount(undefined)
    }, [setSelectedAccount])

    useEffect(() => {
        // The tooltip function we use doesn't have good support for modals or infinite scroll.
        // We force the tooltips to be rebuilt after every render
        ReactTooltip.rebuild()
    })

    if (shouldRefetchAccounts) {
        fetchMore({
            variables: {
                offset,
                limit,
                keyword,
                socialMediaService,
            },
        })
        setShouldRefetchAccounts(false)
    }

    const accounts = data?.searchAccounts?.accounts as Array<AccountRecord>
    const total = data?.searchAccounts?.total

    function handleSearch(keyword: string) {
        handleKeywordChange(keyword)
    }

    function generateTable() {
        if (!!!accounts) {
            return null
        }
        return (
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="border-t border-gray-200 bg-white">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs  uppercase tracking-wider text-gray-700 "
                            >
                                Name
                            </th>

                            <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs  uppercase text-gray-700 w-10"
                            >
                                Safe
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs  uppercase text-gray-700 w-10"
                            >
                                Suspect
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs  uppercase text-gray-700 w-10"
                            >
                                Severe
                            </th>

                            {admin && showAdminFeatures ? (
                                <>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-center text-xs  uppercase tracking-wider text-gray-700"
                                    ></th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-center text-xs  uppercase tracking-wider text-gray-700"
                                    >
                                        Admin
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs  uppercase tracking-wider text-gray-700"
                                    ></th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs  uppercase tracking-wider text-gray-700"
                                    ></th>
                                </>
                            ) : null}
                        </tr>
                    </thead>
                    <tbody
                        className="divide-y divide-gray-200 bg-white"
                        data-cy="account_table_body"
                    >
                        {accounts.map((account) => {
                            return (
                                <AccountRow
                                    key={account.id}
                                    account={account}
                                    admin={admin}
                                    showAdminFeatures={showAdminFeatures}
                                />
                            )
                        })}
                    </tbody>
                </table>
                {total && total < DEFAULT_LIMIT ? null : (
                    <div className="px-4 py-3 border-t border-gray-200">
                        <Pagination
                            limit={limit}
                            offset={offset}
                            total={total || 0}
                            onChange={(newOffset, newLimit) => {
                                const newSearchParams = new URLSearchParams(
                                    searchParams.toString()
                                )
                                newSearchParams.set(
                                    OFFSET_QUERY_PARAM,
                                    `${newOffset < 0 ? 0 : newOffset}`
                                )
                                newSearchParams.set(
                                    LIMIT_QUERY_PARAM,
                                    `${newLimit}`
                                )
                                setSearchParams(newSearchParams)
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div>
            <div>
                <div className="flex flex-row flex-wrap border-t-2  pb-3 pl-3 pt-2 ">
                    {admin ? (
                        <div className="mr-1 mt-1">
                            <TeamFilter
                                teamIds={teamIds}
                                onUpdateSelectedTeams={(teamIds: number[]) => {
                                    mixpanel.track('Search - accounts - team', {
                                        teamIds,
                                    })
                                    const newSearchParams = new URLSearchParams(
                                        searchParams.toString()
                                    )
                                    newSearchParams.set(
                                        TEAMS_QUERY_PARAM,
                                        encodeStringQueryParam(
                                            teamIds.map((teamId) => `${teamId}`)
                                        )
                                    )
                                    newSearchParams.set(
                                        OFFSET_QUERY_PARAM,
                                        String(0)
                                    )
                                    newSearchParams.set(
                                        LIMIT_QUERY_PARAM,
                                        String(DEFAULT_LIMIT)
                                    )
                                    setSearchParams(newSearchParams)
                                }}
                            />
                            {/* TODO: rolled back until the auth checker is working */}
                            {/* <div className="mr-1 mt-1">
                                <FilterByAuthorisationSelect />
                            </div> */}
                        </div>
                    ) : null}
                    <div className="mr-1 mt-1">
                        <PlatformDropdown
                            onChange={() => {
                                // No changes needed here.
                            }}
                        />
                    </div>

                    <div className="mr-1 mt-1">
                        <FetchTimePeriodDropDown />
                    </div>

                    <div className="mr-1 mt-1">
                        <button
                            aria-label="Refresh"
                            data-tip="Click to refresh the page"
                            type="button"
                            className="focus:ring-primary-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm  leading-4 text-gray-700 shadow-md hover:border-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={() => {
                                fetchMore({
                                    variables: {
                                        offset: offset,
                                        limit: limit,
                                    },
                                })
                            }}
                        >
                            <ArrowPathIcon
                                className="h-5 w-5 text-gray-700"
                                aria-hidden="true"
                            />
                        </button>
                    </div>

                    <div className="mr-1 mt-1 items-center text-center text-gray-950">
                        <SearchInput
                            keyword={keyword}
                            onKeywordChange={handleKeywordChange}
                            onSearch={handleSearch}
                        />
                    </div>
                    <div className="items-center text-center mr-1 mt-3">
                        <a
                            href={`#/account/new`}
                            className="text-primary-600 underline mx-4 text-md flex flex-row items-center"
                        >
                            Add social profile
                        </a>
                    </div>
                    {admin && (
                        <div className="ml-2 mr-1 mt-1  items-center text-center text-gray-950 content-center">
                            <Toggle
                                checked={showAdminFeatures}
                                disabled={false}
                                label="Show admin features"
                                id="showAdminFeatures"
                                loading={false}
                                onToggle={async () =>
                                    setShowAdminFeatures(!showAdminFeatures)
                                }
                                size="large"
                                tooltip="Click this to show the admin features"
                            ></Toggle>
                        </div>
                    )}
                </div>

                {accounts?.length === 0 && (
                    <div className="flex flex-row justify-between border-t-2 bg-white pb-2 pl-4 pt-2">
                        <AccountsEmptyState />
                    </div>
                )}

                {accounts?.length > 0 && generateTable()}
            </div>
            {loading && (
                <>
                    {Array.apply(null, Array(limit)).map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="flex animate-pulse flex-row items-center justify-start border-b border-solid border-gray-300 py-2"
                            >
                                <div className="ml-4 h-14 w-14 rounded-full bg-gray-300"></div>
                                <div className="ml-4 h-5 w-2/5 rounded-md bg-gray-300 "></div>
                                <div className="ml-4 h-5 w-2/5 rounded-md bg-gray-300 "></div>
                            </div>
                        )
                    })}
                </>
            )}
        </div>
    )
}

export default AccountsPage
