export type Queue = 'SAFE' | 'SUSPECT' | 'SEVERE'

export enum BoundaryType {
    NUMERICAL = 'NUMERICAL',
    CATEGORICAL = 'CATEGORICAL',
}

export enum SeverityCategory {
    EXTREME = 'EXTREME',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NONE = 'NONE',
    NOTDETECTED = 'NOTDETECTED',
}

export type ModerationBoundaryTemplate = {
    queue: Queue
    classifierName: string
    type: BoundaryType
    probability?: number
    severityCategory?: SeverityCategory
}
