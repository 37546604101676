import { Transition } from '@headlessui/react'
import { ContentActionView, ContentOutcomeView } from '../../api/client'
import LikeButton from '../actions/buttons/LikeButton'

type Props = {
    selectedContent: Map<number, ContentOutcomeView>
    onActionClick: (contentActions: ContentActionView[]) => void
    onClearSelectedContent: () => void
    onSelectAll: () => void
}

/**
 * Displays actions for the selected content.
 */
export default function SelectedContentBar(props: Props) {
    const {
        selectedContent,
        onClearSelectedContent,
        onActionClick,
        onSelectAll,
    } = props

    return (
        <>
            <Transition show={selectedContent.size > 0}>
                <Transition.Child
                    enter="transition-height ease-in-out duration-300 transform"
                    enterFrom="h-0"
                    enterTo="h-14"
                    leave="transition-height ease-in-out duration-300 transform"
                    leaveFrom="h-14"
                    leaveTo="h-0"
                >
                    <div className="h-14  flex items-center bg-primary-100 rounded-md px-4 py-2">
                        {selectedContent.size > 0 && (
                            <>
                                <span className="text-lg font-bold ">
                                    {selectedContent.size} selected
                                </span>
                                <div className="ml-4 relative">
                                    {selectedContent.size > 0 && (
                                        <LikeButton
                                            contents={Array.from(
                                                selectedContent.values()
                                            )}
                                            onActionClick={onActionClick}
                                            actions={[]}
                                        />
                                    )}
                                </div>
                                <div className="pl-4">
                                    <button
                                        onClick={onClearSelectedContent}
                                        className="text-primary-500"
                                    >
                                        Clear
                                    </button>
                                </div>
                                <div className="pl-4">
                                    <button
                                        onClick={onSelectAll}
                                        className="text-primary-500"
                                    >
                                        Select all visible
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Transition.Child>
            </Transition>
        </>
    )
}
