/**
 * Converts a string to a boolean.
 *
 * TypeScript and JavaScript don't have library functions for this because their
 * type system is chaotic-evil and people might want different things.
 *
 * Here the only things that are truthy are 'true', '1', and 'yes'. Everything else
 * including null are falsy.
 */
export function stringToBoolean(value: string | null): boolean | null {
    const trueValues = new Set(['true', '1', 'yes'])
    if (!value) return null
    return trueValues.has(value.toLowerCase())
}
