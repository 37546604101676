import { CheckIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { ComboboxMenuOption } from './ComboboxMenuOption'

type Props = {
    option: ComboboxMenuOption
    selected: boolean
    focus: boolean
}

export default function ArwenComboBoxOption(props: Props) {
    const { option, selected, focus } = props
    return (
        <>
            <div
                className={clsx(
                    'flex flex-row',
                    'py-2',
                    focus ? ' bg-primary text-white' : 'text-gray-950'
                )}
            >
                <span
                    className={clsx(
                        'pl-2 pr-2',
                        selected ? 'visible' : 'invisible',
                        focus ? 'text-white' : 'text-primary'
                    )}
                >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>

                <span>{option.name}</span>
            </div>
        </>
    )
}
