import {
    Authenticator,
    CheckboxField,
    useAuthenticator,
} from '@aws-amplify/ui-react'
import ArwenLogo from '../../assets/brand/arwen-logo-red-medium.png'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

type Props = {
    hideSignUp: boolean
    initialState: 'signIn' | 'signUp'
    showHeader: boolean
    onboarding?: boolean
}

enum Action {
    SignUp = 'signUp',
    SignIn = 'signIn',
    Transition = 'transition',
    Unknown = 'unknown',
}

const mapRouteToAction = (route: string): Action => {
    switch (route) {
        case 'signUp':
            return Action.SignUp
        case 'signIn':
            return Action.SignIn
        case 'transition':
            return Action.Transition
        default:
            return Action.Unknown
    }
}

export default function Authenticate(props: Props) {
    const { hideSignUp, initialState, showHeader, onboarding } = props
    const navigate = useNavigate()
    const { route } = useAuthenticator((context) => [context.route])
    const [action, setAction] = useState<Action>(mapRouteToAction(route))

    useEffect(() => setAction(mapRouteToAction(route)), [route])

    useEffect(() => {
        // If we're:
        // 1. at the end of the authentication flow
        // 2. onboarding
        // Then we want to redirect to the new account page, and enable onboarding.
        if (action === Action.Transition && onboarding) {
            // Adding a small timeout to ensure the state is fully updated before redirect
            setTimeout(() => {
                navigate('/account/new?onboarding=true')
            }, 500)
        }
    }, [action, navigate, onboarding])
    return (
        <>
            <Authenticator
                hideSignUp={hideSignUp}
                initialState={initialState}
                formFields={{
                    signIn: {
                        username: {
                            placeholder: 'Email address',
                            label: '',
                        },
                        password: {
                            placeholder: 'Password',
                            label: '',
                        },
                    },
                    signUp: {
                        password: {
                            placeholder: 'Password',
                            label: '',
                        },
                        confirm_password: {
                            placeholder: 'Please confirm your password',
                            label: '',
                        },
                    },
                }}
                components={{
                    Header() {
                        return showHeader ? (
                            <img
                                alt="logo"
                                src={ArwenLogo}
                                className="mb-8 ml-20"
                                width="300"
                            />
                        ) : (
                            <></>
                        )
                    },
                    Footer() {
                        // If we're signing up a user then we don't need to display this because there's a checkbox
                        // they need to check that says the same thing.
                        return action !== Action.SignUp ? (
                            <div className="text-sm text-gray-500 justify-center">
                                <p className="text-center">
                                    By proceeding I agree to Arwen AI Ltd’s{' '}
                                    <a
                                        href="https://www.arwen.ai/eula"
                                        className="text-primary-600 underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        EULA
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://www.arwen.ai/privacy-policy"
                                        className="text-primary-600 underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                </p>
                            </div>
                        ) : (
                            <></>
                        )
                    },
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator()

                            return (
                                <>
                                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                    <Authenticator.SignUp.FormFields />

                                    {/* Add custom fields here */}
                                    <CheckboxField
                                        //@ts-ignore
                                        errorMessage={
                                            validationErrors.acknowledgement
                                        }
                                        hasError={
                                            !!validationErrors.acknowledgement
                                        }
                                        name="acknowledgement"
                                        value="yes"
                                        label={
                                            <div>
                                                I accept the{' '}
                                                <a
                                                    href="https://www.arwen.ai/eula"
                                                    className="text-primary-600 underline"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Arwen EULA.
                                                </a>{' '}
                                                and{' '}
                                                <a
                                                    href="https://www.arwen.ai/privacy-policy"
                                                    className="text-primary-600 underline"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Privacy Policy
                                                </a>
                                            </div>
                                        }
                                    />
                                </>
                            )
                        },
                    },
                }}
            >
                {({ signOut, user }) => <div />}
            </Authenticator>
        </>
    )
}
