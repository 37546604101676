import {
    UserGroupIcon,
    UserIcon,
    BeakerIcon,
    SparklesIcon,
} from '@heroicons/react/24/outline'

export function getAdminNavigationItems(
    onSpamPage: boolean,
    onTeamAdminPage: boolean,
    onUserAdminPage: boolean,
    onCommenterAdminPage: boolean,
    onPresalesReportPage: boolean
) {
    return [
        {
            name: 'Manage Spam Model',
            href: '#/spam-model',
            icon: BeakerIcon,
            current: onSpamPage,
        },
        {
            name: 'Team admin',
            href: '#/admin/team',
            icon: UserGroupIcon,
            current: onTeamAdminPage,
        },
        {
            name: 'User admin',
            href: '#/admin/user',
            icon: UserIcon,
            current: onUserAdminPage,
        },
        {
            name: 'Commenter admin',
            href: '#/admin/commenter',
            icon: UserIcon,
            current: onCommenterAdminPage,
        },
        {
            name: 'Presales Report',
            href: '#/presales-report',
            icon: SparklesIcon,
            current: onPresalesReportPage,
        },
    ]
}
