import { ContentOutcomeView } from '../api/client'

/**
 * Determines whether or not the reply component should be displayed.
 *
 * If this is a reply then we may need to add an additional line to the content card.
 * BUT ut we also need to make sure that the reply to username is not empty so we have something to display
 * We can't just display the id.
 *
 * This change has come about since the transition to the new Elon-era API.
 */
export function displayReplyComponent(content: ContentOutcomeView): boolean {
    return (
        content.isReply &&
        !!content.inReplyToUsername &&
        content.inReplyToUsername?.length > 0
    )
}
