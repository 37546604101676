import { useParams } from 'react-router-dom'
import { useGetTeamByIdQuery } from '../../api/client'
import ErrorMessageContainer from '../../components/errors/ErrorMessageContainer'
import TeamDetailsForm from '../../components/team/TeamDetailsForm'

type ParamTypes = {
    teamId: string
}

function TeamEditPage() {
    // Which user are we editing?
    const { teamId: teamIdString } = useParams<ParamTypes>()
    const teamId = Number(teamIdString)

    // Fetch the user
    const {
        data: teamData,
        loading: teamLoading,
        error: teamError,
    } = useGetTeamByIdQuery({
        variables: { teamId: teamId },
        fetchPolicy: 'network-only',
    })

    // Handle errors
    if (teamError) {
        return <ErrorMessageContainer message={teamError.message} />
    }

    if (teamLoading) {
        return <div>Loading...</div>
    } else if (!teamLoading && teamData?.getTeamById) {
        // Display the form
        return <TeamDetailsForm team={teamData?.getTeamById} />
    }
    return <div>{teamId}</div>
}

export default TeamEditPage
