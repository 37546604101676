import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'

export default function WarningCallout({ children }: { children: ReactNode }) {
    return (
        <>
            <div
                className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 "
                role="alert"
            >
                <ExclamationTriangleIcon
                    className="h-10 w-10 text-yellow-700 pr-4"
                    aria-hidden="true"
                />
                <span className="sr-only">Info</span>
                <div>{children}</div>
            </div>
        </>
    )
}
