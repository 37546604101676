import { useState } from 'react'
import {
    AccountRecord,
    useAddTwitterSubscriptionMutation,
    useRemoveTwitterSubscriptionMutation,
} from '../../api/client'
import Toggle from './Toggle'

const TwitterActivityApiToggle = ({ account }: { account: AccountRecord }) => {
    const [addTwitterSubscription, { loading: addLoading }] =
        useAddTwitterSubscriptionMutation()
    const [removeTwitterSubscription, { loading: removeLoading }] =
        useRemoveTwitterSubscriptionMutation()

    const [subscriptionEnabled, setSubscriptionEnabled] = useState(
        account.isSubscribed
    )

    const onToggle = async (isEnabled: boolean) => {
        setSubscriptionEnabled(isEnabled)
        if (isEnabled) {
            await addTwitterSubscription({
                variables: { accountId: account.id },
            })
        } else {
            await removeTwitterSubscription({
                variables: { accountId: account.id },
            })
        }
    }

    return (
        <Toggle
            id={`activity-api-${account.id.toString()}`}
            disabled={false}
            checked={subscriptionEnabled}
            label="Activity API"
            loading={addLoading || removeLoading}
            onToggle={onToggle}
            tooltip="If enabled this will allow X to send us Comments as soon as they happen."
            size="small"
        />
    )
}

export default TwitterActivityApiToggle
