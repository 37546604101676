import { useParams } from 'react-router-dom'
import { useGetUserByIdQuery } from '../../api/client'
import ErrorMessageContainer from '../../components/errors/ErrorMessageContainer'
import UserDetailsForm from '../../components/user/UserDetailsForm'
import { mapToUserAdminView } from '../../models/User'

type ParamTypes = {
    userId: string
}

function UserEditPage() {
    // Which user are we editing
    const { userId: userIdString } = useParams<ParamTypes>()
    const userId = Number(userIdString)

    // Fetch the user
    const {
        data: userData,
        loading: userLoading,
        error: userError,
    } = useGetUserByIdQuery({
        variables: { id: userId },
        fetchPolicy: 'network-only',
    })

    // Display a full page error if something goes wrong. NB this isn't the full screen error shown by the MainErrorBoundary
    if (userError) {
        return <ErrorMessageContainer message={userError.message} />
    }

    if (userLoading) {
        return <div>Loading...</div>
    } else if (!userLoading && userData?.getUserById) {
        const user = mapToUserAdminView(userData?.getUserById)
        // Render the form
        return (
            <>
                <UserDetailsForm user={user} />
            </>
        )
    }
}

export default UserEditPage
