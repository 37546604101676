import { AccountRecord } from '../../../api/client'
import { SettingReadonly } from '../../settings/SettingReadonly'

type Props = {
    account: AccountRecord
}

export default function ShadowBanningSettings(props: Props) {
    const { account } = props

    return (
        <div>
            <SettingReadonly
                name="Shadow banning enabled"
                description="Silently restrict user activity based on violation thresholds."
                value={account.shadowBanningEnabled ? 'Yes' : 'No'}
            />
            <SettingReadonly
                name="Violation Threshold"
                description="Number of violations before a
                        shadow ban is applied."
                value={String(account.shadowBanningThreshold)}
            />
            <SettingReadonly
                name="Violation Period (Days)"
                description="Number of days in which the
                        threshold of violations must occur."
                value={String(account.shadowBanningRangeInDays)}
            />
        </div>
    )
}
