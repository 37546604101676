const config = {
    domain: {
        url: process.env.REACT_APP_CLOUDFRONT_URL || '',
    },
    appSync: {
        aws_appsync_graphqlEndpoint:
            process.env.REACT_APP_GRAPHQL_ENDPOINT || '',
        region: process.env.REACT_APP_REGION || 'eu-west-1',
    },
    unauthApi: {
        url: process.env.REACT_APP_UNAUTH_API_ENDPOINT || '',
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
        COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    },
    defaultSettings: {
        tolerance: 0.9,
    },
    stripe: {
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
        productPage:
            process.env.REACT_APP_PRODUCTS_URL || 'https://arwen.ai/pricing',
        informationUrl:
            process.env.REACT_APP_INFORMATION_URL || 'https://arwen.ai/',
    },
    analytics: {
        token: process.env.REACT_APP_ANALYTICS_TOKEN || '',
    },
}

export default config
