import { DialogPanel, DialogTitle } from '@headlessui/react'
import CloseButton from '../CloseButton'
import SimpleModal from '../SimpleModal'
import { useState } from 'react'
import { clsx } from 'clsx'
import { useRemoveSpamItemsMutation } from '../../api/client'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid'

type Props = {
    contentIds: number[]
}

/**
 * Modal that allows the user to remove content from the spam model
 */
export default function RemoveSpamModal(props: Props) {
    const [open, setOpen] = useState(false)

    const [removeSpamItems] = useRemoveSpamItemsMutation({
        onCompleted: (data) => {
            setOpen(false)
        },
    })

    return (
        <div>
            <button
                className="inline-flex mb-2 items-center rounded-md bg-arwen-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-arwen-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={() => setOpen(true)}
            >
                Remove all
            </button>
            <SimpleModal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <DialogPanel
                    className={clsx(
                        'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300',
                        'min-w-min max-w-4xl sm:w-fit'
                    )}
                >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-96">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle
                                    as="h1"
                                    className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                                >
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                                            <WrenchScrewdriverIcon
                                                className="h-8 w-8 text-gray-700"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="mt-2 ml-3">Confirm</div>

                                        <CloseButton
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        />
                                    </div>
                                </DialogTitle>
                                <DialogPanel>
                                    <div className="flex flex-col mt-6 mb-3">
                                        <p>
                                            This will remove all vsible content
                                            from the spam model.
                                        </p>
                                        <p>
                                            If you only want to remove a some of
                                            the items then use the Spam button
                                            on the content card.
                                        </p>

                                        <button
                                            className="self-start mt-3 items-center rounded-md bg-arwen-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-arwen-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                            onClick={async () =>
                                                removeSpamItems({
                                                    variables: {
                                                        contentIds:
                                                            props.contentIds,
                                                    },
                                                })
                                            }
                                        >
                                            Remove all items from spam model
                                        </button>
                                    </div>
                                </DialogPanel>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </SimpleModal>
        </div>
    )
}
