import { Link, useParams } from 'react-router-dom'
import {
    useGetAccountQuery,
    useGetLlmReplyPromptLazyQuery,
    useSaveLlmReplyPromptMutation,
    useTestLlmReplyPromptLazyQuery,
} from '../../../api/client'
import { useTitle } from '../../../components/TitleProvider'
import PromptDetailsForm from '../../../components/prompt/PromptDetailsForm'
import { TextPageContainer } from '../../../containers/TextPageContainer'
import { TextPageHeader } from '../../../containers/TextPageHeader'
import { useEffect, useState } from 'react'
import { toastError, toastSuccess } from '../../../components/Notification'
import WarningCallout from '../../../components/callout/WarningCallout'
import SimpleLoader from '../../../components/SimpleLoader'
import LlmReplyToggle from '../../../components/toggles/LlmReplyToggle'

export default function LlmReplySettingsPage() {
    useTitle('LLM Reply Settings')
    let { accountId } = useParams()
    const [
        getLlmReplyPrompt,
        { data: getPromptData, loading: getPromptLoading },
    ] = useGetLlmReplyPromptLazyQuery({
        onError: (error) => {
            toastError(`Failed to load LLM reply prompt: ${error.message}`)
        },
    })

    const [saveLlmReplyPrompt, { loading: savePromptLoading }] =
        useSaveLlmReplyPromptMutation({
            onCompleted: () => {
                toastSuccess('Prompt saved')
            },
            onError: (error) => {
                toastError(`Failed to save LLM Reply prompt: ${error.message}`)
            },
        })
    const [
        testLlmReplyPrompt,
        { data: testReplyResultData, loading: testReplyResultLoading },
    ] = useTestLlmReplyPromptLazyQuery({
        onError: (error) => {
            toastError(`Failed to test LLM reply prompt: ${error.message}`)
        },
    })

    const { data: accountData, loading: accountLoading } = useGetAccountQuery({
        variables: { accountId: parseInt(accountId as string) },
    })
    const account = accountData?.getAccount

    const [numberOfSuggestions, setNumberOfSuggestions] = useState(1)

    useEffect(() => {
        if (account) {
            getLlmReplyPrompt({
                variables: {
                    accountId: account.id,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }, [account, getLlmReplyPrompt])

    const initialPrompt = getPromptData?.getLlmReplyPrompt?.prompt || ''

    const testPromptResult = testReplyResultData

    const handleOnTest = async (prompt: string, message: string) => {
        await testLlmReplyPrompt({
            variables: {
                prompt: prompt,
                testMessage: message,
                numberOfSuggestions: numberOfSuggestions,
            },
        })
    }
    const handleOnSave = async (_category: string, prompt: string) => {
        if (account) {
            await saveLlmReplyPrompt({
                variables: {
                    accountId: account.id,
                    prompt: prompt,
                },
            })
        } else {
            console.error('Account not loaded yet.')
            toastError('Unable to save prompt - please try again')
        }
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="LLM reply settings pages" />
            <Link to={`/account/${accountId}/settings`}>Back to settings</Link>

            {accountLoading && <SimpleLoader loading={true} />}

            {account && (
                <div className="flex flex-col mb-32 mt-10">
                    <div className="max-w-96 w-96">
                        <LlmReplyToggle account={account} />
                    </div>
                    {account && !account.llmEnableSuggestedReplies && (
                        <WarningCallout>
                            LLM reply suggestion for this profile is NOT enabled
                        </WarningCallout>
                    )}

                    <div className="w-full ml-3">
                        {accountLoading && getPromptLoading && (
                            <SimpleLoader loading={true} />
                        )}
                        {!accountLoading && !getPromptLoading && (
                            <PromptDetailsForm
                                category="Default reply"
                                prompt={initialPrompt}
                                saving={savePromptLoading}
                                testing={testReplyResultLoading}
                                testResult={
                                    testPromptResult?.testLlmReplyPrompt
                                }
                                onSave={handleOnSave}
                                onTest={handleOnTest}
                                numberOfSuggestions={numberOfSuggestions}
                                setNumberOfSuggestions={setNumberOfSuggestions}
                            />
                        )}
                    </div>
                </div>
            )}
        </TextPageContainer>
    )
}
