type TikTokIconProps = {
    icon: string
    title?: string
}

/**
 * This component is responsible for displaying the icon with disambiguating text.
 * Text is optional.
 */
function IconWithTitle({ icon, title }: TikTokIconProps) {
    return (
        <>
            <i className={icon}></i>
            {title ? (
                <span className="text-sm font-medium">{title}</span>
            ) : null}
        </>
    )
}

export default IconWithTitle
