import { useEffect } from 'react'
import config from '../../config'
import LoadingWelcome from '../unauthenticated/LoadingWelcome'
import { useCreatePaymentPortalMutation } from '../../api/client'

export function PaymentPageRedirect() {
    const [createPaymentPortal] = useCreatePaymentPortalMutation()

    useEffect(() => {
        const timer = setTimeout(() => {
            redirectToCheckout()
        }, 1000)
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function redirectToCheckout() {
        const { data: checkoutSessionId } = await createPaymentPortal({
            variables: {
                successUrl: `${window.location.origin}/#/`,
                cancelUrl: `${config.stripe.productPage}`,
            },
        })

        // Redirect the user to the Stripe Checkout page. The return value of createPaymentPortal is the URL of the newly created Stripe Checkout Session.
        window.location.href = checkoutSessionId?.createPaymentPortal!
    }

    return (
        <div className="full-screen">
            <LoadingWelcome />
        </div>
    )
}
