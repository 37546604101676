import { AccountRecord } from '../../../api/client'
import TwitterAuthorisationButton from './TwitterAuthorisationButton'

type Props = {
    account: AccountRecord
}

/**
 * A component to show the authorisation status of a TikTok account.
 *
 * This type of account is added to Arwen in two steps:
 * 1. Add the account
 * 2. Authorise the account
 *
 * So it might in fact be missing an authorisation row.
 */
function TwitterAuthorisation(props: Props) {
    const { account } = props

    let alreadyAuthorised = account.hasAuthorisation
    return (
        <>
            {alreadyAuthorised ? (
                <>
                    <p>This profile is authorised.</p>
                    <p>Click the button below to start re-authorisation.</p>
                    <div className="mt-4">
                        <TwitterAuthorisationButton account={account} />
                    </div>
                </>
            ) : (
                <>
                    <p>This profile is not authorised.</p>
                    <p>Click on the button below to start authorisation.</p>
                    <div className="mt-4">
                        <TwitterAuthorisationButton account={account} />
                    </div>
                </>
            )}
        </>
    )
}

export default TwitterAuthorisation
