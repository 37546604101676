/**
 * This button starts the OAuth flow for Instagram.
 */

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toastError, toastWarning } from '../../Notification'
import { useSaveAuthorisationCodeMutation } from '../../../api/client'
import { initFacebookSdk } from '../../../util/FacebookSDK'
import SimpleLoader from '../../SimpleLoader'

function InstagramAuthorisationButton() {
    const navigate = useNavigate()
    const [sdkLoading, setSdkLoading] = useState<boolean>(true)

    const [saveAuthorisationCodeMutation, { loading: authSaveLoading }] =
        useSaveAuthorisationCodeMutation({
            onCompleted: function (response) {
                const newString = response.saveAuthorisationCode?.new.join(',')
                const updatedString =
                    response.saveAuthorisationCode?.updated.join(',')
                const redirectUrl = `/account/new/authorised?new=[${newString}]&updated=[${updatedString}]`
                navigate(redirectUrl)
            },
            onError: (error) => {
                toastError(error.message)
                initFacebookSdk().then(() => {
                    window.FB.getLoginStatus(function (response: any) {
                        if (response.authResponse) {
                            window.FB.logout()
                        }
                        setSdkLoading(false)
                    })
                })
            },
        })

    useEffect(() => {
        initFacebookSdk()
            .then(() => {
                window.FB.getLoginStatus(function (response: any) {
                    if (response.authResponse) {
                        window.FB.logout()
                    }
                    setSdkLoading(false)
                })
            })
            .catch((error) => {
                console.error('Error initializing Facebook SDK:', error)
                setSdkLoading(false)
            })
    }, [])

    window.facebookLoginCallback = function () {
        window.FB.getLoginStatus(function (response: any) {
            if (response.authResponse) {
                saveAuthorisationCodeMutation({
                    variables: {
                        authCode: response.authResponse.accessToken,
                        socialMediaServiceName: 'instagram',
                        baseUrl: `${window.location.protocol}//${window.location.host}/`,
                    },
                })
            } else {
                toastWarning('Facebook login was cancelled.')
                setSdkLoading(false)
            }
        })
    }

    const scopes: string[] = [
        'instagram_basic',
        'instagram_manage_comments',
        'pages_read_engagement',
        'pages_show_list',
        'pages_manage_metadata',
        'business_management',
        'ads_management',
    ]

    let isLoading = sdkLoading || authSaveLoading

    return (
        <>
            {isLoading ? <SimpleLoader loading={true} /> : undefined}
            <div
                className={`fb-login-button`}
                data-width=""
                data-size="large"
                data-button-type="continue_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
                data-onlogin="facebookLoginCallback"
                data-scope={scopes.join(',')}
            ></div>
        </>
    )
}

export default InstagramAuthorisationButton
