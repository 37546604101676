import { useEffect, useState } from 'react'
import { ContentOutcomeView, TranslatedTextView } from '../../../api/client'
import { useRecoilState } from 'recoil'
import { translateAllState, targetLanguageState } from '../../../store/UIStore'
import mixpanel from 'mixpanel-browser'

type Props = {
    content: ContentOutcomeView
    translatedText?: TranslatedTextView
    translating: boolean
}

export default function ContentText(props: Props) {
    const { content, translatedText, translating } = props
    const [translateAll] = useRecoilState(translateAllState)
    const [targetLanguage] = useRecoilState(targetLanguageState)
    const [toggleTranslation, setToggleTranslation] = useState(false)

    /**
        TRANSLATION LOGIC EXPLAINED

        We have the following variables that determine what we display:
        - translateAll - set by the component in the header
        - toggleTranslation - set by the button in the card
        - translate - whether or not to translate the content

        Getting the logic right around these variables is tricky. The following table shows the logic for each combination of the variables.
        translateAll       0  0  1  1
        toggleTranslation  0  1  0  1
        translate          0  1  1  0
        This is XOR logic...

        We also have offerTranslation - if the content has a translation and the translation is not the same as the target language
        */

    useEffect(() => {
        // We need to set translation to false as soon as the content changes. This happens when this component is reused by the infinite scroll.
        setToggleTranslation(false)
    }, [content.id])

    // This variable tracks whether or not we should display the translation or not. It follows the logic in the comment above.
    const translate =
        (!toggleTranslation && translateAll) ||
        (toggleTranslation && !translateAll)

    // Should we offer to translate the content at all? This depends on the source and target language
    const offerTranslation = translatedText
        ? !!translatedText.detectedSourceLanguage &&
          translatedText.detectedSourceLanguage !== targetLanguage
        : false

    // What should the translation button say?
    let toggleTranslationText = translate
        ? `See original in ${translatedText?.detectedSourceLanguage}`
        : 'See translation'

    const showTranslationLoadingSkeleton =
        translating &&
        (translatedText?.translatedText.length === 0 ||
            translatedText?.translatedText === undefined)

    function generateOfferTranslation() {
        if (!offerTranslation) {
            return null
        }

        return (
            <span
                className="text-primary w-fit cursor-pointer text-sm"
                onClick={() => {
                    mixpanel.track(
                        `Translation - single - toggled ${
                            !toggleTranslation ? 'on' : 'off'
                        }`
                    )
                    setToggleTranslation(!toggleTranslation)
                }}
            >
                {offerTranslation ? toggleTranslationText : ''}
            </span>
        )
    }

    return (
        <div className="flex flex-col ">
            <div className="grid grid-flow-row grid-cols-1 text-gray-950">
                {/* NORMAL CONTENT */}

                <div
                    style={{
                        gridColumnStart: 1,
                        gridRowStart: 1,
                    }}
                    className={
                        ' transition-opacity duration-300 ' +
                        (translate
                            ? ' z-1 opacity-0 pointer-events-none'
                            : ' z-2 opacity-100')
                    }
                >
                    <span>{content.content}</span>
                    {/* {generateOfferTranslation()} */}
                </div>

                {/* TRANSLATED CONTENT */}
                <div
                    style={{
                        gridColumnStart: 1,
                        gridRowStart: 1,
                    }}
                    className={
                        ' transition-opacity duration-300 opacity-0' +
                        (translate
                            ? ' z-2 opacity-100'
                            : ' z-1 opacity-0 pointer-events-none')
                    }
                >
                    {showTranslationLoadingSkeleton ? (
                        <>
                            <div className="flex h-5 w-full animate-pulse flex-row border-solid border-gray-300 ">
                                <div className="h-5 w-full rounded-sm bg-gray-200"></div>
                            </div>
                        </>
                    ) : (
                        <span>{translatedText?.translatedText}</span>
                    )}

                    {/* {generateOfferTranslation()} */}
                </div>

                {generateOfferTranslation()}
            </div>
        </div>
    )
}
