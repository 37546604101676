import { ReactNode } from 'react'

interface TextPageContainerProps {
    children: ReactNode
}

/**
 * Standard container for a page that's dominated by text, as opposed to a page
 * that's full-screen data, e.g. Content Search, or the Account Dashboard..
 *
 * Sets background colours and flex and margains and so on.
 */
export function TextPageContainer({ children }: TextPageContainerProps) {
    return (
        <div className="w-full h-full p-6 bg-white flex flex-col items-center">
            <div className="prose prose-stone prose-lg">{children}</div>
        </div>
    )
}
