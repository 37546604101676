import { useState } from 'react'
import { TeamView, useGetTeamsQuery } from '../../../api/client'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    teamIds: Array<number>
    onUpdateSelectedTeams: (accountIds: Array<number>) => void
}
export default function TeamFilter(props: Props) {
    const { teamIds, onUpdateSelectedTeams } = props
    const [searchTerm, setSearchTerm] = useState('')

    // Fetch the initial batch of data
    // NOTE: we do not paginate this data! Over time we will probably need to do so.
    const { data, loading } = useGetTeamsQuery({
        onError: (error: any) => {
            throw Error(error.message)
        },
    })

    function handleUpdateSelectedTeams(selectedItems: Array<FilterItem>) {
        // Map back from selectedItems to Teams
        onUpdateSelectedTeams(selectedItems.map((item) => item.id))
    }

    const teams = data?.getTeams || []
    return (
        <>
            <FilterInput
                filterTypeName="Teams"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => {
                    setSearchTerm(term)
                }}
                onUpdateSelectedItems={handleUpdateSelectedTeams}
                selectedItems={teams
                    .filter((team) => teamIds.includes(team.id))
                    .map(mapTeamToFilterItem)}
                searchResults={teams
                    .filter((team) => {
                        return team.name
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    })
                    .map(mapTeamToFilterItem)}
                searching={loading}
            />
        </>
    )
}

function mapTeamToFilterItem(team: TeamView): FilterItem {
    return {
        id: team.id,
        name: team.name || '',
        displayName: team.name || '',
    }
}
