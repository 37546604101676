import { useState } from 'react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'

type Props = {
    text: string | number
}

/**
 * Shows a clipboard icon that, when clicked, copies the given text to the clipboard.
 *
 * Has subtle colour change on hover to indicate it's interactive. It temporarily changes
 * to a different colour and icon when the text is copied.
 *
 * This performs an action so it's a button. It has an accessible tooltip using `aria-label`
 * and `title`.
 */
export default function CopyToClipboard({ text }: Props) {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const handleClick = async () => {
        // We can only write `string` to the clipboard, but we might have string or number, so
        // we'll convert it to a string.
        let textToCopy = String(text)
        // The Navigator and Clipboard APIs are supported in all modern browsers. Docs here:
        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard
        await navigator.clipboard.writeText(textToCopy)
        setIsCopied(true)
        // We're going to reset the icon after a bit. We've put it in the clipboard but
        // we can't guarantee that it stays there, so we shouldn't always display the green tick.
        setTimeout(() => setIsCopied(false), 2000)
    }

    return (
        <button
            onClick={handleClick}
            className="relative flex items-center cursor-pointer transition duration-300 ease-in-out"
            aria-label={isCopied ? 'Copied!' : 'Copy to clipboard'}
            title={isCopied ? 'Copied!' : 'Copy to clipboard'}
        >
            {isCopied ? (
                <DocumentDuplicateIcon className="flex-shrink-0 h-5 w-5 text-green-500" />
            ) : (
                <DocumentDuplicateIcon className="flex-shrink-0 h-5 w-5 text-gray-500 hover:text-gray-700" />
            )}
        </button>
    )
}

type WrapperProps = {
    children: React.ReactNode
    text?: string | number
}

/**
 * If you want standard spacing around a CopyToClipboard, use this. E.g.:
 * ```
 *  <CopyToClipboardWrapper>
 *    {profile.serviceProfileId}
 *  </CopyToClipboardWrapper>
 * ```
 * Of if you're passing a component that doesn't resolve to a string you can pass the text:
 * ```
 * <CopyToClipboardWrapper
 *   children={
 *     <a
 *       href={content.socialMediaAuthorLink}
 *       target="_blank"
 *       rel="noreferrer"
 *       className="text-primary hover:text-primary-900 underline"
 *     >
 *       {content.socialMediaAuthorLink}
 *     </a>
 *   }
 *   text={content.socialMediaAuthorLink}
 * ></CopyToClipboardWrapper>
 * ```
 */
export function CopyToClipboardWrapper({ text, children }: WrapperProps) {
    const textToCopy =
        text ??
        (typeof children === 'string' || typeof children === 'number'
            ? children
            : '')

    return (
        <span className="flex flex-row space-x-2">
            <span>{children}</span>
            <CopyToClipboard text={textToCopy} />
        </span>
    )
}

type ConditionalProps = {
    text: string | number | null | undefined
    fallback: string
}

/**
 * If the value you want to display and copy might be null or undefined, use this.
 *
 * It lets you provide a fallback value to display if the value is null or undefined, and
 * in that case it won't display the copy icon. Who wants to copy a warning message?
 *
 * Use it like this:
 * ```
 *  <ConditionalCopyToClipboard
 *    text={content.serviceAuthorDisplayUsername}
 *    fallback="This field is not set"
 *  />
 *  ```
 */
export function ConditionalCopyToClipboard({
    text,
    fallback,
}: ConditionalProps) {
    return text ? (
        <CopyToClipboardWrapper>{text}</CopyToClipboardWrapper>
    ) : (
        <span>{fallback}</span>
    )
}
