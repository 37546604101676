/**
 * This button starts the OAuth flow for Facebook.
 */

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useSaveAuthorisationCodeMutation } from '../../../api/client'
import SimpleLoader from '../../SimpleLoader'
import { shouldRefetchAccountsState } from '../../../store/UIStore'
import { initFacebookSdk } from '../../../util/FacebookSDK'
import { toastError, toastWarning } from '../../Notification'

function FacebookAuthorisationButton() {
    const navigate = useNavigate()
    const [sdkLoading, setSdkLoading] = useState<boolean>(true)

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [_, setShouldRefetchAccounts] = useRecoilState(
        shouldRefetchAccountsState
    )

    const [
        saveAuthorisationCodeMutation,
        { loading: authSaveLoading, error: authSaveError },
    ] = useSaveAuthorisationCodeMutation({
        onCompleted: function (response) {
            const newString = response.saveAuthorisationCode?.new.join(',')
            const updatedString =
                response.saveAuthorisationCode?.updated.join(',')
            const redirectUrl = `/account/new/authorised?new=[${newString}]&updated=[${updatedString}]`
            navigate(redirectUrl)
            setShouldRefetchAccounts(true)
        },
        onError: (error) => {
            toastError(error.message)
            initFacebookSdk().then(() => {
                window.FB.getLoginStatus(function (response: any) {
                    if (response.authResponse) {
                        window.FB.logout()
                    }
                    setSdkLoading(false)
                })
            })
        },
    })

    useEffect(() => {
        initFacebookSdk()
            .then(() => {
                window.FB.getLoginStatus(function (response: any) {
                    if (response.authResponse) {
                        window.FB.logout()
                    }
                    setSdkLoading(false)
                })
            })
            .catch((error) => {
                console.error('Error initializing Facebook SDK:', error)
                setSdkLoading(false)
            })
    }, [])

    window.facebookLoginCallback = function () {
        window.FB.getLoginStatus(function (response: any) {
            if (response.authResponse) {
                saveAuthorisationCodeMutation({
                    variables: {
                        authCode: response.authResponse.accessToken,
                        socialMediaServiceName: 'facebook',
                        baseUrl: `${window.location.protocol}//${window.location.host}/`,
                    },
                })
            } else {
                toastWarning('Facebook login was cancelled.')
                setSdkLoading(false)
            }
        })
    }

    const scopes: string[] = [
        'pages_manage_engagement',
        'pages_manage_metadata',
        'pages_manage_posts',
        'pages_read_engagement',
        'pages_read_user_content',
        'pages_show_list',
        'business_management',
        'ads_management',
    ]

    const isLoading = sdkLoading || authSaveLoading

    return (
        <>
            {isLoading ? <SimpleLoader loading={true} /> : undefined}
            <div
                className="fb-login-button"
                data-width=""
                data-size="large"
                data-button-type="continue_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
                data-onlogin="facebookLoginCallback"
                data-scope={scopes.join(',')}
            ></div>
        </>
    )
}

export default FacebookAuthorisationButton
