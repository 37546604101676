import GenericErrorMessage from './GenericErrorMessage'

type Props = {
    message?: string
}

/**
 * This is intended to be used where an action has failed and it is appropriate to
 * interrupt the user's flow to display information about it.
 *
 * For example, any of the crud admin pages could use this to display a message when creation or saving fails.
 *
 * Alternative are: a notification popup or throwing an error and letting the full page error boundary catch it.
 */
export default function ErrorMessageContainer(props: Props) {
    const { message } = props
    return (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="max-w-max mx-auto">
                <GenericErrorMessage message={message} />
            </div>
        </div>
    )
}
