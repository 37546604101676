import { useState } from 'react'
import {
    AccountRecord,
    useUpdateLlmSuggestReplyEnabledMutation,
} from '../../api/client'
import Toggle from './Toggle'

const LlmReplyToggle = ({ account }: { account: AccountRecord }) => {
    const [updateLlmSuggestedReplyEnabled, { loading }] =
        useUpdateLlmSuggestReplyEnabledMutation()

    const [enabled, setEnabled] = useState(account.llmEnableSuggestedReplies)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateLlmSuggestedReplyEnabled({
            variables: {
                accountId: account.id,
                llmSuggestedReplyEnabled: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`llm-reply-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="LLM reply enabled"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enables and disables LLM reply and moderation"
            size="large"
        />
    )
}

export default LlmReplyToggle
