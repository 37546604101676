import { ClassifierDetails, ModerationBoundaryCreate } from '../../api/client'
import { ModerationBoundaryTemplate, Queue } from '../../models/Moderation'

export function mapTemplateValue(
    accountId: number,
    classifierDetailsMap: Map<string, ClassifierDetails>,
    template: IterableIterator<ModerationBoundaryTemplate> | undefined,
    queue: Queue
): ModerationBoundaryCreate[] {
    if (template) {
        return Array.from(template)
            .filter((boundary) => {
                // The boundary classifier needs to match up wtih the classifier details
                return !!classifierDetailsMap.get(boundary.classifierName)?.id
            })
            .map((boundary) => ({
                accountId: accountId,
                classifierId: classifierDetailsMap.get(boundary.classifierName)
                    ?.id as number, // We can safely do as number because we've filtered out the ones that don't match - its just that Typeshit doesn't pick this up
                queue: queue,
                type: boundary.type,
                probability: boundary.probability,
                severityCategory: boundary.severityCategory,
            }))
    }
    return []
}
