import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import {
    AccountRecord,
    useCheckMetaAuthorisationForAccountQuery,
} from '../../api/client'
import { Link } from 'react-router-dom'

type Props = {
    account: AccountRecord
}

function AccountAuthorisationStatus(props: Props) {
    const { account } = props

    const { data, loading, error } = useCheckMetaAuthorisationForAccountQuery({
        variables: { accountId: account.id },
    })

    if (loading) return <div>Checking authorisation status...</div>
    if (error)
        return (
            <div className="text-red-500">
                Error checking authorisation status
                <Link
                    className={`text-primary-600 p-2 underline `}
                    to={`/account/${account.id}/settings/validation`}
                >
                    Click here to try again
                </Link>
            </div>
        )

    const isValid = data?.checkMetaAuthorisationForAccount

    return (
        <>
            <div className="flex items-center space-x-2 justify-center">
                {isValid ? (
                    <CheckCircleIcon
                        className="h-6 w-6 text-green-500"
                        aria-hidden="true"
                    />
                ) : (
                    <XCircleIcon
                        className="h-6 w-6 text-red-500"
                        aria-hidden="true"
                    />
                )}
                <span className="text-gray-700">
                    {isValid
                        ? 'Has valid authorisation'
                        : 'Authorisation invalid'}
                </span>
            </div>
        </>
    )
}

export default AccountAuthorisationStatus
