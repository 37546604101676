import { Link } from 'react-router-dom'
import { useGetUsersByTeamIdQuery } from '../../api/client'
import SimpleLoader from '../SimpleLoader'
import { useRecoilState } from 'recoil'
import { isUserAdminState } from '../../store/DomainStore'

type Props = {
    teamId: number
}

export default function TeamUsersDisplay(props: Props) {
    const { teamId } = props
    const { data, loading } = useGetUsersByTeamIdQuery({
        variables: {
            teamId: teamId,
        },
        onError: (error) => {
            throw Error(error.message)
        },
    })

    const [admin] = useRecoilState(isUserAdminState)

    const users = data?.getUsersByTeamId || []
    return (
        <div>
            {loading && <SimpleLoader loading={loading} />}
            <ul>
                {users.map((user) => {
                    return (
                        <li key={user.id}>
                            {admin && (
                                <Link to={`/admin/user/edit/${user.id}`}>
                                    {user.username}
                                </Link>
                            )}
                            {!admin && user.username}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
