import UserDetailsForm from '../../components/user/UserDetailsForm'

/**
 * This page is used to create a new user - the form it users does not need much configuration. It is designed like this for consistency across the admin pages.
 */
function UserCreatePage() {
    return <UserDetailsForm />
}

export default UserCreatePage
