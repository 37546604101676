import { fetchAuthSession } from 'aws-amplify/auth'

type UserPermissions = {
    isAdmin: boolean
    isPlatformAppReviewer: boolean
}

// This represents Cognito permissions only
export async function getUserPermissions(): Promise<UserPermissions> {
    const authSession = await fetchAuthSession()

    const groups = authSession.tokens?.accessToken.payload['cognito:groups']

    if (groups) {
        //@ts-ignore
        const isAdmin = groups.includes('admins')
        //@ts-ignore
        const isPlatformAppReviewer = groups.includes('platformAppReviewer')

        return {
            isAdmin,
            isPlatformAppReviewer,
        }
    } else {
        return {
            isAdmin: false,
            isPlatformAppReviewer: false,
        }
    }
}
