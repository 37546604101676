import { useParams } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { selectedAccountState } from '../../../store/UIStore'
import {
    useDeleteAuthorisationMutation,
    useGetAccountQuery,
} from '../../../api/client'
import { useEffect, useState } from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import AuthoriseButton from '../../../components/authorise/AuthoriseButton'
import SimpleLoader from '../../../components/SimpleLoader'
import { SyncLoader } from 'react-spinners'
import { toastError } from '../../../components/Notification'

type ParamTypes = {
    accountId: string
}

function RevokeAuthorisationPage() {
    const { accountId: accountIdString } = useParams<ParamTypes>()
    const accountId = Number(accountIdString)
    const setSelectedAccount = useSetRecoilState(selectedAccountState)
    const {
        data,
        loading,
        refetch: refetchAccount,
    } = useGetAccountQuery({
        variables: { accountId },
    })

    const [isRevoked, setIsRevoked] = useState(false)
    const [deleteAuthorisation, { loading: isDeletingAuthorisation, error }] =
        useDeleteAuthorisationMutation({
            onCompleted: () => {
                setIsRevoked(true)
                refetchAccount()
            },
            onError: () => {
                console.error(
                    `Error revoking authorisation. Error was ${error}`
                )
                toastError(`Error revoking authorisation.`)
            },
        })

    useEffect(() => {
        if (data?.getAccount) {
            setSelectedAccount(data?.getAccount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    if (loading) {
        return <SimpleLoader loading={loading} />
    }

    const account = data?.getAccount
    let socialMediaService =
        data?.getAccount?.socialMediaServiceShortName?.toLowerCase()
    // You can change this value to test the different cases:
    // socialMediaService = 'instagram'
    let revokeCopy = <> </>
    let authoriseCopy = <> </>
    if (account) {
        switch (socialMediaService) {
            case 'youtube':
                revokeCopy = (
                    <>
                        <p>
                            You authorised Arwen to moderate comments on your
                            YouTube channel's videos. You might want to revoke
                            this authorisation and you can do that here.
                        </p>
                        <p>There are two things you would need to do:</p>
                        <ol>
                            <li>
                                Go to Google's{' '}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://myaccount.google.com/permissions"
                                >
                                    manage permissions
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>{' '}
                                page, select "arwen.ai", and then click "REMOVE
                                ACCESS".{' '}
                            </li>
                            <li>Click the button below.</li>
                        </ol>
                    </>
                )
                authoriseCopy = (
                    <>
                        <p>
                            Arwen is not authorised to moderate comments on your
                            YouTube posts, so there is nothing to revoke.
                        </p>
                        <p>
                            You may authorise Arwen by clicking the button
                            below.
                        </p>
                        <div>
                            <AuthoriseButton account={account} />
                        </div>
                    </>
                )
                break
            case 'twitter':
                revokeCopy = (
                    <>
                        <p>
                            You authorised Arwen to moderate mentions on your
                            Twitter posts. You might want to revoke this
                            authorisation and you can do that here.
                        </p>
                        <p>There are two things you would need to do:</p>
                        <ol>
                            <li>
                                Go to Twitter's{' '}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://twitter.com/settings/connected_apps"
                                >
                                    connected apps{' '}
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>{' '}
                                page, select "arwen-production" and then click
                                "Revoke app permissions". Twitter document this
                                process{' '}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://help.twitter.com/en/managing-your-account/connect-or-revoke-access-to-third-party-apps#%20connectremove"
                                >
                                    here
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>
                                .
                            </li>
                            <li>Click the button below.</li>
                        </ol>
                    </>
                )
                authoriseCopy = (
                    <>
                        <p>
                            Arwen is not authorised to moderate mentions on your
                            Twitter posts, so there is nothing to revoke.
                        </p>
                        <p>
                            You may authorise Arwen by clicking the button
                            below.
                        </p>
                        <div className="flex flex-row">
                            <AuthoriseButton account={account} />
                        </div>
                    </>
                )

                break
            case 'facebook':
                revokeCopy = (
                    <>
                        <p>
                            You authorised Arwen to moderate comments on your
                            Facebook Page's posts. You might want to revoke this
                            authorisation and you can do that here.
                        </p>
                        <p>There are two things you would need to do:</p>
                        <ol>
                            <li>
                                Go to Facebook's{' '}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://www.facebook.com/settings?tab=business_tools&ref=settings"
                                >
                                    business intagrations
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>{' '}
                                page, find "Arwen" and then click "Remove".
                            </li>
                            <li>Click the button below.</li>
                        </ol>
                    </>
                )

                // Should never see this page for Facebook, but adding copy in case they find a way.
                authoriseCopy = (
                    <>
                        <p>
                            Arwen is not authorised to moderate comments on your
                            Facebook Page posts, so there is nothing to revoke.
                        </p>
                        <p>
                            To re-authorise for Facebook please add the profile
                            again, using{' '}
                            <a href="/account/new/facebook_page">
                                the Add Facebook page.
                            </a>
                        </p>
                    </>
                )
                break
            case 'instagram':
                revokeCopy = (
                    <>
                        <p>
                            You authorised Arwen to moderate comments on your
                            Instagram posts. You might want to revoke this
                            authorisation and you can do that here.
                        </p>
                        <p>There are two things you would need to do:</p>
                        <ol>
                            <li>
                                Go to Instagram's{' '}
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://www.instagram.com/accounts/manage_access/"
                                >
                                    manage access
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>{' '}
                                page, find "Arwen" and then click "Remove".
                            </li>
                            <li>Click the button below.</li>
                        </ol>
                    </>
                )

                // Should never see this page for Facebook, but adding copy in case they find a way.
                authoriseCopy = (
                    <>
                        <p>
                            Arwen is not authorised to moderate comments on your
                            Instagram posts, so there is nothing to revoke.
                        </p>

                        <p>
                            To re-authorise for Instagram please add the profile
                            again, using{' '}
                            <a href="/account/new/instagram">
                                the Add Instagram page.
                            </a>
                        </p>
                    </>
                )
                break
            default:
                console.error(
                    'Trying to render the authorisation failure page but we have an unknown social media service!'
                )
        }
    } else {
        throw Error(
            `Could not find this Account. It may not exist or there may be a network problem.`
        )
    }

    return (
        <div className="m-10">
            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                    <div className="space-y-8 sm:space-y-5 prose ">
                        <h2>Revoke the authorisation you gave to Arwen</h2>
                        {data?.getAccount?.hasAuthorisation ? (
                            <>
                                {revokeCopy}

                                <button
                                    disabled={isRevoked}
                                    className={` w-80 text-primary-text hover:text-primary-text bg-primary p-2 rounded`}
                                    key={accountId}
                                    onClick={() => {
                                        deleteAuthorisation({
                                            variables: {
                                                accountId: accountId,
                                            },
                                        })
                                    }}
                                >
                                    {isDeletingAuthorisation ? (
                                        <SyncLoader color="#fff" size="4px" />
                                    ) : (
                                        'Revoke authorisation with Arwen'
                                    )}
                                </button>
                            </>
                        ) : (
                            <>{authoriseCopy}</>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RevokeAuthorisationPage
