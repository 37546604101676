import { Switch } from '@headlessui/react'
import { classNames } from '../../util/classNames'
import SimpleLoader from '../SimpleLoader'

type Props = {
    loading: boolean
    checked: boolean
    onToggle: (isEnabled: boolean) => Promise<void>
    label: string
    tooltip: string
    disabled: boolean
    id: string
    size: 'small' | 'medium' | 'large'
}

/**
 * From https://tailwindui.com/components/application-ui/forms/toggles
 */
const Toggle = (props: Props) => {
    const { checked, onToggle, label, tooltip, disabled, id, loading, size } =
        props
    const textStyle = ' ' + (disabled ? 'text-gray-300' : 'text-gray-950')

    const classesChecked =
        'bg-primary-600 hover:bg-primary-700 hover:border-primary-800'
    const classesUnchecked =
        'bg-gray-300 hover:bg-gray-400 hover:border-gray-400'
    const classesBaseSwitch =
        'shadow-md relative inline-flex flex-shrink-0 border-gray-300 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
    const classesBaseInner =
        'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'

    let translateCheckedClasses = 'translate-x-5'
    let classesSwitch = 'translate-x-5'
    let loaderClasses = 'ml-2 p-2 w-6'
    let innerClasses = 'h-5 w-5'
    switch (size) {
        case 'small':
            translateCheckedClasses = 'translate-x-3'
            classesSwitch = 'h-3 w-7'
            loaderClasses = 'ml-2 p-1 w-4'
            innerClasses = 'h-2 w-3'
            break
        case 'medium':
            translateCheckedClasses = 'translate-x-4'
            classesSwitch = 'h-4 w-8'
            loaderClasses = 'ml-2 p-2 w-6'
            innerClasses = 'h-3 w-3'
            break
        case 'large':
            translateCheckedClasses = 'translate-x-5'
            classesSwitch = 'h-6 w-11'
            loaderClasses = 'ml-2 p-2 w-6'
            innerClasses = 'h-5 w-5'
            break
    }

    return (
        <div key={id} data-tip={tooltip}>
            <Switch.Group
                data-for={id}
                data-tip
                as="div"
                className="flex items-center justify-between"
            >
                <Switch.Label as="span" className="ml-3 pr-2">
                    <span className={textStyle}>{label}</span>
                </Switch.Label>
                {loading ? (
                    <div className={loaderClasses}>
                        <SimpleLoader loading={true} classNames="" />
                    </div>
                ) : (
                    <Switch
                        disabled={disabled}
                        checked={checked}
                        onChange={onToggle}
                        className={classNames(
                            checked ? classesChecked : classesUnchecked,
                            classesBaseSwitch,
                            classesSwitch
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                checked
                                    ? translateCheckedClasses
                                    : 'translate-x-0',
                                classesBaseInner,
                                innerClasses
                            )}
                        />
                    </Switch>
                )}
            </Switch.Group>
        </div>
    )
}

export default Toggle
