import { SubmitHandler, useForm } from 'react-hook-form'
import {
    useCreateKeywordMutation,
    useGetClassifierDetailsQuery,
} from '../../api/client'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'

interface IFormInput {
    keyword: string
    regex: boolean
    allowableRegex?: string
    description?: string
    classifierDetailsId: number
}

const VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_1 = 'keyword'
const VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_2 = 'spam'
const VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_3 = 'url'

/**
 * This page uses the react-hook-form to create a new keyword.
 * https://www.react-hook-form.com/get-started/
 */
function KeywordCreatePage() {
    let navigate = useNavigate()
    let { accountId: accountIdString } = useParams()
    const accountId = Number(accountIdString)

    const [createKeywordMutation, { loading: createKeywordLoading }] =
        useCreateKeywordMutation()

    const {
        data: getClassifierDetailsData,
        loading: getClassifierDetailsLoading,
    } = useGetClassifierDetailsQuery()

    const classifierDetails =
        getClassifierDetailsData?.getClassifierDetails || []

    const validKeywordClassifiers = classifierDetails?.filter(
        (classifier) =>
            classifier.displayName
                .toLowerCase()
                .indexOf(VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_1) !== -1 ||
            // We use a different query here to exlude the tisane spam classifier, which we can probably delete anyway.
            classifier.shortName.toLowerCase() ===
                VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_2.toLowerCase() ||
            classifier.displayName
                .toLowerCase()
                .indexOf(VALID_KEYWORD_CLASSIFIERS_MUST_CONTAIN_3) !== -1
    )

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({})

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        await createKeywordMutation({
            variables: {
                params: {
                    keyword: data.keyword,
                    regex: data.regex,
                    allowableRegex: data.allowableRegex,
                    description: data.description,
                    accountId: accountId,
                    classifierDetailId: Number(data.classifierDetailsId),
                },
            },
        })

        navigate(-1)
    }

    return (
        <div className="m-10 prose prose-stone">
            <h2 className="text-gray-950 initial">Create a Keyword</h2>
            <p className="text-gray-700">
                Any content with this keyword will be moderated as severe.
            </p>
            <p className="text-gray-700">
                Please consider if you need to have spaces around the keyword.
                Keywords are case-insensitive.{' '}
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col ">
                    <label
                        htmlFor="keyword"
                        className="block  leading-6 text-gray-700 "
                    >
                        New keyword
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            id="keyword"
                            {...register('keyword', {
                                required: true,
                                // minLength: 3, Left in as a comment in case we need to re-introduce some limit in future.
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600  sm:leading-6"
                        />
                    </div>
                    {errors.keyword?.type === 'required' && (
                        <p role="alert">Keyword is required</p>
                    )}

                    <label
                        htmlFor="regex"
                        className="block  leading-6 text-gray-700  mt-6"
                    >
                        Is this a regex?
                    </label>
                    <div className="">
                        <input
                            type="checkbox"
                            id="regex"
                            {...register('regex')}
                            className="checkbox "
                        />
                    </div>

                    <label
                        htmlFor="allowableRegex"
                        className="block  leading-6 text-gray-700  mt-6"
                    >
                        Exceptions (regex)
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            id="allowableRegex"
                            {...register('allowableRegex', {
                                required: false,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600  sm:leading-6"
                        />
                    </div>

                    <label
                        htmlFor="description"
                        className="block  leading-6 text-gray-700  mt-6"
                    >
                        Description
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            id="description"
                            {...register('description', {
                                required: false,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600  sm:leading-6"
                        />
                    </div>

                    <label
                        htmlFor="classifier"
                        className="block  leading-6 text-gray-700 mt-6"
                    >
                        Classifier
                    </label>
                    <div className="mt-2">
                        <select
                            defaultValue={''}
                            id="classifier"
                            {...register('classifierDetailsId', {
                                required: true,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600  sm:leading-6"
                        >
                            <option value="" disabled>
                                Select the keyword type.
                            </option>
                            {validKeywordClassifiers.map((classifier) => (
                                <option
                                    value={classifier.id!}
                                    key={classifier.id!}
                                >
                                    {classifier.displayName}
                                </option>
                            ))}
                        </select>
                    </div>
                    {errors.classifierDetailsId?.type === 'required' && (
                        <p role="alert">A classifier is required</p>
                    )}

                    <div className="items-center flex flex-row mt-6">
                        {createKeywordLoading ? (
                            <div>Saving...</div>
                        ) : (
                            <input
                                type="submit"
                                className="text-base inline-flex items-center px-3 py-2 border border-transparent leading-4  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow-sm text-white bg-primary hover:bg-primary-700 cursor-pointer"
                            />
                        )}

                        <button
                            type="reset"
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="py-2 px-6 underline"
                        >
                            Cancel
                        </button>
                        {getClassifierDetailsLoading ? (
                            <div className="ml-2">
                                <Spinner />
                            </div>
                        ) : null}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default KeywordCreatePage
