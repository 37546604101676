import { Link } from 'react-router-dom'
import { TeamView } from '../../api/client'
import TeamUsersDisplay from './TeamUsersDisplay'

type Props = {
    team: TeamView
}

function TeamDetails(props: Props) {
    const { team } = props

    // A team for this user could not be found - this is most likely an error/
    // If the user does not have a team then the NoTeam component will be rendered instead.
    if (!!!team) {
        throw Error('Unable to display team details. Please try again.')
    }

    return (
        <div className="prose prose-stone">
            <h1 className="text-gray-950">
                You are a member of the{' '}
                <span className="text-primary-600 font-bold">{team.name}</span>{' '}
                team.
            </h1>
            This team has the following members:
            <TeamUsersDisplay teamId={team.id} />
            <p className="">
                To <strong>add</strong>, <strong>remove</strong> or{' '}
                <strong>change</strong> the details of anyone on this team
                please contact{' '}
                <Link to="/contact-us" className="text-primary-600 underline">
                    <span className="text-primary-600 underline">
                        Arwen Support
                    </span>
                </Link>
                .
            </p>
        </div>
    )
}

export default TeamDetails
