import { AccountRecord } from '../../api/client'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Button'

type Props = {
    /** The account to link to */
    account: AccountRecord
    /** Should this be a primary button or a link? */
    primary?: boolean
    /** Should this button be displayed only when there's no authorisation? */
    onlyWhenUnauthorised?: boolean
}

/**
 * A button or link to the authorisation page for an account.
 *
 * This button no longer (2024-05) generates an authorisation URL and puts it behind a button.
 * We needed a separate page for platform authorisations, and that contains the auth URL button.
 * But we still want a button to use elsewhere to take us, automatically, to our authorisation page.
 * So this is a link to the authorisation page for the given account.
 */
function AuthoriseButton(props: Props) {
    const navigate = useNavigate()
    const { account, primary, onlyWhenUnauthorised } = props

    let authorisationStatusPage = `/account/${account.id}/authorisation`
    const button = (
        <Button
            primary
            text="Authorisation"
            onClick={() => navigate(authorisationStatusPage)}
        />
    )

    const link = (
        <Link
            className={`text-primary-600 p-2 underline `}
            key={account.id}
            to={authorisationStatusPage}
        >
            Authorisation
        </Link>
    )

    const buttonOrLink = primary ? button : link

    if (onlyWhenUnauthorised) {
        if (!account.hasAuthorisation) {
            return buttonOrLink
        } else {
            return <></>
        }
    } else {
        return buttonOrLink
    }
}

export default AuthoriseButton
