import { ApolloClient } from '@apollo/client'
import { Hub } from 'aws-amplify/utils'

// This class listens for authentication events and resets the Apollo cache when the user signs in.
export default class AuthEventListener {
    constructor(private client: ApolloClient<any>) { }

    public listen() {
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signedIn':
                    this.client.cache.reset()
                    break
                case 'signInWithRedirect':
                    this.client.cache.reset()
                    break
            }
        })
    }
}
