import { useState } from 'react'
import {
    AccountRecord,
    useUpdateProfileCollectionEnabledMutation,
} from '../../api/client'
import Toggle from './Toggle'

const ProfileCollectionToggle = ({ account }: { account: AccountRecord }) => {
    const [updateProfileCollectionEnabled, { loading }] =
        useUpdateProfileCollectionEnabledMutation()

    const [enabled, setEnabled] = useState(account.profileCollectionEnabled)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateProfileCollectionEnabled({
            variables: {
                accountId: account.id,
                profileCollectionEnabled: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`profile-collection-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="Profile collection enabled (Instagram only)"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enables and disables profile collection and classification for instagram"
            size="large"
        />
    )
}

export default ProfileCollectionToggle
