import avatar from '../../assets/avatar.jpeg'
import { ProfileView } from '../../api/client'

export default function CommenterProfileImage({
    profile,
}: {
    profile: ProfileView
}) {
    return (
        <img
            className="m-0 p-0 rounded-full border-slate-300 border shadow-md"
            src={profile.profileImagePath || avatar}
            onError={(event) => {
                //@ts-ignore
                event.target.onerror = null
                //@ts-ignore
                event.target.src = avatar
            }}
            alt="avatar"
        />
    )
}
