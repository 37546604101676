import { useRecoilState } from 'recoil'
import { isUserAdminState } from '../store/DomainStore'

import { useGetTableauTokenQuery, useGetTeamsQuery } from '../api/client'
import { useEffect, useRef, useState } from 'react'

import {
    TableauViz,
    PrintScaling,
    PrintPageSize,
    PrintOrientation,
    TableauEventType,
} from '../assets/vendor/tableau-embedding-3.6.0.min.js'
import SimpleLoader from '../components/SimpleLoader'
import ReportsNotEnabled from '../components/report/ReportsNotEnabled'
import GenericErrorMessage from '../components/errors/GenericErrorMessage'

// Returned when the user is not found in Tableau
const VIZ_ERROR_AUTH_FAILED = 'auth-failed'

//FIXME:
// There is a lot of duplication of Tableau code between this and the ReportPage
// Ideally this would be shared but we're not doing that now for simplicity and speed.

function PresalesReportPage() {
    const [admin] = useRecoilState(isUserAdminState)

    const { data, loading, refetch } = useGetTableauTokenQuery()
    const vizRef = useRef(null)
    const dashboardUrl =
        'https://dub01.online.tableau.com/#/site/arwen/views/Inprogressscrapingreport/Outreachdashboard?%3Aiid=1'

    const [teamId, setTeamId] = useState(0)

    const { data: getTeamsData, loading: getTeamsLoading } = useGetTeamsQuery()

    const [industryAvg, setIndustryAvg] = useState('')

    const [isInteractive, setIsInteractive] = useState(false)
    const [inErrorState, setInErrorState] = useState(false)

    /**
     * Scenarios:
     * - there was a network error or some other unspecified error - ask to try again and then direct them to tech support.
     *
     * The errors: "no Tableau user" and "dash not found" and other unspecified network or Tableau permissions errors both derive from the Tableau Embedding API - we need to capture these errors and handle
     *
     * Essential documentation:
     * Tableau Embedding API v3 Documentation - https://help.tableau.com/current/api/embedding_api/en-us/index.html
     * Tableau Embedding API v3 Samples - https://github.com/tableau/embedding-api-v3-samples#embedding-api-v3-samples
     * Tableau Embedding API v3 Reference - https://help.tableau.com/current/api/embedding_api/en-us/reference/index.html
     *
     * We will be using the Javascript API rather than the Web Component as the API provides more flexibility and control.
     * For example with the Javascript API we can catch and properly handle Signin errors.
     */

    /**
     * Knowing when the visualisation becomes interactive is important as it allows us to control
     * what we display when. By hiding the Tableau loading screen and showing the Arwen loader
     * we make it look more like Arwen native functionality..
     */
    function onFirstInteractive() {
        setInErrorState(false)
        setIsInteractive(true)
    }

    /**
     * We only explicitly handle the auth-failed error. All other errors are handled by the default error handler.
     * @param error the viz load error object. The Tableau documentation on this is not very thorough and I deduced the following from inspection.
     */
    function onVizLoadError(error: any) {
        // @ts-ignore
        const visLoadError: TableauEventType.VizLoadError = error?.detail
        if (visLoadError._errorCode === VIZ_ERROR_AUTH_FAILED) {
            refetch()
        } else {
            setInErrorState(true)
        }
    }

    const token = data?.getTableauToken
    const url = dashboardUrl

    useEffect(() => {
        if (!!token && !!url && !!vizRef.current) {
            const viz = new TableauViz()
            viz.width = 1232
            viz.width = 827

            viz.hideTabs = true

            if (teamId === 0) {
                setTeamId(
                    getTeamsData?.getTeams ? getTeamsData?.getTeams[0]?.id : 0
                )
            }

            // We set the team name here as admins need a team selected in order to show data. By default we choose the first in the list
            // The parameter does not affect the view for non-admins.
            if (teamId !== 0) {
                const teamIdParameter = document.createElement('viz-parameter')
                teamIdParameter.setAttribute('name', 'Team ID')
                teamIdParameter.setAttribute('value', String(teamId))
                viz.append(teamIdParameter)
            }

            const industryAvgParameter = document.createElement('viz-parameter')
            industryAvgParameter.setAttribute('name', 'Industry Avg')
            industryAvgParameter.setAttribute('value', industryAvg)
            viz.append(industryAvgParameter)

            viz.src = url
            viz.toolbar = 'hidden'
            viz.token = token
            viz.device = 'desktop'

            viz.addEventListener(
                TableauEventType.FirstInteractive,
                onFirstInteractive
            )
            viz.addEventListener(TableauEventType.VizLoadError, onVizLoadError)

            // @ts-ignore
            if (vizRef.current.hasChildNodes()) {
                // @ts-ignore
                vizRef.current.removeChild(vizRef.current.childNodes[0])
            }
            // @ts-ignore
            vizRef.current.appendChild(viz)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, url, teamId, industryAvg, inErrorState, getTeamsData])

    const exportPDFOptions = {
        scaling: PrintScaling.AtMost1PageWide,
        pageSize: PrintPageSize.A4,
        orientation: PrintOrientation.Portrait,
    }

    // TODO: this is too specific an error - it should be more generic. for example this will happen when the dashboard id associated with the user is incorrect.
    const reportLoadingErrorDisplay = (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24  lg:px-8">
            <div className="max-w-max">
                <GenericErrorMessage message="There was an error generating your report." />
            </div>
        </div>
    )

    async function exportPDF() {
        // @ts-ignore
        const publishedSheetsInfo =
            // @ts-ignore
            vizRef.current.childNodes[0].workbook.publishedSheetsInfo
        const selectedWorkbookSheetsForExport = Array.from(
            publishedSheetsInfo,
            (publishedSheetInfo: any) => publishedSheetInfo.name
        )
        // @ts-ignore
        await vizRef.current.childNodes[0].exportPDFAsync(
            selectedWorkbookSheetsForExport,
            exportPDFOptions
        )
    }

    let onTeamChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let teamId = event.target.value
        setTeamId(parseInt(teamId))
        setIsInteractive(false)
    }

    let onIndustryAvgChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIndustryAvg(event.target.value)
        setIsInteractive(false)
    }

    const teamsDropdown = (
        <div className="text-center text-gray-950 items-center flex ">
            <div className=""> </div>
            <select
                id="visibleSocialMediaServices"
                name="visibleSocialMediaServices"
                className="shadow-md hover:cursor-pointer block w-full pl-3 pr-10 py-2 text-sm  hover:bg-gray-100  border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                defaultValue={''}
                onChange={onTeamChangeHandler}
            >
                {getTeamsData?.getTeams?.map((team, index) => (
                    <option key={index} value={team.id}>
                        {team.name}
                    </option>
                ))}
            </select>
        </div>
    )

    const industryAvgInput = (
        <div className="text-center text-gray-500 items-center flex ">
            <div className=""> </div>
            <input
                id="visibleSocialMediaServices"
                name="visibleSocialMediaServices"
                className="shadow-md  hover:cursor-text block w-full pl-3 pr-10 py-2 text-sm hover:bg-gray-100  border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                defaultValue={''}
                placeholder="Industry average..."
                onChange={onIndustryAvgChangeHandler}
            ></input>
        </div>
    )

    const pdfButton = (
        <button
            className="inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
            text-white bg-primary hover:bg-primary-800 "
            onClick={() => exportPDF()}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
            </svg>
            Download Report
        </button>
    )

    return (
        <>
            {loading || getTeamsLoading ? (
                // Hide the Tableau embedded object until: the token has been fetched and the visualisation is interactive.
                <div className="w-full absolute bg-white h-full mt-1 z-30 ">
                    <div className="mt-20">
                        {inErrorState ? (
                            reportLoadingErrorDisplay
                        ) : (
                            <SimpleLoader loading={loading} />
                        )}
                    </div>
                </div>
            ) : null}

            {admin && data?.getTableauToken && dashboardUrl && !inErrorState && (
                <div className="w-full bg-white pt-8 pl-8 flex flex-col items-center">
                    <div className="flex flex-row flex-wrap gap-x-4 gap-y-4">
                        {admin ? teamsDropdown : null}
                        {industryAvgInput}
                        {pdfButton}
                    </div>
                    {!isInteractive ? (
                        <div className="text-lg bg-primary-600 rounded-sm p-2 px-4 mt-4 text-white animate-pulse">
                            We are generating your report - this can take up to
                            30 seconds.
                        </div>
                    ) : null}
                    <div
                        className="vizDiv  bg-white mb-20 mt-12"
                        ref={vizRef}
                    ></div>
                </div>
            )}
            {!admin && <ReportsNotEnabled />}
        </>
    )
}

export default PresalesReportPage
