import Loader from '../Loader'

type Props = {}

function LoadingWelcome(props: Props) {
    return (
        <>
            <div className="flex flex-col items-center justify-center bg-white h-screen w-screen">
                <div className="flex flex-row">
                    <Loader status={'pending'} />
                </div>
            </div>
        </>
    )
}

export default LoadingWelcome
