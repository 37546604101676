import * as React from 'react'
import { ASYNC_STATUS } from '../hooks/useAsync'

export interface Props {
    status: 'idle' | 'pending' | 'success' | 'error'
}

function Loader(props: Props) {
    const { status } = props

    if (status === ASYNC_STATUS.PENDING) {
        return (
            <div className="flex justify-center pt-20">
                <div className="inline-block animate-ping ease duration-300 w-5 h-5 bg-black mx-2"></div>
            </div>
        )
    }
    return <div />
}

export default Loader
