import {
    FilterFn,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { RankingInfo } from '@tanstack/match-sorter-utils'
import React, { useEffect, useState } from 'react'
import { UserAuthEventView, useGetUserAuthEventsQuery } from '../../api/client'
import { fuzzyFilter, fuzzySort } from '../../components/table/helpers'
import SearchInput from '../SearchInput'
import Spinner from '../Spinner'
import SimplePagination from '../table/SimplePagination'
import SimpleTable from '../table/SimpleTable'
import { formatISO9075, parseISO } from 'date-fns'
import { toastError } from '../Notification'

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

type Props = {
    username: string
}

const columnHelper = createColumnHelper<UserAuthEventView>()

function UserAuthEventList(props: Props) {
    const { username } = props
    const [globalFilter, setGlobalFilter] = useState('')
    const [data, setData] = useState<UserAuthEventView[]>([])

    const { data: getUserAuthEventsData, loading: getUserAuthEventsLoading } =
        useGetUserAuthEventsQuery({
            variables: {
                username,
            },
            onError: (error) => {
                console.error(error)

                toastError('Failed to retrieve user auth events.')
            },
        })

    const columns = [
        columnHelper.accessor('username', {
            header: () => 'Username',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('eventType', {
            header: () => 'Type',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('eventTimestamp', {
            header: () => 'Date',
            cell: (info) => {
                return (
                    <span>
                        <span>{formatISO9075(parseISO(info.getValue()))}</span>
                    </span>
                )
            },
        }),
        columnHelper.accessor('eventResponse', {
            header: () => 'Response',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('ipAddress', {
            header: () => 'IP',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('deviceName', {
            header: () => 'Device',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('city', {
            header: () => 'City',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('country', {
            header: () => 'Country',
            filterFn: 'fuzzy',
            sortingFn: fuzzySort,
            cell: (info) => info.getValue(),
        }),
    ]

    useEffect(() => {
        if (getUserAuthEventsData?.getUserAuthEvents) {
            setData(getUserAuthEventsData?.getUserAuthEvents)
        }
    }, [getUserAuthEventsData])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        // pageCount: Math.ceil(userCount / pageSize),
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <>
            <div className="m-10 prose prose-stone">
                <h3 className="text-gray-950 initial">User Login Events</h3>

                <div className="flex flex-row items-baseline">
                    <SearchInput
                        keyword={globalFilter ?? ''}
                        onKeywordChange={(value) =>
                            setGlobalFilter(String(value))
                        }
                        onSearch={(value) => setGlobalFilter(String(value))}
                    />
                    <div>
                        {getUserAuthEventsLoading && <Spinner size={6} />}
                    </div>
                </div>
            </div>
            <div className="mx-10 prose prose-stone max-w-none">
                <SimpleTable table={table} />

                <SimplePagination table={table} />
            </div>
        </>
    )
}

export default UserAuthEventList
