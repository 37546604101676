import { useRecoilState } from 'recoil'
import SimpleLoader from '../components/SimpleLoader'
import NoTeam from '../components/team/NoTeam'
import TeamDetails from '../components/team/TeamDetails'
import { userState } from '../store/DomainStore'
import { useTitle } from '../components/TitleProvider'
import { useGetUserTeamsQuery } from '../api/client'

type Props = {}

function TeamPage(props: Props) {
    useTitle('Team details')
    const [user] = useRecoilState(userState)

    const { data: userTeamsData, loading: userTeamsLoading } =
        useGetUserTeamsQuery({
            variables: { userId: user?.id || 0 },
            skip: !user,
        })

    let display

    // A user should always be available at this point - but just in case show the loading spinner
    if (!user || userTeamsLoading) {
        display = (
            <div>
                <SimpleLoader loading={true} />
            </div>
        )
    }

    // Not all users are in a team so handle this scenario
    if (
        !userTeamsLoading &&
        userTeamsData?.getUserTeams &&
        userTeamsData?.getUserTeams.length === 0
    ) {
        display = <NoTeam />
    }

    // We have a team id so display the team details
    if (userTeamsData?.getUserTeams && userTeamsData?.getUserTeams.length > 0) {
        display = (
            <>
                {userTeamsData?.getUserTeams.map((team) => {
                    return (
                        <div className="mb-16">
                            <TeamDetails team={team} />
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="w-full h-full bg-white pt-8 pl-8 flex flex-col items-center">
            <div className="m-10">{display}</div>
        </div>
    )
}

export default TeamPage
