import { useState } from 'react'
import { AccountRecord, useUpdateFetchAdsMutation } from '../../api/client'
import Toggle from './Toggle'

const FetchAdsToggle = ({ account }: { account: AccountRecord }) => {
    const [updateFetchAds, { loading }] = useUpdateFetchAdsMutation()

    const [enabled, setEnabled] = useState(account.fetchAds)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateFetchAds({
            variables: {
                accountId: account.id,
                fetchAds: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`fetch-ads-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="Fetch ads"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enable or disable fetching of Ads for Meta profiles"
            size="large"
        />
    )
}

export default FetchAdsToggle
