import { useRecoilState } from 'recoil'
import { translateAllState } from '../../store/UIStore'
import LanguageSelectDropDown from './LanguageSelectDropDown'
import mixpanel from 'mixpanel-browser'
import Toggle from '../toggles/Toggle'
import { ArrowRightIcon } from '@heroicons/react/20/solid'

export default function TranslationSettings() {
    const [translateAll, setTranslateAll] = useRecoilState(translateAllState)
    const onToggle = async (isEnabled: boolean) => {
        setTranslateAll(isEnabled)
        mixpanel.track(
            `Translation - global - toggled ${isEnabled ? 'on' : 'off'}`
        )
    }

    return (
        <div className="flex flex-row items-center">
            <Toggle
                id="translationToggle"
                disabled={false}
                checked={translateAll}
                label="Translate all"
                loading={false}
                onToggle={onToggle}
                tooltip="Enable or disable translation of all content."
                size="medium"
            />

            <ArrowRightIcon className="mx-2 h-6 w-6 text-gray-600" />
            <LanguageSelectDropDown />
        </div>
    )
}
