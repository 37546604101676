import { useState } from 'react'
import FilterInput, { FilterItem } from './FilterInput'
import { AccountRecord, useSearchAccountsQuery } from '../../../api/client'

type Props = {
    selectedAccountIds: Array<number>
    selectedTeamIds: Array<number>
    onUpdateSelectedAccounts: (accountIds: Array<AccountRecord>) => void
}
export default function AccountFilter(props: Props) {
    const { selectedAccountIds, selectedTeamIds, onUpdateSelectedAccounts } =
        props
    const [searchTerm, setSearchTerm] = useState('')

    const offset = 0
    const limit = 200

    // Fetch the initial batch of data
    const { data, loading } = useSearchAccountsQuery({
        variables: {
            params: {
                offset,
                limit,
                keyword: searchTerm,
                teamIds: selectedTeamIds,
                socialMediaServices: [],
            },
        },
        onError: (error: any) => {
            throw Error(error.message)
        },
    })

    const allAccounts = data?.searchAccounts?.accounts || []
    const selectedAccounts = allAccounts.filter((account) =>
        selectedAccountIds.includes(account.id)
    )

    function handleUpdateSelectedAccounts(selectedItems: Array<FilterItem>) {
        // Map back from selectedItems to accounts
        onUpdateSelectedAccounts(
            allAccounts.filter((account) => {
                return selectedItems
                    .map((selectedItem) => selectedItem.id)
                    .includes(account.id)
            })
        )
    }

    return (
        <>
            <FilterInput
                filterTypeName="Profiles"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => {
                    setSearchTerm(term)
                }}
                onUpdateSelectedItems={handleUpdateSelectedAccounts}
                selectedItems={selectedAccounts.map(mapAccountToFilterItem)}
                searchResults={allAccounts.map(mapAccountToFilterItem)}
                searching={loading}
            />
        </>
    )
}

function mapAccountToFilterItem(account: AccountRecord): FilterItem {
    return {
        id: account.id,
        name: account.serviceAccountUsername || '',
        displayName: account.accountName || '',
        iconName: account.socialMediaServiceShortName || '',
    }
}
