import {
    BoundaryType,
    ModerationBoundaryTemplate,
    Queue,
    SeverityCategory,
} from '../../models/Moderation'

export type ModerationTemplate = {
    id: number
    displayName: string
    severe: Map<string, ModerationBoundaryTemplate>
    suspect: Map<string, ModerationBoundaryTemplate>
    safe: Map<string, ModerationBoundaryTemplate>
}

function generateTemplateFamily(
    queue: Queue,
    options: QueueBoundaryTypes
): Map<string, ModerationBoundaryTemplate> {
    const template = new Map<string, ModerationBoundaryTemplate>()

    // We need this test as if the value is zero JS considers it falsy .
    function isNotNullOrUndefined(value: any): boolean {
        return value !== null && value !== undefined
    }

    // Text classifiers
    if (isNotNullOrUndefined(options.hateSpeech)) {
        template.set('SPECTRUM_hate-speech'.toLowerCase(), {
            classifierName: 'SPECTRUM_hate-speech',
            queue: queue,
            type: BoundaryType.CATEGORICAL,
            severityCategory: options.hateSpeech,
        })
    }
    if (isNotNullOrUndefined(options.identityAttack)) {
        template.set('IDENTITY_ATTACK'.toLowerCase(), {
            classifierName: 'IDENTITY_ATTACK',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.identityAttack,
        })
    }
    if (isNotNullOrUndefined(options.toxicity)) {
        template.set('TOXICITY'.toLowerCase(), {
            classifierName: 'TOXICITY',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.toxicity,
        })
    }
    if (isNotNullOrUndefined(options.profanity)) {
        template.set('PROFANITY'.toLowerCase(), {
            classifierName: 'PROFANITY',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.profanity,
        })
    }
    if (isNotNullOrUndefined(options.selfHarm)) {
        template.set('SPECTRUM_self-harm'.toLowerCase(), {
            classifierName: 'SPECTRUM_self-harm',
            queue: queue,
            type: BoundaryType.CATEGORICAL,
            severityCategory: options.selfHarm,
        })
    }
    if (isNotNullOrUndefined(options.sexual)) {
        template.set('SPECTRUM_sexual'.toLowerCase(), {
            classifierName: 'SPECTRUM_sexual',
            queue: queue,
            type: BoundaryType.CATEGORICAL,
            severityCategory: options.sexual,
        })
    }
    if (isNotNullOrUndefined(options.sexuallyExplicit)) {
        template.set('SEXUALLY_EXPLICIT'.toLowerCase(), {
            classifierName: 'SEXUALLY_EXPLICIT',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.sexuallyExplicit,
        })
    }
    if (isNotNullOrUndefined(options.insultSpectrum)) {
        template.set('SPECTRUM_insult'.toLowerCase(), {
            classifierName: 'SPECTRUM_insult',
            queue: queue,
            type: BoundaryType.CATEGORICAL,
            severityCategory: options.insultSpectrum,
        })
    }
    if (isNotNullOrUndefined(options.insultPerspective)) {
        template.set('INSULT'.toLowerCase(), {
            classifierName: 'INSULT',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.insultPerspective,
        })
    }
    if (isNotNullOrUndefined(options.severeToxicity)) {
        template.set('SEVERE_TOXICITY'.toLowerCase(), {
            classifierName: 'SEVERE_TOXICITY',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.severeToxicity,
        })
    }
    if (isNotNullOrUndefined(options.threat)) {
        template.set('THREAT'.toLowerCase(), {
            classifierName: 'THREAT',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.threat,
        })
    }
    if (isNotNullOrUndefined(options.spam)) {
        template.set('SPAM'.toLowerCase(), {
            classifierName: 'SPAM',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.spam,
        })
    }
    // Keywords
    if (isNotNullOrUndefined(options.blockedKeyword)) {
        template.set('BLOCKED_KEYWORDS'.toLowerCase(), {
            classifierName: 'BLOCKED_KEYWORDS',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.blockedKeyword,
        })
    }
    if (isNotNullOrUndefined(options.racistKeyword)) {
        template.set('KEYWORD_RACIST'.toLowerCase(), {
            classifierName: 'KEYWORD_RACIST',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.racistKeyword,
        })
    }
    // Image classifiers
    if (isNotNullOrUndefined(options.pornImage)) {
        template.set('IMAGEPORN'.toLowerCase(), {
            classifierName: 'IMAGEPORN',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.pornImage,
        })
    }
    if (isNotNullOrUndefined(options.drugsImage)) {
        template.set('IMAGEDRUGS'.toLowerCase(), {
            classifierName: 'IMAGEDRUGS',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.drugsImage,
        })
    }
    if (isNotNullOrUndefined(options.alcoholImage)) {
        template.set('IMAGEALCOHOL'.toLowerCase(), {
            classifierName: 'IMAGEALCOHOL',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.alcoholImage,
        })
    }
    if (isNotNullOrUndefined(options.weaponsImage)) {
        template.set('IMAGEWEAPONS'.toLowerCase(), {
            classifierName: 'IMAGEWEAPONS',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.weaponsImage,
        })
    }
    if (isNotNullOrUndefined(options.gamblingImage)) {
        template.set('IMAGEGAMBLING'.toLowerCase(), {
            classifierName: 'IMAGEGAMBLING',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.gamblingImage,
        })
    }
    if (isNotNullOrUndefined(options.swimUnderwearImage)) {
        template.set('IMAGESWIMUNDERWEAR'.toLowerCase(), {
            classifierName: 'IMAGESWIMUNDERWEAR',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.swimUnderwearImage,
        })
    }
    if (isNotNullOrUndefined(options.offensiveGesturesImage)) {
        template.set('IMAGEOFFENSIVEGESTURES'.toLowerCase(), {
            classifierName: 'IMAGEOFFENSIVEGESTURES',
            queue: queue,
            type: BoundaryType.NUMERICAL,
            probability: options.offensiveGesturesImage,
        })
    }

    return template
}

type QueueBoundaryTypes = {
    hateSpeech?: SeverityCategory
    identityAttack?: number
    toxicity?: number
    profanity?: number
    selfHarm?: SeverityCategory
    sexual?: SeverityCategory
    sexuallyExplicit?: number
    insultSpectrum?: SeverityCategory
    insultPerspective?: number
    severeToxicity?: number
    threat?: number
    spam?: number
    blockedKeyword?: number
    racistKeyword?: number
    pornImage?: number
    swimUnderwearImage?: number
    drugsImage?: number
    alcoholImage?: number
    offensiveGesturesImage?: number
    gamblingImage?: number
    weaponsImage?: number
}

export default function getModerationTemplates(
    socialMediaServiceShortName?: string // twitter facebook instagram youtube tiktook
) {
    const isTwitter = socialMediaServiceShortName === 'twitter'
    const isTikTok = socialMediaServiceShortName === 'TikAPI'
    const isYouTube = socialMediaServiceShortName === 'youtube'

    return [
        {
            id: 0,
            displayName: 'Family',
            severe: generateTemplateFamily('SEVERE', {
                hateSpeech: SeverityCategory.LOW,
                identityAttack: 0.7,
                toxicity: 0.8,
                profanity: 0.8,
                selfHarm: SeverityCategory.HIGH,
                sexual: SeverityCategory.LOW,
                sexuallyExplicit: 0.7,
                insultSpectrum: SeverityCategory.HIGH,
                insultPerspective: 0.8,
                severeToxicity: 0.6,
                threat: 0.7,
                spam: isTwitter || isTikTok || isYouTube ? 0.98 : 0.8,
                blockedKeyword: 1,
                racistKeyword: 1,
                pornImage: 0.5,
                swimUnderwearImage: 0.5,
                drugsImage: 0.5,
                alcoholImage: 0.5,
                offensiveGesturesImage: 0.5,
                gamblingImage: 0.5,
                weaponsImage: 0.5,
            }),
            suspect: generateTemplateFamily('SUSPECT', {
                hateSpeech: SeverityCategory.LOW,
                identityAttack: 0.6,
                toxicity: 0.7,
                profanity: 0.7,
                selfHarm: SeverityCategory.LOW,
                sexual: SeverityCategory.LOW,
                sexuallyExplicit: 0.65,
                insultSpectrum: SeverityCategory.LOW,
                insultPerspective: 0.7,
                severeToxicity: 0.5,
                threat: 0.65,
                spam: isTwitter || isTikTok || isYouTube ? 0.9 : 0.7,
                pornImage: 0.25,
                swimUnderwearImage: 0.25,
                drugsImage: 0.25,
                alcoholImage: 0.25,
                offensiveGesturesImage: 0.25,
                gamblingImage: 0.25,
                weaponsImage: 0.25,
            }),
            safe: generateTemplateFamily('SAFE', {
                hateSpeech: SeverityCategory.NONE,
                identityAttack: 0,
                toxicity: 0,
                profanity: 0,
                selfHarm: SeverityCategory.NONE,
                sexual: SeverityCategory.NONE,
                sexuallyExplicit: 0,
                insultSpectrum: SeverityCategory.NONE,
                insultPerspective: 0,
                severeToxicity: 0,
                threat: 0,
                spam: 0,
                blockedKeyword: 0,
                racistKeyword: 0,
                pornImage: 0,
                swimUnderwearImage: 0,
                drugsImage: 0,
                alcoholImage: 0,
                offensiveGesturesImage: 0,
                gamblingImage: 0,
                weaponsImage: 0,
            }),
        },
        {
            id: 1,
            displayName: 'Standard',
            severe: generateTemplateFamily('SEVERE', {
                hateSpeech: SeverityCategory.HIGH,
                identityAttack: 0.8,
                toxicity: 0.9,
                profanity: 0.9,
                selfHarm: SeverityCategory.HIGH,
                sexual: SeverityCategory.HIGH,
                sexuallyExplicit: 0.8,
                insultSpectrum: SeverityCategory.HIGH,
                insultPerspective: 0.9,
                severeToxicity: 0.7,
                threat: 0.9,
                spam: isTwitter || isTikTok || isYouTube ? 0.98 : 0.8,
                blockedKeyword: 1,
                racistKeyword: 1,
                pornImage: 0.5,
            }),
            suspect: generateTemplateFamily('SUSPECT', {
                hateSpeech: SeverityCategory.LOW,
                identityAttack: 0.7,
                toxicity: 0.8,
                profanity: 0.8,
                selfHarm: SeverityCategory.LOW,
                sexual: SeverityCategory.LOW,
                sexuallyExplicit: 0.7,
                insultSpectrum: SeverityCategory.LOW,
                insultPerspective: 0.8,
                severeToxicity: 0.65,
                threat: 0.8,
                spam: isTwitter || isTikTok || isYouTube ? 0.9 : 0.7,
                pornImage: 0.25,
            }),
            safe: generateTemplateFamily('SAFE', {
                hateSpeech: SeverityCategory.NONE,
                identityAttack: 0,
                toxicity: 0,
                profanity: 0,
                selfHarm: SeverityCategory.NONE,
                sexual: SeverityCategory.NONE,
                sexuallyExplicit: 0,
                insultSpectrum: SeverityCategory.NONE,
                insultPerspective: 0,
                severeToxicity: 0,
                threat: 0,
                spam: 0,
                blockedKeyword: 0,
                racistKeyword: 0,
                pornImage: 0,
                swimUnderwearImage: 0,
                drugsImage: 0,
                alcoholImage: 0,
                offensiveGesturesImage: 0,
                gamblingImage: 0,
                weaponsImage: 0,
            }),
        },
        {
            id: 2,
            displayName: 'Sport',
            severe: generateTemplateFamily('SEVERE', {
                hateSpeech: SeverityCategory.HIGH,
                identityAttack: 0.8,
                toxicity: 0.9,
                profanity: 0.95,
                selfHarm: SeverityCategory.HIGH,
                sexual: SeverityCategory.HIGH,
                sexuallyExplicit: 0.9,
                insultSpectrum: SeverityCategory.HIGH,
                insultPerspective: 0.95,
                severeToxicity: 0.75,
                threat: 1.01,
                spam: isTwitter || isTikTok || isYouTube ? 0.98 : 0.8,
                blockedKeyword: 1,
                racistKeyword: 1,
                pornImage: 0.5,
            }),
            suspect: generateTemplateFamily('SUSPECT', {
                hateSpeech: SeverityCategory.LOW,
                identityAttack: 0.7,
                toxicity: 0.8,
                profanity: 0.85,
                selfHarm: SeverityCategory.LOW,
                sexual: SeverityCategory.LOW,
                sexuallyExplicit: 0.7,
                insultSpectrum: SeverityCategory.LOW,
                insultPerspective: 0.8,
                severeToxicity: 0.65,
                threat: 0.9,
                spam: isTwitter || isTikTok || isYouTube ? 0.9 : 0.7,
                blockedKeyword: 1,
                racistKeyword: 1,
                pornImage: 0.25,
            }),
            safe: generateTemplateFamily('SAFE', {
                hateSpeech: SeverityCategory.NONE,
                identityAttack: 0,
                toxicity: 0,
                profanity: 0,
                selfHarm: SeverityCategory.NONE,
                sexual: SeverityCategory.NONE,
                sexuallyExplicit: 0,
                insultSpectrum: SeverityCategory.NONE,
                insultPerspective: 0,
                severeToxicity: 0,
                threat: 0,
                spam: 0,
                blockedKeyword: 0,
                racistKeyword: 0,
                pornImage: 0,
            }),
        },
        {
            id: 3,
            displayName: 'Spam only',
            severe: generateTemplateFamily('SEVERE', {
                spam: isTwitter || isTikTok || isYouTube ? 0.98 : 0.8,
            }),
            suspect: generateTemplateFamily('SUSPECT', {
                spam: isTwitter || isTikTok || isYouTube ? 0.9 : 0.7,
            }),
            safe: generateTemplateFamily('SAFE', {
                spam: 0,
            }),
        },
    ]
}
