import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { object, string } from 'yup'
import {
    AccountRecord,
    CreateAccountMutation,
    useCreateAccountMutation,
} from '../api/client'
import Button from '../components/Button'
import { toastError, toastSuccess } from '../components/Notification'
import SimpleLoader from '../components/SimpleLoader'
import { useTitle } from '../components/TitleProvider'
import TwitterAuthorisationButton from '../components/authorise/platforms/TwitterAuthorisationButton'
import TwitterUsernameInput from '../components/inputs/TwitterUsernameInput'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import {
    selectedAccountState,
    shouldRefetchAccountsState,
} from '../store/UIStore'
import mapFromCreateAccountMutationToAccountRecord from '../models/AccountRecord.mapper'

interface FormData {
    username: string
    name: string
    serviceAccountId: string
}

const validationSchema = object({
    username: string().required('Username is required'),
    name: string().required('Name is required'),
    serviceAccountId: string().required('Service Account ID is required'),
})

function NewTwitterAccountPage() {
    useTitle('Add Twitter profile')
    const setSelectedAccount = useSetRecoilState(selectedAccountState)
    const [account, setAccount] = useState<AccountRecord | null>()
    const form = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            username: '',
            name: '',
            serviceAccountId: '',
        },
    })

    const [onboarding] = useState(() => {
        const onboarding = localStorage.getItem('onboarding')
        return onboarding === 'true'
    })

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [_shouldRefetchAccounts, setShouldRefetchAccounts] = useRecoilState(
        shouldRefetchAccountsState
    )

    const [createAccountMutation, { loading: authSaveLoading }] =
        useCreateAccountMutation({
            onCompleted: (account: CreateAccountMutation) => {
                if (account.createAccount) {
                    let newAccount =
                        mapFromCreateAccountMutationToAccountRecord(
                            account.createAccount
                        )
                    setSelectedAccount(newAccount)
                    setAccount(newAccount)
                } else {
                    console.error(
                        'We created an account but did not get an account back from the server!'
                    )
                }
                setShouldRefetchAccounts(true)
                toastSuccess(
                    `Twitter account ${account?.createAccount?.accountName} added`
                )
            },
            onError: (error) => {
                toastError(error.message)
            },
        })

    const onSubmit = async (data: FormData) => {
        createAccountMutation({
            variables: {
                serviceAccountId: data.serviceAccountId,
                accountName: data.username,
                serviceAccountUsername: data.username,
                serviceName: 'twitter',
            },
        })
    }

    let component
    if (account) {
        component = (
            <TextPageContainer>
                <TextPageHeader title="One more step..." />
                <p>
                    Authorise your account so we can start moderating your
                    comments.
                </p>
                <TwitterAuthorisationButton account={account} />
            </TextPageContainer>
        )
    } else {
        component = (
            <TextPageContainer>
                <TextPageHeader title="Add an X/Twitter profile" />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        {/* We have different text for player onboarding vs normal onboarding */}
                        {onboarding ? (
                            <p>
                                You need to give Arwen permission to retrieve
                                and hide unwanted content on your profile. Arwen
                                is a recognised X/Twitter partner and will never
                                post on your behalf.
                            </p>
                        ) : (
                            <p>
                                You need to give Arwen permission to retrieve
                                and hide unwanted content and direct messages on
                                your profile. Arwen is a recognised X/Twitter
                                partner and will never post on your behalf.
                            </p>
                        )}
                        <label>Your X/Twitter username (case-sensitive):</label>
                        <TwitterUsernameInput />
                        <div className="mt-4">
                            <Button
                                disabled={
                                    !form.formState.isValid ||
                                    form.formState.isSubmitting
                                }
                                primary
                                text="Add profile"
                                type="submit"
                                data-cy="add_twitter_account_button"
                            />
                        </div>
                    </form>
                </FormProvider>
                <SimpleLoader loading={authSaveLoading} />
            </TextPageContainer>
        )
    }
    return component
}

export default NewTwitterAccountPage
