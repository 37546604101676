import { ContentOutcomeView } from '../../../api/client'
import { displayReplyComponent } from '../../../util/inReplyToUsername'

type Props = { content: ContentOutcomeView }

export default function ContentReplyTo(props: Props) {
    const { content } = props
    const shouldDisplay = displayReplyComponent(content)

    if (!shouldDisplay) {
        return null
    }

    return (
        <div className="text-gray-500">
            Replying to{' '}
            <span className="text-primary">@{content.inReplyToUsername}</span>
        </div>
    )
}
