import { EnvelopeIcon } from '@heroicons/react/24/outline'

function SupportEmailText() {
    const iconClassNames = 'h-6 w-6 text-gray-500 mr-2 mt-1'
    return (
        <div className="flex flex-row ">
            <EnvelopeIcon className={iconClassNames} />
            <div>
                <strong>Email</strong>:{' '}
                <a
                    href="mailto:support@arwen.ai"
                    target="_blank"
                    rel="noreferrer"
                >
                    support@arwen.ai
                </a>
            </div>
        </div>
    )
}

export default SupportEmailText
