type Props = {
    replySuggestion: string
    onSelection: (message: string) => void
}

/**
 * Component to display suggested replies which are editable and sendable
 */
export default function SuggestedReply(props: Props) {
    const { replySuggestion, onSelection } = props

    return (
        <div className="flex items-start space-x-4">
            <div className="min-w-0 flex-1 pt-3">
                <form action="#" className="relative flex flex-row">
                    <div className="group flex flex-grow rounded-lg hover:shadow-xl border-white hover:border-none bg-gray-300 hover:bg-gradient-to-r from-primary-700 to-primary-500 opacity-100 cursor-pointer">
                        <div
                            className="flex-grow bg-white overflow-hidden rounded-md shadow-md  ring-gray-900 m-0.5 "
                            onClick={() => {
                                onSelection(replySuggestion)
                            }}
                            data-tip={'Click to edit and send'}
                        >
                            <label htmlFor="comment" className="sr-only">
                                Your response
                            </label>
                            <div className="resize-none border-0 py-1.5 ml-2 mr-1.5 text-gray-900 placeholder:text-gray-400">
                                {replySuggestion}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
