import { AccountRecord } from '../../../api/client'
import TikTokAuthorisationButton from './TikTokAuthorisationButton'

type Props = {
    account: AccountRecord
}

/**
 * Shows the authorisation status of a TikTok account.
 *
 * This type of profile is added via OAuth, so it will always have an authorisation
 * row, whether or not it's valid. If it has no authorisation then someone
 * has _probably_ been messing with the database, and we should advise the
 * user to contact support.
 */
function TikAPIAuthorisation(props: Props) {
    const { account } = props

    let alreadyAuthorised = account.hasAuthorisation
    return (
        <>
            {alreadyAuthorised ? (
                <>
                    <p>This profile is authorised.</p>
                    <div className="mt-4">
                        <TikTokAuthorisationButton />
                    </div>
                </>
            ) : (
                <>
                    <p>
                        This profile has no authorisation! Please contact
                        support.
                    </p>
                    <div className="mt-4">
                        <TikTokAuthorisationButton />
                    </div>
                </>
            )}
        </>
    )
}

export default TikAPIAuthorisation
