import { atom } from 'recoil'
import { AccountRecord } from '../api/client'

export const productIdState = atom({
    key: 'productIdState',
    default: '',
})

export const priceIdState = atom({
    key: 'priceIdState',
    default: '',
})

export const shouldRefetchAccountsState = atom({
    key: 'shouldRefetchAccounts',
    default: false,
})

export const selectedAccountState = atom<AccountRecord | undefined>({
    key: 'selectedAccountState',
    default: undefined,
})

export const fetchTimePeriodState = atom({
    key: 'fetchTimePeriodState',
    default: 7,
})

export const SUPPORT_EMAIL = 'info@arwen.ai'

export const MODERATION_ALL = 'all'

export const targetLanguageState = atom({
    key: 'targetLanguage',
    default: 'English',
})

export const translateAllState = atom({
    key: 'translateAll',
    default: false,
})
