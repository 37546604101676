import { useState } from 'react'
import { AccountRecord, useUpdateScrapeContentMutation } from '../../api/client'
import Toggle from './Toggle'

const ScrapingToggle = ({
    account,
    size,
}: {
    account: AccountRecord
    size: 'small' | 'medium' | 'large'
}) => {
    const [updateScrapeContent, { loading }] = useUpdateScrapeContentMutation()

    const [scrapeContentEnabled, setScrapeContentEnabled] = useState(
        account.scrapeContent
    )

    const onToggle = async (isEnabled: boolean) => {
        setScrapeContentEnabled(isEnabled)

        await updateScrapeContent({
            variables: { accountId: account.id, scrapeContent: isEnabled },
        })
    }

    return (
        <Toggle
            id={`scraping-${account.id.toString()}`}
            disabled={false}
            checked={scrapeContentEnabled}
            label="Scraping"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enable or disable scraping for content."
            size={size}
        />
    )
}

export default ScrapingToggle
