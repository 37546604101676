import exampleDashboard from '../../assets/example_dashboard.png'

export default function ReportsNotEnabled() {
    return (
        <div className="m-10">
            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8 text-center flex flex-col items-center">
                    <article className="prose lg:prose-xl">
                        <h2 className="mt-4 text-2xl font-bold text-gray-950 sm:text-3xl sm:tracking-tight">
                            Reports are not enabled for your account
                        </h2>
                        <p>
                            Please contact{' '}
                            <a href="mailto:info@arwen.ai">
                                <span className="text-primary-600 underline">
                                    {' '}
                                    customer support{' '}
                                </span>
                            </a>
                            to get started.
                        </p>
                        <div className="relative mt-24 border-2 border-gray-300 border-solid">
                            <img alt="Example report" src={exampleDashboard} />
                            <div
                                className="absolute left-20 top-64 text-6xl font-bold text-gray-950 opacity-30"
                                style={{ transform: 'rotate(-45deg)' }}
                            >
                                EXAMPLE REPORT
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}
