import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

type Props = {
    showAll: boolean
    onShowAllChange: (showAll: boolean) => void
}

/**
 * Generates a little chevron toggle to show and hide the classifiers.
 *
 * Could be used for other things but currently isn't
 */
export default function ShowHideChevronToggle(props: Props) {
    const { showAll, onShowAllChange } = props

    function handleShowAllOnClick() {
        onShowAllChange(!showAll)
    }
    return (
        <div onClick={handleShowAllOnClick}>
            {showAll ? (
                <div className="cursor-pointer text-gray-300 hover:text-gray-500">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </div>
            ) : (
                <div className="cursor-pointer text-gray-300 hover:text-gray-500">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </div>
            )}
        </div>
    )
}
