import ReplyButton from '../../actions/buttons/ReplyButton'
import {
    ContentActionView,
    ContentOutcomeView,
    ProfileView,
} from '../../../api/client'

type Props = {
    reply: string
    content: ContentOutcomeView
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    profileCache: ProfileView[]
    actionCacheMap: Map<number, ContentActionView[]>
}

const TRUNCATE_SIZE = 200

export default function ReplyText({
    reply,
    content,
    onActionClick,
    profileCache,
    actionCacheMap,
}: Props) {
    return (
        <div className="flex flex-col">
            <div className="grid grid-flow-row grid-cols-1 text-gray-950">
                <div
                    style={{
                        gridColumnStart: 1,
                        gridRowStart: 1,
                    }}
                >
                    <p className="text-gray-700 text-sm">Your reply</p>
                    <div className="flex">
                        <p className="w-11/12 overflow-hidden whitespace-nowrap truncate">
                            {reply}
                        </p>
                        {reply.length >= TRUNCATE_SIZE && (
                            <ReplyButton
                                content={content}
                                onActionClick={onActionClick}
                                profileCache={profileCache}
                                actionCacheMap={actionCacheMap}
                                isMoreButton
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
