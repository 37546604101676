const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID as string

function init(resolve: () => void) {
    // Don't try and init the Facebook SDK if it's not yet been loaded
    if (window.FB) {
        window.FB.init({
            appId: facebookAppId,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v16.0',
        })
        resolve()
    } else {
        // If it's not been loaded wait a bit and try again
        setTimeout(() => init(resolve), 100)
    }
}

export function initFacebookSdk(): Promise<void> {
    return new Promise((resolve, reject) => {
        if (window.FB) {
            init(resolve)
            return
        }

        window.fbAsyncInit = function () {
            init(resolve)
        }

        const d = document
        const s = 'script'
        const id = 'facebook-jssdk'
        const fjs = d.getElementsByTagName(s)[0]

        if (d.getElementById(id)) {
            window.fbAsyncInit()
            return
        }

        const js = d.createElement(s) as HTMLScriptElement
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        js.onload = () => {
            if (window.FB) {
                window.fbAsyncInit()
            } else {
                reject(new Error('Failed to load Facebook SDK'))
            }
        }
        js.onerror = () => reject(new Error('Failed to load Facebook SDK'))
        fjs.parentNode?.insertBefore(js, fjs)
    })
}
