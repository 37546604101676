import { QuestionMarkCircleIcon, PhoneIcon } from '@heroicons/react/24/outline'
import SupportEmailText from '../components/help/SupportEmailText'

function SupportPage() {
    const iconClassNames = 'h-6 w-6 text-gray-500 mr-2 mt-1'
    return (
        <div className="w-full h-full bg-white pt-8 pl-8 flex flex-col items-center">
            <div className="m-10 prose prose-stone prose-lg ">
                <h1 className="text-gray-950 not-prose">
                    We're here to help. Here's how to get in touch.
                </h1>
                <div className="mt-6 flex flex-row ">
                    <div>
                        <QuestionMarkCircleIcon className={iconClassNames} />
                    </div>
                    <div>
                        <strong>Help Centre</strong>: the fastest way to get
                        answers to your questions is through our{' '}
                        <a
                            href="https://knowledge.arwen.ai/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Help Centre
                        </a>
                        . You can find our FAQs there.
                    </div>
                </div>
                <div className="mt-6">
                    <SupportEmailText />
                </div>
                <div className="mt-6 flex flex-row ">
                    <PhoneIcon className={iconClassNames} />
                    <div>
                        <strong>Telephone</strong>: +44 (0) 203 918 8550
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportPage
