import {
    ClassifierDetails,
    ContentClassificationView,
    ContentOutcomeView,
    ModerationBoundary,
} from '../api/client'
import { Queue, SeverityCategory } from '../models/Moderation'

const ENGAGE_CLASSIFICATION_NUMERICAL_THRESHOLD = 0.5
const SAFE_CLASSIFICATION_NUMERICAL_THRESHOLD = 0.2
const SAFE_CLASSIFICATION_CATEGORICAL_THRESHOLD = SeverityCategory.LOW
const EXCLUSION_LIST = ['neutral']
const SENTIMENT_NAME = 'sentiment'

/**
 * Convert the categorical severity to a percentage.
 *
 * This is useful for comparing severities to see if one is greater
 * than another and so used for determining if a severity meets a boundary.
 *
 * It is also useful for generating the radial progress display.
 *
 * So PLEASE CONSIDER the radial progress display if you decide to change
 * these values
 *
 * */
export function convertSeverityToPercentage(severity: string): number {
    switch (severity) {
        case SeverityCategory.NONE:
            return 0
        case SeverityCategory.NOTDETECTED:
            return 0
        case SeverityCategory.LOW:
            return 0.25
        case SeverityCategory.MEDIUM:
            return 0.5
        case SeverityCategory.HIGH:
            return 0.75
        case SeverityCategory.EXTREME:
            return 1
        default:
            return 0
    }
}

export type ClassifiersToDisplay = {
    relevantSevereClassifications: ContentClassificationView[]
    relevantSuspectClassifications: ContentClassificationView[]
    safeClassifications: ContentClassificationView[]
    engageClassifications: ContentClassificationView[]
}

export function getClassifiersToDisplay(
    content: ContentOutcomeView,
    configuredBoundaries: ModerationBoundary[],
    classifiersMap: Map<string, ClassifierDetails>,
    isEngagePage: boolean,
    searchParameters: any,
    engageClassifiersList: string[]
): ClassifiersToDisplay {
    const classifiersToDisplay: ClassifiersToDisplay = {
        relevantSevereClassifications: [],
        relevantSuspectClassifications: [],
        safeClassifications: [],
        engageClassifications: [],
    }

    /**
     * Identifies the relevant classifications for a queue.
     */
    function relevantClassificationsForQueue(
        queue: Queue,
        classifications: ContentClassificationView[],
        configuredBoundaries: ModerationBoundary[],
        classifiersMap: Map<string, ClassifierDetails>
    ): ContentClassificationView[] {
        // First identify all the boundaries that are relevant to this queue
        // and put them in a map for speedier lookup
        const configuredBoundariesMap = new Map<number, ModerationBoundary>(
            configuredBoundaries
                .filter((boundary) => boundary.queue === queue)
                .map((boundary) => {
                    return [boundary.classifier.id || 0, boundary]
                })
        )

        return classifications.filter((classification) => {
            // First find the classifier that matches the classification. We do this by name
            const matchingClassifier = classifiersMap.get(
                classification.classifierName.toLowerCase()
            )

            // Is there a boundary configured against this classifier?
            const matchingBoundary = configuredBoundariesMap.get(
                matchingClassifier?.id || 0
            )

            // Check the numerical probability
            if (classification.classification && matchingBoundary) {
                if (classification.classifierName === SENTIMENT_NAME) {
                    if (
                        matchingBoundary?.probability &&
                        classification.classification <=
                            matchingBoundary.probability
                    ) {
                        return true
                    }
                } else {
                    // Lets see if the classification of this item is greater than the boundary
                    if (
                        matchingBoundary?.probability &&
                        classification.classification >=
                            matchingBoundary.probability
                    ) {
                        return true
                    }
                }
            }

            // Check against the categorical classification
            if (matchingBoundary?.severityCategory && classification.severity) {
                if (
                    convertSeverityToPercentage(classification.severity) >=
                    convertSeverityToPercentage(
                        matchingBoundary.severityCategory
                    )
                ) {
                    return true
                }
            }

            // No matching bondary found.
            return false
        })
    }

    // Get all relevant severe classifications
    classifiersToDisplay.relevantSevereClassifications =
        relevantClassificationsForQueue(
            'SEVERE',
            content.allClassifications,
            configuredBoundaries,
            classifiersMap
        )

    // Let's continue to find the suspect ones...
    // BUT... first we need to remove the severe ones from the list of all classifications
    const nonSevereClassifications = content.allClassifications.filter(
        (classification) => {
            return !!!classifiersToDisplay.relevantSevereClassifications.find(
                (severeClassification) =>
                    classification.classifierName ===
                    severeClassification.classifierName
            )
        }
    )
    classifiersToDisplay.relevantSuspectClassifications =
        relevantClassificationsForQueue(
            'SUSPECT',
            nonSevereClassifications,
            configuredBoundaries,
            classifiersMap
        )

    // Find the rest of the classifications
    classifiersToDisplay.safeClassifications = content.allClassifications
        .filter((classification) => {
            return (
                !!!classifiersToDisplay.relevantSevereClassifications.find(
                    (severeClassification) =>
                        classification.classifierName ===
                        severeClassification.classifierName
                ) &&
                !!!classifiersToDisplay.relevantSuspectClassifications.find(
                    (suspectClassification) =>
                        classification.classifierName ===
                        suspectClassification.classifierName
                ) &&
                // Filter out the engage classifiers from the safe ones - engage classifiers are displayed differently.
                !engageClassifiersList.includes(classification.classifierName)
            )
        })
        // And ignore the ones that are really low significant
        .filter((classification) => {
            return (
                (classification.classification &&
                    classification.classification >
                        SAFE_CLASSIFICATION_NUMERICAL_THRESHOLD) ||
                (classification.severity &&
                    convertSeverityToPercentage(classification.severity) >=
                        convertSeverityToPercentage(
                            SAFE_CLASSIFICATION_CATEGORICAL_THRESHOLD
                        ))
            )
        })

    /**
     * Three scenario:
     * 1. On the engage page, one or more emotion and topics selected => show the score for those classifiers
     * 2. On the engage page, no emotion or topics selected => show the score for all classifiers > a threshold, e.g. 0.6
     * 3. On the normal content page, do not show any engage classifiers (they need to pay for it)
     **/

    // Lets get all the Engage classifiers - emotions only - different customers will have different topics:
    if (isEngagePage) {
        const hasSelectedEmotions =
            searchParameters?.selectedEmotions &&
            searchParameters?.selectedEmotions.length > 0
        const hasSelectedTopics =
            searchParameters?.selectedTopics &&
            searchParameters?.selectedTopics.length > 0

        classifiersToDisplay.engageClassifications =
            content.allClassifications.filter((classification) => {
                const isEngageClassifier = engageClassifiersList.includes(
                    classification.classifierName
                )

                // Some classifiers are uninteresting, e.g. Neutral, so we exclude them
                const isNotExcluded = !EXCLUSION_LIST.includes(
                    classification.classifierName
                )

                // Low threshold classifiers are not interesting...
                const doesMeetThreshold =
                    classification?.classification &&
                    classification?.classification >
                        ENGAGE_CLASSIFICATION_NUMERICAL_THRESHOLD

                if (hasSelectedEmotions || hasSelectedTopics) {
                    // ... unless the user has specifically requested them.
                    const isSelectionEmotionClassifier =
                        searchParameters.selectedEmotions.includes(
                            classification.classifierName
                        )

                    const isSelectionTopicClassifier =
                        searchParameters.selectedTopics.includes(
                            classification.classifierName
                        )

                    return (
                        isEngageClassifier &&
                        (isSelectionEmotionClassifier ||
                            isSelectionTopicClassifier)
                    )
                }

                return isEngageClassifier && doesMeetThreshold && isNotExcluded
            })
    }
    return classifiersToDisplay
}
