import {
    AccountRecord,
    SocialMediaServiceEnum,
    useGetAuthorisationUrlQuery,
} from '../../../api/client'
import SimpleLoader from '../../SimpleLoader'

type Props = {
    account: AccountRecord
}

/**
 * Starts the OAuth2 authorisation grant flow for YouTube.
 *
 * This is the simplest type of flow button: we just redirect the user to
 * a URL with our own button.
 */
function YouTubeAuthorisationButton(props: Props) {
    const { account } = props
    const { data, loading } = useGetAuthorisationUrlQuery({
        variables: {
            socialMediaService: SocialMediaServiceEnum.Youtube,
            accountId: account.id,
            baseUrl: `${window.location.protocol}//${window.location.host}/`,
        },
    })

    let authorisationUrl: string = data?.getAuthorisationUrl || ''
    return (
        <>
            {loading ? (
                <SimpleLoader loading={loading} />
            ) : (
                <a
                    href={authorisationUrl}
                    className={`text-primary-600 p-2 underline `}
                >
                    Authorise at YouTube.com
                </a>
            )}
        </>
    )
}

export default YouTubeAuthorisationButton
