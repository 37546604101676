import { useTitle } from '../components/TitleProvider'

interface TextPageHeaderProps {
    title: string
    tabTitle?: string
}

/**
 * Formats a page header, and updates the browser tab text.
 *
 * Title will set both the page header and the browser tab text.
 * If you want different text in the tab, you can set tabTitle.
 */
export function TextPageHeader({ title, tabTitle }: TextPageHeaderProps) {
    useTitle(tabTitle || title)
    return <h1 className="text-gray-950 not-prose pt-5 text-left">{title}</h1>
}
