import { AccountRecord } from '../../../api/client'
import FacebookAuthorisationButton from './FacebookAuthorisationButton'

type Props = {
    account: AccountRecord
}

/**
 * Shows the authorisation status of a Facebook Page account.
 *
 * This type of profile is added via OAuth, so it will always have an authorisation
 * row, whether or not it's valid. If it has no authorisation then someone
 * has _probably_ been messing with the database, and we should advise the
 * user to contact support.
 */
function FacebookAuthorisation(props: Props) {
    const { account } = props

    let alreadyAuthorised = account.hasAuthorisation
    return (
        <>
            {alreadyAuthorised ? (
                <>
                    <p>This profile is authorised.</p>
                    <div className="mt-4">
                        <p>
                            If there is an issue with authorisation you may need
                            to reauthoise by clicking the button below. Problems
                            with authorisation may prevent Arwen from taking
                            action on content. For example if you get an error
                            when clicking Hide, or none of the severe content
                            queue is hidden.
                        </p>
                        <div className="mt-4">
                            <FacebookAuthorisationButton />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        This profile has no authorisation! Please contact
                        Customer Support by emailing{' '}
                        <a
                            href="mailto:support@arwen.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="text-red-700 underline font-bold"
                        >
                            support@arwen.ai
                        </a>
                    </p>
                    <div className="mt-4">
                        <FacebookAuthorisationButton />
                    </div>
                </>
            )}
        </>
    )
}

export default FacebookAuthorisation
