/**
 * This module contains the HTTP functions for interacting with the unauth API, which is RESTFUL.
 *
 * You may use these functions with TanStack Query.
 */
import { Referrer } from '../../models/Referrer'
import config from '../../config'

export interface ReferrerData {
    referrer?: Referrer
    hasReachedUserLimit?: boolean
}

export function fetchReferrerData(referrerUuid: string): Promise<ReferrerData> {
    return fetch(
        `${config.unauthApi.url}/referrer_signup_enabled?referrerUuid=${referrerUuid}`
    ).then((response) => response.json())
}
