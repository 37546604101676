import { FilterFn } from '@tanstack/react-table'

import CommenterTable from '../../components/commenter/CommenterTable'

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
}

function UserListPage() {
    return (
        <>
            <div className="m-10 prose prose-stone">
                <h2 className="text-gray-950 initial">Commenter search page</h2>
                <p>
                    This page lets you search for social media users who have
                    commented on posts
                </p>
                <p>
                    <strong>Instagram users only</strong>
                </p>

                <CommenterTable />
            </div>
        </>
    )
}

export default UserListPage
