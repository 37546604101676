function NoActiveSubscription() {
    return (
        <div className="bg-white  ">
            <div className="mt-32 prose prose-stone prose-lg max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
                <h2 className="text-3xl tracking-tight text-gray-950 ">
                    <span className="block">
                        Thank you for creating an Arwen account.
                    </span>
                </h2>
                <h3 className="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl mt-8">
                    <span className="block text-primary-600">
                        Please reply to your instructions email, to let us know,
                        and we will finalise your set up.
                    </span>
                </h3>
            </div>
        </div>
    )
}

export default NoActiveSubscription
