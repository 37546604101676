import { ContentActionBusiness, ContentActionView } from '../api/client'

/**
 * Ok so tech debt alert.
 * Some actions take place in Tenmoku in TS and some in the Rust API
 * They look different, specifically "Types of property '__typename' are incompatible."
 * So we need to map the TS to the Rust API one
 *
 * @param contentAction
 * @returns
 */
export function mapToContentActionView(
    contentAction: ContentActionBusiness
): ContentActionView {
    return {
        action: contentAction.action,
        actionOn: new Date(contentAction.actionOn),
        active: contentAction.active,
        id: contentAction.id || 0,
        queued: contentAction.queued,
        automated: contentAction.automated,
        contentId: contentAction.contentId,
    }
}
