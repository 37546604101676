import clsx from 'clsx'

export default function PromptTestResult({
    result,
}: {
    result: string[] | number | undefined
}) {
    return (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1">
            <div
                className={clsx(
                    'overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6',
                    'rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                    'ring-1 ring-inset ring-gray-300 '
                )}
            >
                <dt className="truncate text-sm font-medium text-gray-500">
                    Result
                </dt>
                <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                    {Array.isArray(result) ? (
                        <ul>
                            {result.map((resultItem, id) => (
                                <li key={id}>{resultItem}</li>
                            ))}
                        </ul>
                    ) : (
                        result
                    )}
                </dd>
            </div>
        </dl>
    )
}
