import SupportEmailText from '../components/help/SupportEmailText'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

/**
 * This page is used to add a Facebook account to Arwen.
 */
function InstagramProfileConfigurationHelpPage() {
    return (
        <TextPageContainer>
            <TextPageHeader title="How to configure your Instagram profile so you can connect it to Arwen" />
            <div className="w-full flex justify-center">
                <iframe
                    className="w-full h-64 sm:h-96 lg:h-112 xl:h-128 border-0"
                    src="https://www.youtube.com/embed/WWWsI_uOpZg?si=y2SLMactDMAkAgxB"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="mt-6">
                <SupportEmailText />
            </div>
        </TextPageContainer>
    )
}

export default InstagramProfileConfigurationHelpPage
