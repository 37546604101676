import { useRecoilState } from 'recoil'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
    ProfileView,
} from '../../api/client'
import {
    canUserLikeContentState,
    canUserReplyToContentState,
} from '../../store/DomainStore'
import { getActions } from '../content/content-card/actions'
import BlockButton from './buttons/BlockButton'
import DeleteButton from './buttons/DeleteButton'
import HideButton from './buttons/HideButton'
import LikeButton from './buttons/LikeButton'
import MuteButton from './buttons/MuteButton'
import ReplyButton from './buttons/ReplyButton'
import SpamButton from './buttons/SpamButton'

type Props = {
    content: ContentOutcomeView
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    profileCache: ProfileView[]
    actionCacheMap: Map<number, ContentActionView[]>
}

/**
 * This component is responsible for rendering the action buttons for a piece of content.
 */
function ActionRow(props: Props) {
    const { content, onActionClick, profileCache, actionCacheMap } = props
    const [canUserLikeContent] = useRecoilState(canUserLikeContentState)
    const [canUserReplyToContent] = useRecoilState(canUserReplyToContentState)

    // Identify the relevant actions from the action cache map
    const allExistingContentActions = actionCacheMap.get(content.id) || []

    // FIXME: move to the delete button component
    const deleted =
        allExistingContentActions.findIndex(
            (contentActionView) =>
                contentActionView.action.toLowerCase() ===
                    ContentAction.Delete.toLowerCase() &&
                contentActionView.active
        ) > -1

    // These are the available actions for this item of content.
    const actions = getActions(
        content.socialMediaServiceName.toLowerCase(),
        deleted,
        content.isPublic,
        content.authorised
    ).filter((action) => {
        // Enforce the permissions here by filtering out disallowed actions
        if (action === ContentAction.Like && !canUserLikeContent) {
            return false
        }
        if (action === ContentAction.Reply && !canUserReplyToContent) {
            return false
        }

        // All other actions are allowed
        return true
    })

    return (
        <div className="flex gap-1">
            {/* Engage actions */}
            {actions.includes(ContentAction.Like) && (
                <LikeButton
                    contents={[content]}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                />
            )}
            {actions.includes(ContentAction.Reply) && (
                <ReplyButton
                    content={content}
                    onActionClick={onActionClick}
                    profileCache={profileCache}
                    actionCacheMap={actionCacheMap}
                />
            )}

            {/* Dividing line separating engage and moderate actions */}
            {(actions.includes(ContentAction.Reply) ||
                actions.includes(ContentAction.Like)) && (
                <div className="mx-2 border-l-2"></div>
            )}

            {/* Moderate actions */}

            {actions.includes(ContentAction.Hide) && (
                <HideButton
                    content={content}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                    actionCacheMap={actionCacheMap}
                />
            )}
            {actions.includes(ContentAction.Delete) && (
                <DeleteButton
                    content={content}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                />
            )}

            {actions.includes(ContentAction.Block) && (
                <BlockButton
                    content={content}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                />
            )}
            {actions.includes(ContentAction.Mute) && (
                <MuteButton
                    content={content}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                />
            )}

            {actions.includes(ContentAction.ConfirmSpam) && (
                <SpamButton
                    content={content}
                    onActionClick={onActionClick}
                    actions={allExistingContentActions}
                />
            )}
        </div>
    )
}

export default ActionRow
