import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
    AccountRecord,
    SocialMediaServiceEnum,
    useFetchHistoricYouTubeVideosMutation,
    useGetAccountQuery,
    useGetYouTubeSubscriptionDiagnosticUrlLazyQuery,
} from '../api/client'
import Button from '../components/Button'
import DeleteAccountModal from '../components/DeleteAccountModal'
import { toastError, toastSuccess } from '../components/Notification'
import AccountTeamSelector from '../components/account/AccountTeamSelector'
import FetchHistoricMeta from '../components/account/FetchHistoricMeta'
import AuthoriseButton from '../components/authorise/AuthoriseButton'
import AutoDeleteSevereContentToggle from '../components/toggles/AutoDeleteSevereContentToggle'
import AutoFetchContentToggle from '../components/toggles/AutoFetchContentToggle'
import AutoHideSevereContentToggle from '../components/toggles/AutoHideSevereContentToggle'
import AutoMuteSevereAccountToggle from '../components/toggles/AutoMuteSevereAccountToggle'
import LlmClassificationToggle from '../components/toggles/LlmClassificationToggle'
import ProfileCollectionToggle from '../components/toggles/ProfileCollectionToggle'
import ProfileModerationToggle from '../components/toggles/ProfileModerationToggle'
import ScrapingToggle from '../components/toggles/ScrapingToggle'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import { isPlatformAppReviewerState } from '../store/DomainStore'
import { getUserPermissions } from '../util/auth'
import LlmReplyToggle from '../components/toggles/LlmReplyToggle'
import DuplicateModerationConfiguration from '../components/moderation/DuplicateModerationConfiguration'
import IntelligenceEnabledToggle from '../components/toggles/IntelligenceEnabledToggle'
import FetchPostsToggle from '../components/toggles/FetchPostsToggle'
import FetchAdsToggle from '../components/toggles/FetchAdsToggle'
import AutoHideAdCommentsToggle from '../components/toggles/AutoHideAdCommentsToggle'
import PromptTable from '../components/prompt/PromptTable'
import ShadowBanningSettings from '../components/account/settings/ShadowBanningSettings'

type ParamTypes = {
    accountId: string
}

/**
 * Checks whether this account is a meta account (i.e. Instagram or Facebook)
 */
export function isMeta(account: AccountRecord) {
    const socialMediaService =
        account.socialMediaServiceShortName?.toLowerCase()
    return (
        socialMediaService === SocialMediaServiceEnum.Instagram.toLowerCase() ||
        socialMediaService === SocialMediaServiceEnum.Facebook.toLowerCase()
    )
}

/**
 * This page holds all the settings for a given account.
 */
function AccountSettingsPage() {
    const location = useLocation()
    const { accountId } = useParams<ParamTypes>()
    const [admin, setAdmin] = useState(false)
    const [isPlatformAppReviewer] = useRecoilState(isPlatformAppReviewerState)
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
        useState(false)

    const [fetchHistoricYouTubeVideos] = useFetchHistoricYouTubeVideosMutation()
    const [getYouTubeSubscriptionDiagnosticUrl, { data: subscriptionData }] =
        useGetYouTubeSubscriptionDiagnosticUrlLazyQuery()

    useEffect(() => {
        // Check if there's a hash in the URL (e.g., #section-name)
        if (location.hash) {
            const element = document.getElementById(
                location.hash.replace('#', '')
            )
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [location])

    const { data: accountData, refetch } = useGetAccountQuery({
        variables: { accountId: parseInt(accountId as string) },
    })

    const account = accountData?.getAccount

    let showMetaFetch = false
    if (account) {
        showMetaFetch = isMeta(account) ? isPlatformAppReviewer || admin : false
    }

    useEffect(() => {
        if (account) {
            getYouTubeSubscriptionDiagnosticUrl({
                variables: { channelId: account.serviceAccountId },
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        async function determineIfAdmin() {
            const userPermissions = await getUserPermissions()

            setAdmin(userPermissions.isAdmin)
        }
        determineIfAdmin()
    }, [])

    let diagnosticUrl = useRef('')
    useEffect(() => {
        const url = subscriptionData?.getYouTubeSubscriptionDiagnosticUrl?.url
        if (url) {
            diagnosticUrl.current = url
        }
    }, [subscriptionData])

    const handleDeleteClick = () => {
        setDeleteAccountModalVisible(true)
    }

    const handleTeamChange = () => {
        refetch()
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="Profile settings" />
            <Link to={`/account/${accountId}`}>Back to profile</Link>
            {account && (
                <>
                    <div className="flex flex-col mb-32">
                        <h2>Team</h2>
                        <AccountTeamSelector
                            account={account}
                            onTeamChange={handleTeamChange}
                        />
                        <h2>Profile validation</h2>
                        <div className="">
                            <p className="mt-0">
                                The following link lets you check the
                                authorisation and subscription status and repair
                                subscriptions if necessary
                            </p>
                            <Link
                                to={`/account/${accountId}/settings/validation`}
                            >
                                Validation page
                            </Link>
                        </div>
                        <h2>Moderation</h2>
                        <div className="max-w-60 w-60">
                            <AutoMuteSevereAccountToggle account={account} />
                            <AutoHideSevereContentToggle account={account} />
                            <AutoDeleteSevereContentToggle account={account} />
                            <AutoHideAdCommentsToggle account={account} />
                        </div>
                        {!account.hasAuthorisation && (
                            <>
                                <h2>Authorisation</h2>
                                <AuthoriseButton
                                    account={account}
                                    onlyWhenUnauthorised={true}
                                />
                            </>
                        )}

                        <h3>Keywords and Boundaries</h3>
                        <Link
                            to={`/account/${accountId}/moderation-boundaries`}
                            className="ml-3"
                        >
                            Edit moderation boundaries...
                        </Link>
                        <Link
                            to={`/account/${accountId}/keywords`}
                            className="ml-3"
                        >
                            Edit keywords...
                        </Link>

                        <DuplicateModerationConfiguration account={account} />
                        <h3>Shadow-banning</h3>
                        <div className="ml-3">
                            <ShadowBanningSettings account={account} />
                            <Link
                                to={`/account/${accountId}/settings/shadow-banning`}
                            >
                                Edit shadow-banning settings...
                            </Link>
                        </div>

                        <h2>Engage</h2>
                        <div className="max-w-60 w-60">
                            <IntelligenceEnabledToggle account={account} />
                        </div>
                        <p className="ml-3 mt-1 text-gray-600 ">
                            This will enable emotion classification for all
                            content in this profile. Historic content will not
                            get classified. Classification currently takes place
                            every 30 minutes. This does not enable topic
                            classification, e.g. events, food, service. To this
                            please raise a ticket with 2nd line support.
                        </p>

                        <h2 id="llm-settings">LLM Settings</h2>
                        <h3>Reply</h3>
                        <div className="max-w-96 w-96">
                            <LlmReplyToggle account={account} />
                        </div>
                        <Link
                            to={`/account/${accountId}/settings/llm-reply`}
                            className="ml-3"
                        >
                            Edit LLM reply settings...
                        </Link>
                        <h3>Classification</h3>
                        <div className="max-w-96 w-96">
                            <LlmClassificationToggle account={account} />
                        </div>
                        <Link
                            to={`/account/${accountId}/settings/prompt/create`}
                            className="ml-3"
                        >
                            Create a new prompt
                        </Link>
                        <PromptTable accountId={account.id} />

                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Tikapi.toLowerCase() && (
                            <>
                                <h2>TikApi</h2>
                                <div className="max-w-96 w-96">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Twitter.toLowerCase() && (
                            <>
                                <h2>X</h2>
                                <div className="max-w-96 w-96">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                    <ScrapingToggle
                                        account={account}
                                        size="large"
                                    />
                                    <Link
                                        to={`/account/${accountId}/fetch-historic`}
                                        className="ml-3"
                                    >
                                        Fetch historic X content...
                                    </Link>
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Instagram.toLowerCase() && (
                            <>
                                <h2>Instagram settings</h2>
                                <div className="w-4/5">
                                    <ProfileCollectionToggle
                                        account={account}
                                    />
                                    <ProfileModerationToggle
                                        account={account}
                                    />
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Youtube.toLowerCase() && (
                            <>
                                <h2>YouTube settings</h2>
                                <div className="w-4/5">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                </div>
                                <Link
                                    key={account.id}
                                    to={`/account/${accountId}/authorisation/revoke`}
                                    className="ml-3"
                                >
                                    Revoke authorisation...
                                </Link>
                                <a
                                    href={diagnosticUrl.current}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ml-3"
                                >
                                    <span className="flex">
                                        YouTube subscription diagnostics page...
                                        <ArrowTopRightOnSquareIcon className="w-4" />
                                    </span>
                                </a>
                                <div className="flex flex-col gap-y-3 ml-3 mt-3">
                                    {/* I've removed the following buttons as they are now obsolete and haven't been used in years
                                    - Add or renew YouTube post subscription
                                    - Refresh access token
                                     */}
                                    <div>
                                        <span className="text-gray-600 text-sm mb-1">
                                            We only fetch comments on videos we
                                            know about. We get sent videos via
                                            our webhook, but that means we only
                                            have videos that were created since
                                            the profile was added to Arwen. Our
                                            customers frequently want us to
                                            collect comments on existing videos.
                                            This function uses the API to fetch
                                            older videos, so we can fetch older
                                            comments, and indeed new comments on
                                            older videos. This action fetches 5
                                            years of posts and could take up to
                                            45 minutes.
                                        </span>

                                        <div className="w-80">
                                            <Button
                                                primary={false}
                                                text="Fetch historic YouTube videos"
                                                onClick={() =>
                                                    fetchHistoricYouTubeVideos({
                                                        variables: {
                                                            accountId:
                                                                account.id,
                                                        },
                                                        onCompleted: () => {
                                                            toastSuccess(
                                                                'Submitted fetch request for historic YouTube videos.'
                                                            )
                                                        },
                                                        onError: (error) => {
                                                            console.error(error)
                                                            toastError(
                                                                'Unable to request historic YouTube videos! See console for errors.'
                                                            )
                                                        },
                                                    })
                                                }
                                            ></Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {showMetaFetch && (
                            <>
                                <h2>Meta</h2>
                                <div className="w-80">
                                    <FetchPostsToggle account={account} />
                                </div>
                                <p className="ml-3 mt-1 text-gray-600 ">
                                    Arwen will, while this is activated, fetch
                                    all active ads for this profile and store
                                    them as Posts. This means we will be able to
                                    detect what comments are associated with
                                    ads, and mark them as being “ad comments”.
                                    This only applies to Meta.
                                </p>
                                <div className="w-80 ">
                                    <FetchAdsToggle account={account} />
                                </div>
                                <p className="ml-3 mt-1 text-gray-600 ">
                                    Arwen will, while this is activated, fetch
                                    all organic posts for this profile. We will
                                    know what organic posts are boosted (and
                                    hence ads) and which aren’t. This means we
                                    will be able to detect what comments are
                                    associated with ads, and mark them as being
                                    “ad comments”. This only applies to Meta.
                                </p>
                                <div className="w-80 ml-3">
                                    <FetchHistoricMeta account={account} />
                                </div>
                                <p className="ml-3 mt-1 text-gray-600 ">
                                    This will fetch 24 hours of historic content
                                    for Meta profiles
                                </p>
                            </>
                        )}
                        <h2>Delete this profile</h2>

                        {deleteAccountModalVisible ? (
                            <DeleteAccountModal
                                setVisible={setDeleteAccountModalVisible}
                                accountId={account.id}
                            />
                        ) : null}
                        <div className="w-80 ml-3">
                            <Button
                                primary={false}
                                onClick={handleDeleteClick}
                                text="Delete profile forever"
                            />
                        </div>
                        <p className="ml-3 mt-1 text-gray-600">
                            Deleting a profile can take some time, e.g. 15-30
                            minnutes. Once deleted this profile will disappear
                            from the profile list but will remain in a hidden
                            state until all its content and classifications have
                            been deleted. During this time of you try to add the
                            profile again you will get an error
                        </p>
                    </div>
                </>
            )}
        </TextPageContainer>
    )
}

export default AccountSettingsPage
