import { AuthorisedAccountView } from '../../api/client'
import { faIconName } from '../../util/classNames'
import DashboardButton from './DashboardButton'

/**
 * Displays a small row of information about an account:
 *   Icon - Account Name - Dashboard Button
 */
export default function AccountSummary(account: AuthorisedAccountView) {
    return (
        <span className="flex items-center ">
            <div className="mr-2" title={account.socialMediaServiceName}>
                <i
                    className={`fab ${faIconName(
                        account.socialMediaServiceName,
                        true
                    )} text-complementary-500`}
                ></i>
            </div>
            <span className="mr-2">{account.accountName}</span>
            <DashboardButton accountId={account.id} />
        </span>
    )
}
