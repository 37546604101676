import { useGetProfileQuery } from '../../api/client'
import Spinner from '../Spinner'
import { CopyToClipboardWrapper } from '../CopyToClipboard'

type Props = {
    contentId: number
}

/**
 * Displays the Instagram Profile ID.
 *
 * Getting the profile might include a scrape, so it takes a while and this component warns
 * of that.
 *
 * More details:
 * The ID in `content.serviceAuthorId` is, for Meta, an App-Specific ID (ASID). It's unique
 * for that user and our app, but it doesn't identify the user outside that. Our customers
 * need IDs for users so they can report them. The ID in `content.serviceAuthorId` isn't that.
 * They ID they want is the "raw ID". That's not available in any of the APIs we use, so we
 * need to scrape it. The detail for all that is in the backend.
 */
export default function InstagramProfileId({ contentId }: Props) {
    const { data, error, loading } = useGetProfileQuery({
        variables: {
            contentId,
        },
    })

    const profile = data?.getProfile

    if (loading) {
        return (
            <div>
                <Spinner size={4} />
                {'  '}
                <span>
                    Fetching profile ID (takes time but only happens once)...
                </span>
            </div>
        )
    }

    if (error) {
        return <div>Error fetching profile ID</div>
    }

    if (profile) {
        return (
            <CopyToClipboardWrapper>
                {profile.serviceProfileId}
            </CopyToClipboardWrapper>
        )
    }
}
