import { DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { SyncLoader } from 'react-spinners'
import {
    AccountRecord,
    useCopyKeywordsToAccountMutation,
    useCopyModerationBoundariesMutation,
    useGetAccountsInTeamQuery,
} from '../../api/client'
import Button from '../Button'
import { toastError } from '../Notification'
import { useState } from 'react'
import clsx from 'clsx'

type Prop = {
    account: AccountRecord
    teamId: number
    setCopyModalOpen: (value: boolean) => void
}

export default function DuplicateModerationConfiguration(props: Prop) {
    const { account, teamId, setCopyModalOpen } = props
    // We will be copying to multiple accounts using multiple requests to the server.
    // We need to keep track of the status of copying and the current account we are copying to.
    const [copying, setCopying] = useState(false)
    const [copied, setCopied] = useState(false)
    const [currentlyCopyingTeam, setCurrentlyCopyingTeam] = useState<string>()

    // Fetch all the accounts in the target team...
    const { data: getAccountsInTeamData, loading: getAccountsInTeamLoading } =
        useGetAccountsInTeamQuery({
            variables: {
                params: { teamId: teamId },
            },
            onError: (error) => {
                toastError(error.message)
            },
        })

    const [copyModerationBoundaries] = useCopyModerationBoundariesMutation({
        onError: (error) => {
            toastError(error.message)
        },
    })

    const [copyKeywordsToAccount] = useCopyKeywordsToAccountMutation({
        onError: (error) => {
            toastError(error.message)
        },
    })

    // Copy the moderation boundaries and keywords to all the accounts in the team one by one.
    const handleCopyClick = async () => {
        setCopying(true)
        for (let targetAccount of accountsInTeam) {
            // If the team contains the source account then we want to skip it, as otherwise all the keywords will get blatted.
            if (account.id !== targetAccount.id) {
                setCurrentlyCopyingTeam(targetAccount.accountName || '')
                await copyModerationBoundaries({
                    variables: {
                        sourceAccountId: account.id,
                        targetAccountId: targetAccount.id,
                    },
                })

                await copyKeywordsToAccount({
                    variables: {
                        sourceAccountId: account.id,
                        targetAccountId: targetAccount.id,
                    },
                })
            } else {
                console.info('Skipping copying to the source account')
            }
        }
        setCopied(true)
        setCopying(false)
    }

    const accountsInTeam = getAccountsInTeamData?.getAccountsInTeam || []

    return (
        <div className="ml-3">
            <DialogPanel
                className={clsx(
                    'border-primary700 relative transform overflow-hidden rounded-lg',
                    'bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg',
                    'overflow-y-scroll max-h-[500px]'
                )}
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon
                                className="h-6 w-6 text-red-600"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-950"
                            >
                                Copy moderation boundaries and keywords?
                            </DialogTitle>
                            <div className="mt-2 space-y-4 ">
                                <p className="text-sm text-gray-500">
                                    Copying moderation boundaries and keywords
                                    will overwrite the existing settings for all
                                    the profiles in the selected team.
                                </p>
                                <p className="text-sm text-gray-500">
                                    This cannot be undone!
                                </p>
                                <p>
                                    This team contains the following profiles:
                                </p>

                                {getAccountsInTeamLoading && (
                                    <div className="mx-4">
                                        <SyncLoader
                                            loading={true}
                                            color="#CF3C28"
                                            size={'4px'}
                                        />
                                    </div>
                                )}

                                <ul>
                                    {accountsInTeam.map((account) => {
                                        return (
                                            <li key={account.id}>
                                                {account.accountName}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:px-6 space-x-6">
                    {!copied && (
                        <Button
                            primary={true}
                            disabled={copying}
                            onClick={handleCopyClick}
                            text="Copy"
                        />
                    )}
                    {copied && (
                        <div className="flex flex-row items-baseline">
                            <Button
                                primary={false}
                                onClick={() => setCopyModalOpen(false)}
                                text="Close"
                            />
                            <div className="ml-3">
                                Successfully copied moderation boundaries!
                            </div>
                        </div>
                    )}

                    {!copied && !copying && (
                        <Button
                            primary={false}
                            onClick={() => setCopyModalOpen(false)}
                            text="Cancel"
                        />
                    )}

                    {copying ? (
                        <div className="flex flex-row items-baseline">
                            <div className="mx-4">
                                <SyncLoader
                                    loading={true}
                                    color="#CF3C28"
                                    size={'4px'}
                                />
                            </div>
                            <div className="ml-3">{currentlyCopyingTeam}</div>
                        </div>
                    ) : null}
                </div>
            </DialogPanel>
        </div>
    )
}
