import { useState } from 'react'
import {
    AccountRecord,
    useUpdateAutoHideAdCommentsMutation,
} from '../../api/client'
import Toggle from './Toggle'
import React from 'react'

const AutoHideAdCommentsToggle = ({ account }: { account: AccountRecord }) => {
    const [updateAutoHideAdComments, { loading }] =
        useUpdateAutoHideAdCommentsMutation()

    const [enabled, setEnabled] = useState(account.autoHideAllAdComments)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateAutoHideAdComments({
            variables: {
                accountId: account.id,
                autoHideAdComments: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`auto-hide-ad-comments-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled ? enabled : false}
            label="Autohide ad comments"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enable or disable blanket hiding ad comments"
            size="large"
        />
    )
}

export default AutoHideAdCommentsToggle
