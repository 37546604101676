import { useState } from 'react'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    allEmotions: string[]
    selectedEmotions: string[]
    onUpdateSelectedEmotions: (emotions: Array<string>) => void
}

export default function EmotionFilter(props: Props) {
    const { allEmotions, selectedEmotions, onUpdateSelectedEmotions } = props
    const [searchTerm, setSearchTerm] = useState('')

    function handleUpdateSelectedEmotions(selectedItems: Array<FilterItem>) {
        onUpdateSelectedEmotions(selectedItems.map((item) => item.displayName))
    }

    return (
        <>
            <FilterInput
                filterTypeName="Emotions"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => {
                    setSearchTerm(term)
                }}
                onUpdateSelectedItems={handleUpdateSelectedEmotions}
                selectedItems={selectedEmotions.map(mapStringItemToFilterItem)}
                searchResults={allEmotions.map(mapStringItemToFilterItem)}
                searching={false}
            />
        </>
    )
}

function mapStringItemToFilterItem(emotion: string, index: number): FilterItem {
    return {
        id: index,
        displayName: emotion,
    }
}
