import TeamDetailsForm from '../../components/team/TeamDetailsForm'

/**
 * This page uses the react-hook-form to create a new team.
 * https://www.react-hook-form.com/get-started/
 */
function TeamCreatePage() {
    return <TeamDetailsForm />
}

export default TeamCreatePage
