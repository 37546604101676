import { AxisBottom, AxisLeft } from '@visx/axis'
import { Group } from '@visx/group'
import { HeatmapRect } from '@visx/heatmap'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Tooltip, defaultStyles } from '@visx/tooltip'
import React, { useMemo, useState } from 'react'
import { useGetHeatmapQuery } from '../../api/client'
import QueryFailed from '../content/QueryFailed'
import { SearchParameters } from '../content/search/ContentSearchBar'

export type HeatmapProps = {
    width: number
    height: number
    searchParameters: SearchParameters
    onSearchParameterChange: (searchParams: SearchParameters) => void
    emotions: string[]
    topics: string[]
}

const defaultMargin = { top: 5, left: 100, right: 20, bottom: 60 }
const tooltipStyle = {
    ...defaultStyles,
    backgroundColor: 'white',
    color: 'rgb(75 85 99)',
    padding: 12,
    rx: 6,
    fontSize: '15px',
}

export default function Heatmap(props: HeatmapProps) {
    const {
        width,
        height,
        searchParameters,
        onSearchParameterChange,
        emotions,
        topics,
    } = props

    const { data, loading, error } = useGetHeatmapQuery({
        variables: {
            params: {
                since: searchParameters.startDateTime,
                before: searchParameters.endDateTime,
                accountIds: searchParameters.selectedAccountIds,
                teamIds: searchParameters.selectedTeams,
                socialMediaServiceIds:
                    searchParameters.selectedSocialMediaServices.map(
                        (s) => s.id
                    ),
                containsText: searchParameters.keyword,
                moderationString: [searchParameters.moderation],
            },
        },
    })

    const margin = defaultMargin
    const separation = 0
    const events = true

    const binData = useMemo(() => {
        const heatmapData = data?.getHeatmap[0]
        return emotions.map((e) => {
            return {
                bin: e,
                bins: topics.map((t) => {
                    const key = (t.toString() +
                        e[0].toUpperCase() +
                        e.substring(1)) as keyof typeof heatmapData

                    return {
                        bin: t,
                        count: heatmapData?.[key] ? heatmapData?.[key] : 0,
                    }
                }),
            }
        })
    }, [emotions, topics, data])

    function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
        return Math.max(...data.map(value))
    }

    // The bins are the rows (in this case topics)
    const bins = (d: { bin: string; bins: { bin: string; count: number }[] }) =>
        d.bins
    const count = (d: { bin: string; count: number }) => d.count

    const colorMax = max(
        binData.filter((emotionBin) => emotionBin.bin !== 'neutral'),
        (d) => max(bins(d), count)
    )
    const bucketSizeMax = max(binData, (d) => bins(d).length)

    // scales
    const xScale = scaleLinear<number>({
        domain: [0, binData.length],
    })
    const yScale = scaleLinear<number>({
        domain: [0, bucketSizeMax],
    })

    const rectColorScale = scaleLinear<string>({
        range: ['#8ecae6', '#219ebc'],
        domain: [0, colorMax],
    })
    const opacityScale = scaleLinear<number>({
        range: [0.5, 1],
        domain: [0, colorMax],
    })

    const [activeColumn, setActiveColumn] = useState(-1)
    const [activeRow, setActiveRow] = useState(-1)

    const size =
        width > margin.left + margin.right
            ? width - margin.left - margin.right - separation
            : width
    const xMax = size
    const yMax = height - margin.bottom

    const binWidth = xMax / binData.length
    const binHeight = (yMax - margin.bottom) / bucketSizeMax

    xScale.range([0, xMax])
    yScale.range([margin.bottom, yMax])

    const topicScale = scaleBand({
        domain: topics,
        range: [margin.bottom, yMax],
    })

    const emotionScale = scaleBand({
        domain: emotions,
        range: [0, xMax],
    })

    const [tooltipOpen, setToolTipOpen] = useState(false)
    const [tooltipLeft, setToolTipLeft] = useState(0)
    const [tooltipTop, setToolTipTop] = useState(0)
    const [tooltipData, setToolTipData] = useState({
        topic: '',
        emotion: '',
        count: 0,
    })

    function getBinOpacity(bin: any): number {
        const topic: any = bin.bin

        // No cell is selected
        if (
            searchParameters.selectedTopics.length === 0 &&
            searchParameters.selectedEmotions.length === 0
        ) {
            if (bin.datum.bin === 'neutral') {
                return 0.25
            } else if (bin.count === 0) {
                return 0.1
            } else {
                return opacityScale(bin.count)
            }
        }

        // There is a selection so lets determine if it applies to this bin
        const topicHidden =
            searchParameters.selectedTopics.indexOf(topic.bin) > -1 ||
            searchParameters.selectedTopics.length === 0
                ? false
                : true
        const emotionHidden =
            searchParameters.selectedEmotions.indexOf(bin.datum.bin) > -1 ||
            searchParameters.selectedEmotions.length === 0
                ? false
                : true

        if (
            (emotionHidden && topicHidden) ||
            (emotionHidden && searchParameters.selectedTopics.length === 0) ||
            (topicHidden && searchParameters.selectedEmotions.length === 0)
        ) {
            // Cell is not selected, nor is the cell's topic or emotion
            if (bin.datum.bin === 'neutral') {
                return 0.1
            } else if (bin.count === 0) {
                return 0.05
            }
            return 0.1
        } else if (!emotionHidden && !topicHidden) {
            // Cell is selected (either as a single cell or entire row or column)
            if (bin.datum.bin === 'neutral') {
                return 0.5
            } else if (bin.count === 0) {
                return 0.4
            }
            return 1
        }

        // The cell is not selected but is either the same topic or emotion as the selected cell
        if (bin.count === 0) {
            return 0.2
        }
        if (bin.datum.bin === 'neutral') {
            return 0.25
        }
        return 0.35
    }

    const topMargin = -40

    const showData = !loading && data && !error
    const showError = !loading && error
    const showLoader = loading && !data && !error
    return width < 10 ? null : (
        <div>
            {showData ? (
                <svg
                    width={width}
                    height={height + topMargin}
                    style={{ textTransform: 'capitalize' }}
                >
                    <Group top={topMargin} left={margin.left}>
                        <HeatmapRect
                            data={binData}
                            xScale={(d) => xScale(d) ?? 0}
                            yScale={(d) => yScale(d) ?? 0}
                            colorScale={rectColorScale}
                            opacityScale={opacityScale}
                            binWidth={binWidth}
                            binHeight={binHeight}
                            gap={2}
                        >
                            {(heatmap) =>
                                heatmap.map((heatmapBins) =>
                                    heatmapBins.map((bin, index) => {
                                        let opacity = getBinOpacity(bin)

                                        let fill =
                                            bin.datum.bin === 'neutral' ||
                                            bin.count === 0
                                                ? '#666'
                                                : bin.color
                                        if (
                                            bin.count === 0 &&
                                            bin.datum.bin !== 'neutral'
                                        ) {
                                            fill = '#AED1E2'
                                        }

                                        return (
                                            <Group key={index}>
                                                <rect
                                                    key={`heatmap-rect-${bin.row}-${bin.column}`}
                                                    className="visx-heatmap-rect cursor-pointer"
                                                    width={bin.width}
                                                    height={bin.height}
                                                    x={bin.x}
                                                    y={bin.y}
                                                    rx={4}
                                                    fill={fill}
                                                    fillOpacity={opacity}
                                                    stroke={
                                                        activeColumn ===
                                                            bin.column &&
                                                        activeRow === bin.row
                                                            ? 'white'
                                                            : undefined
                                                    }
                                                    strokeWidth={
                                                        activeColumn ===
                                                            bin.column &&
                                                        activeRow === bin.row
                                                            ? 2
                                                            : undefined
                                                    }
                                                    onClick={() => {
                                                        if (!events) return

                                                        const topic: any =
                                                            bin.bin
                                                        const onlySelectedCell =
                                                            searchParameters
                                                                .selectedTopics
                                                                .length === 1 &&
                                                            searchParameters
                                                                .selectedTopics[0] ===
                                                                topic.bin &&
                                                            searchParameters
                                                                .selectedEmotions
                                                                .length === 1 &&
                                                            searchParameters
                                                                .selectedEmotions[0] ===
                                                                bin.datum.bin

                                                        onSearchParameterChange(
                                                            {
                                                                ...searchParameters,
                                                                selectedTopics:
                                                                    onlySelectedCell
                                                                        ? []
                                                                        : [
                                                                              topic.bin,
                                                                          ],
                                                                selectedEmotions:
                                                                    onlySelectedCell
                                                                        ? []
                                                                        : [
                                                                              bin
                                                                                  .datum
                                                                                  .bin,
                                                                          ],
                                                            }
                                                        )
                                                    }}
                                                    onMouseOver={(
                                                        event: React.MouseEvent<SVGRectElement>
                                                    ) => {
                                                        const topic: any =
                                                            bin.bin
                                                        setActiveColumn(
                                                            bin.column
                                                        )
                                                        setActiveRow(bin.row)
                                                        setToolTipOpen(true)
                                                        setToolTipLeft(
                                                            event.clientX + 4
                                                        )
                                                        setToolTipTop(
                                                            event.clientY + 4
                                                        )
                                                        setToolTipData({
                                                            emotion:
                                                                bin.datum.bin,
                                                            topic: topic.bin,
                                                            count: bin.count!,
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        setActiveColumn(-1)
                                                        setActiveRow(-1)
                                                        setToolTipOpen(false)
                                                    }}
                                                />

                                                <text
                                                    pointerEvents="none"
                                                    color="white"
                                                    x={bin.x + binWidth / 2}
                                                    y={bin.y + binHeight / 2}
                                                    textAnchor="middle"
                                                    alignmentBaseline="middle"
                                                >
                                                    {Math.round(bin.count!)}
                                                </text>
                                            </Group>
                                        )
                                    })
                                )
                            }
                        </HeatmapRect>
                        <AxisLeft
                            scale={topicScale}
                            tickValues={topics}
                            tickLabelProps={(label) => {
                                return {
                                    fontSize: '12px',
                                    dx: '-70px',
                                    dy: '5px',
                                    cursor: 'pointer',

                                    onClick: () => {
                                        searchParameters.selectedTopics
                                            .length === 1 &&
                                        searchParameters.selectedTopics[0] ===
                                            label
                                            ? onSearchParameterChange({
                                                  ...searchParameters,
                                                  selectedTopics: [],
                                                  selectedEmotions: [],
                                              })
                                            : onSearchParameterChange({
                                                  ...searchParameters,
                                                  selectedTopics: [label],
                                                  selectedEmotions: [],
                                              })
                                    },
                                }
                            }}
                            hideTicks
                            hideAxisLine
                        />
                        <AxisBottom
                            scale={emotionScale}
                            tickValues={emotions}
                            top={yMax}
                            tickLabelProps={(label) => {
                                return {
                                    dy:
                                        emotions.indexOf(label) % 2 === 0
                                            ? '0px'
                                            : '20px',
                                    dx: '0px',
                                    fontSize: '10px',
                                    alignmentBaseline: 'middle',
                                    textAnchor: 'middle',
                                    cursor: 'pointer',
                                    onClick: () => {
                                        searchParameters.selectedEmotions
                                            .length === 1 &&
                                        searchParameters.selectedEmotions[0] ===
                                            label
                                            ? onSearchParameterChange({
                                                  ...searchParameters,
                                                  selectedTopics: [],
                                                  selectedEmotions: [],
                                              })
                                            : onSearchParameterChange({
                                                  ...searchParameters,
                                                  selectedTopics: [],
                                                  selectedEmotions: [label],
                                              })
                                    },
                                }
                            }}
                            hideAxisLine
                        />
                    </Group>
                </svg>
            ) : showError ? (
                <div className="ml-9 mr-9">
                    <QueryFailed errorMessage={error?.message} />
                </div>
            ) : showLoader ? (
                <svg
                    className="animate-pulse bg-gray-300  rounded-md m-4 "
                    width={width - 20}
                    height={height - 20}
                    style={{ textTransform: 'capitalize' }}
                ></svg>
            ) : null}

            {tooltipOpen ? (
                <Tooltip
                    key={'vis-heatmap-tooltip'}
                    left={tooltipLeft}
                    top={tooltipTop}
                    style={tooltipStyle}
                >
                    <div>
                        <p>
                            <strong style={{ textTransform: 'capitalize' }}>
                                Emotion: {tooltipData.emotion}
                            </strong>
                        </p>
                        <p>
                            <strong style={{ textTransform: 'capitalize' }}>
                                Topic: {tooltipData.topic}
                            </strong>
                        </p>
                        Count: {tooltipData.count}
                    </div>
                </Tooltip>
            ) : null}
        </div>
    )
}
