import { useState } from 'react'
import {
    AccountRecord,
    useUpdateAutoFetchContentMutation,
} from '../../api/client'
import Toggle from './Toggle'

const AutoFetchContentApiToggle = ({
    account,
    size,
}: {
    account: AccountRecord
    size: 'small' | 'medium' | 'large'
}) => {
    const [updateAutoFetchContent, { loading }] =
        useUpdateAutoFetchContentMutation()

    const [autoFetchContentEnabled, setAutoFetchContentEnabled] = useState(
        account.fetchContent
    )

    const onToggle = async (isEnabled: boolean) => {
        setAutoFetchContentEnabled(isEnabled)

        await updateAutoFetchContent({
            variables: { accountId: account.id, autoFetchContent: isEnabled },
        })
    }

    return (
        <Toggle
            id={`auto-fetch-${account.id.toString()}`}
            disabled={false}
            checked={autoFetchContentEnabled}
            label="Fetch with API"
            loading={loading}
            onToggle={onToggle}
            tooltip="Start fetching content in the background from today"
            size={size}
        />
    )
}

export default AutoFetchContentApiToggle
