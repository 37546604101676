import { Referrer } from '../api/client'
import HeinekenWelcome from '../components/unauthenticated/HeinekenWelcome'
import TrialWelcome from '../components/unauthenticated/TrialWelcome'
import { REFERRER_DEFAULT, REFERRER_HEINEKEN } from '../util/referrers'
import Authenticate from '../components/authenticator/Authenticate'
import { signInWithRedirect } from 'aws-amplify/auth'
import CobrandedWelcome from '../components/unauthenticated/CobrandedWelcome'

type Props = {
    referrer?: Referrer
    hasReachedUserLimit?: boolean
}

export default function UnauthenticatedPage(props: Props) {
    const { referrer, hasReachedUserLimit } = props

    const generateTrialWelcome = () => {
        return (
            <TrialWelcome
                authenticator={
                    <Authenticate
                        hideSignUp={false}
                        initialState={
                            referrer?.allowTrial ? 'signUp' : 'signIn'
                        }
                        showHeader={true}
                    />
                }
            />
        )
    }

    const generateStandardLogin = () => {
        return (
            <div className="mt-32 flex-1 flex justify-center mb-12">
                {
                    <Authenticate
                        hideSignUp={false}
                        initialState={
                            referrer?.allowTrial ? 'signUp' : 'signIn'
                        }
                        showHeader={true}
                    />
                }
            </div>
        )
    }

    const generateStandardLoginWithoutSignup = () => {
        return (
            <div className="mt-32 flex-1 flex flex-col justify-center mb-12">
                <Authenticate
                    hideSignUp={true}
                    initialState={'signIn'}
                    showHeader={true}
                />
                <button
                    className="text-sm text-primary-600 underline"
                    onClick={() => {
                        signInWithRedirect()
                    }}
                >
                    Sign in with your corporate account
                </button>
            </div>
        )
    }

    const generateHeinekenWelcome = () => {
        return (
            <HeinekenWelcome
                authenticator={
                    <Authenticate
                        hideSignUp={false}
                        initialState={'signUp'}
                        showHeader={false}
                    />
                }
                allowTrial={!!referrer?.allowTrial}
                signUpEnabled={!!referrer?.signUpEnabled}
                hasReachedUserLimit={!!hasReachedUserLimit}
            />
        )
    }

    const generateCobrandedLogin = () => {
        const logoUrl = referrer?.logoUrl
        if (!logoUrl) {
            console.error(
                'Cobranded referrer must have a logo, but none was provided.'
            )
        } else {
            return (
                <CobrandedWelcome
                    referrer={referrer}
                    hasReachedUserLimit={!!hasReachedUserLimit}
                />
            )
        }
    }

    const determineWelcome = () => {
        if (!!!referrer) {
            // The referrer is not listed in the URL so generate the standard login with no signup
            return generateStandardLoginWithoutSignup()
        } else if (referrer.isCobranded) {
            return generateCobrandedLogin()
        } else if (referrer.referrer === REFERRER_HEINEKEN) {
            // Referrer is Heineken so generate the Heineken welcome page
            return generateHeinekenWelcome()
        } else if (referrer.referrer === REFERRER_DEFAULT) {
            // Referrer is the default so allow the user to create an account
            return generateStandardLogin()
        } else if (referrer.allowTrial) {
            // This is a different referrer, so just generate the trial welcome page
            return generateTrialWelcome()
        } else {
            // This is a different referrer, so just generate the standard login with no signup
            return generateStandardLoginWithoutSignup()
        }
    }

    return (
        <div className="main flex flex-col justify-items-stretch justify-center items-stretch lg:w-full lg:flex-row">
            {determineWelcome()}
        </div>
    )
}
