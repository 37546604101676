import { Referrer } from '../../api/client'
import { TextPageContainer } from '../../containers/TextPageContainer'
import ArwenLogo from '../../assets/brand/arwen-logo-red-medium.png'
import Authenticate from '../authenticator/Authenticate'

type Props = {
    referrer: Referrer
    hasReachedUserLimit: boolean
}

function CobrandedWelcome(props: Props) {
    const { referrer, hasReachedUserLimit } = props

    const generateAuthenticator = () => {
        // TODO: what copy do we want here?
        if (hasReachedUserLimit) {
            return (
                <div className="rounded-lg border-white  text-center backdrop-blur-xl lg:p-16 p-4">
                    <h1 className="text-white text-7xl">
                        Sorry - we’re full today!
                    </h1>
                    <p className="text-white text-3xl pt-4">
                        Come back tomorrow when more Arwen accounts will be
                        available.
                    </p>
                </div>
            )
        }

        return (
            <div className="border rounded-lg border-white">
                <Authenticate
                    hideSignUp={false}
                    initialState={'signUp'}
                    showHeader={false}
                    onboarding={true}
                />
            </div>
        )
    }

    let logoUrl
    if (!referrer?.logoUrl) {
        console.error(
            'Cobranded referrer must have a logo, but none was provided.'
        )
    } else {
        logoUrl = referrer?.logoUrl
    }
    return (
        <TextPageContainer>
            <div className="flex flex-col items-center sm:flex-row w-full sm:justify-between">
                <img
                    alt="logo"
                    src={ArwenLogo}
                    className="object-contain h-auto w-64 mb-0 mt-0"
                />
                <img
                    alt={referrer.referrer}
                    src={logoUrl}
                    className="object-contain h-auto w-40 mt-0 mb-0"
                />
            </div>
            {generateAuthenticator()}
        </TextPageContainer>
    )
}

export default CobrandedWelcome
