import {
    FilterFn,
    PaginationState,
    createColumnHelper,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { formatDistanceToNow } from 'date-fns'

import { CalendarDaysIcon, PencilIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
    UserView,
    useSearchUsersCountQuery,
    useSearchUsersQuery,
} from '../../api/client'
import SearchInput from '../../components/SearchInput'
import Spinner from '../../components/Spinner'
import SimplePagination from '../../components/table/SimplePagination'
import SimpleTable from '../../components/table/SimpleTable'
import { fuzzyFilter } from '../../components/table/helpers'
import { getInLocalTime } from '../../util/timeHelpers'

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
}

const columnHelper = createColumnHelper<UserView>()

function UserListPage() {
    const location = useLocation()

    const [globalFilter, setGlobalFilter] = useState('')
    const [data, setData] = useState<UserView[]>([])
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const {
        data: getUsersData,
        loading: getUsersLoading,
        refetch: refetchUsers,
    } = useSearchUsersQuery({
        variables: {
            params: {
                offset: pageIndex * pageSize,
                limit: pageSize,
                containsText: globalFilter,
            },
        },
    })

    const {
        data: getUsersCountData,
        loading: getUsersCountLoading,
        refetch: refetchUsersCount,
    } = useSearchUsersCountQuery({
        variables: {
            params: {
                containsText: globalFilter,
                // The offset and limit are not used for the count query but the query requires them.
                // We could create a different parameter type for the count query but this is quicker.
                offset: 0,
                limit: 0,
            },
        },
    })

    let userCount = getUsersCountData?.searchUsersCount || 0

    // When the location changes, e.g. navigating back from creating a new team, then we should refetch.
    useEffect(
        () => {
            refetchUsers()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    )

    useEffect(() => {
        setPagination({ pageIndex: 0, pageSize: 10 })
        refetchUsers()
        refetchUsersCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter])

    function UserEventsCell(info: any) {
        const user = info.row.original as UserView
        return (
            <>
                <a
                    href={`#/admin/user/events/${user.id}`}
                    className="text-primary-600 underline"
                    data-tip
                    data-for="userEventsLink"
                >
                    <CalendarDaysIcon className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer" />
                </a>
                <ReactTooltip id="userEventsLink" type="dark" delayShow={300}>
                    <span>Show a list of the user's login events</span>
                </ReactTooltip>
            </>
        )
    }

    function EditCell(info: any) {
        const user = info.row.original as UserView
        return (
            <>
                <a
                    href={`#/admin/user/edit/${user.id}`}
                    className="text-primary-600 underline"
                    data-tip
                    data-for="editUserLink"
                >
                    <PencilIcon className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer" />
                </a>
                <ReactTooltip id="editUserLink" type="dark" delayShow={300}>
                    <span>Edit this user</span>
                </ReactTooltip>
            </>
        )
    }

    const columns = [
        columnHelper.accessor('username', {
            header: () => 'Username',
            enableSorting: false,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('createdOn', {
            header: () => 'Created',
            enableSorting: false,
            cell: (info) => {
                return (
                    <span>
                        {info.getValue()
                            ? formatDistanceToNow(
                                  getInLocalTime(info.getValue()),
                                  {
                                      addSuffix: true,
                                  }
                              )
                            : null}
                    </span>
                )
            },
        }),
        columnHelper.display({
            id: 'events',
            cell: (props) => <UserEventsCell row={props.row} />,
        }),
        columnHelper.display({
            id: 'edit',
            cell: (props) => <EditCell row={props.row} />,
        }),
    ]

    useEffect(() => {
        if (getUsersData?.searchUsers) {
            setData(getUsersData?.searchUsers)
        }
    }, [getUsersData])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        pageCount: Math.ceil(userCount / pageSize),
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        manualPagination: true,
        debugTable: false,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <>
            <div className="m-10 prose prose-stone">
                <h2 className="text-gray-950 initial">User management page</h2>
                <p>This page lets you create, edit and delete users.</p>

                <div className="flex flex-row items-baseline">
                    <SearchInput
                        keyword={globalFilter ?? ''}
                        onKeywordChange={(value) =>
                            setGlobalFilter(String(value))
                        }
                        onSearch={(value) => setGlobalFilter(String(value))}
                    />

                    <div>
                        <a
                            href={`#/admin/user/create`}
                            className="text-primary-600 underline mx-4 text-md"
                        >
                            Create user
                        </a>
                    </div>
                    <div>
                        {(getUsersLoading || getUsersCountLoading) && (
                            <Spinner size={6} />
                        )}
                    </div>
                </div>
            </div>
            <div className="mx-10 prose prose-stone max-w-5xl">
                <SimpleTable table={table} />

                <SimplePagination table={table} />
            </div>
        </>
    )
}

export default UserListPage
