import React, { Component, ErrorInfo, ReactNode } from 'react'
import GenericErrorMessage from './GenericErrorMessage'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class MainErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                    <div className="max-w-max mx-auto">
                        <GenericErrorMessage />
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default MainErrorBoundary
