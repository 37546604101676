import { Link, useNavigate, useParams } from 'react-router-dom'
import { TextPageContainer } from '../../../containers/TextPageContainer'
import { TextPageHeader } from '../../../containers/TextPageHeader'
import PromptDetailsForm from '../../../components/prompt/PromptDetailsForm'
import { useTitle } from '../../../components/TitleProvider'
import { toastError, toastSuccess, toastBespokeError } from '../../../components/Notification'
import {
    useCreateLlmClassificationPromptMutation,
    useTestLlmClassificationPromptLazyQuery,
} from '../../../api/client'
import SimpleLoader from '../../../components/SimpleLoader'

type ParamTypes = {
    accountId: string
}
export default function PromptCreatePage() {
    useTitle('Create LLM Prompt')
    const navigate = useNavigate()

    // Which prompt are we editing?
    const { accountId: accountIdString } = useParams<ParamTypes>()
    const accountId = Number(accountIdString)

    const [testLlmClassificationPrompt, { loading: testResultLoading }] =
        useTestLlmClassificationPromptLazyQuery({
            onError: (error) => {
                toastError(
                    `Failed to test LLM classification prompt: ${error.message}`
                )
            },
        })

    const [createLlmClassificationPrompt, { loading: createResultLoading }] =
        useCreateLlmClassificationPromptMutation({
            onError: (error) => {
                toastBespokeError(
                    `Failed to create LLM classification prompt: ${error.message}`
                )
            },
        })

    async function createLlm(category: string, prompt: string) {
        await createLlmClassificationPrompt({
            variables: {
                accountId: accountId,
                prompt: prompt,
                category: category,
            },
            onCompleted: () => {
                toastSuccess('Prompt Created')
                navigate(`/account/${accountId}/settings#llm-settings`)
            },
        })
    }

    const handleOnTest = async (prompt: string, message: string) => {
        await testLlmClassificationPrompt({
            variables: {
                prompt: prompt,
                testMessage: message,
            },
        })
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="Create new classification prompt" />
            <Link to={`/account/${accountId}/settings#llm-settings`}>
                Back to settings
            </Link>
            {(testResultLoading || createResultLoading) && (
                <SimpleLoader loading={true} />
            )}
            <div className="flex flex-col mb-32 mt-10">
                <div className="w-full ml-3">
                    <PromptDetailsForm
                        category={''}
                        prompt={''}
                        saving={false}
                        testing={false}
                        onSave={createLlm}
                        onTest={handleOnTest}
                        onCancel={() =>
                            navigate(
                                `/account/${accountId}/settings#llm-settings`
                            )
                        }
                    />
                    {/* )} */}
                </div>
            </div>
        </TextPageContainer>
    )
}
