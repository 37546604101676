import { Link } from 'react-router-dom'

function NoTeam() {
    return (
        <div className="prose prose-stone prose-lg">
            <h1 className=" text-gray-950">You are not a member of a team</h1>
            With a team you and your colleagues can all access and manage the
            same social media profiles.
            <p className="">
                To <strong>create</strong> a team and{' '}
                <strong>add your colleagues</strong> please contact{' '}
                <Link to="/contact-us" className="text-primary-600 underline">
                    <span className="text-primary-600 underline">
                        Arwen Support
                    </span>
                </Link>
            </p>
        </div>
    )
}

export default NoTeam
