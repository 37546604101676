import { useEffect, useState } from 'react'
import Button from '../Button'

type Props = {
    onReply: (message: string) => void
    sendingReply: boolean
    initialReply: string
}

/**
 * Component to display a text area for a user to reply to a message.
 */
export default function NewReply(props: Props) {
    const { onReply, sendingReply, initialReply } = props

    const [replyText, setReplyText] = useState(initialReply)

    useEffect(() => {
        setReplyText(initialReply)
    }, [initialReply])

    return (
        <div className="flex items-start space-x-4">
            <div className="min-w-0 flex-1">
                <form action="#" className="relative flex flex-row items-end">
                    <div className="flex-grow overflow-hidden rounded-lg shadow-sm  ring-gray-300 ">
                        <label htmlFor="comment" className="sr-only">
                            Add your response
                        </label>
                        <textarea
                            rows={5}
                            name="comment"
                            id="comment"
                            className=" w-full resize-none border-0 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 ding-6"
                            placeholder="Reply to this message..."
                            value={replyText}
                            onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                const reply = event.currentTarget.value
                                setReplyText(reply)
                            }}
                        />
                    </div>

                    <div className="align-bottom inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                        <div className="flex items-center space-x-5">
                            <div className="flex items-center"></div>
                        </div>
                        <div className="flex-shrink-0">
                            <Button
                                primary={true}
                                disabled={sendingReply}
                                onClick={async () => {
                                    onReply(replyText)
                                }}
                                text="Reply"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
