import { formatISO9075 } from 'date-fns'
import { getInLocalTime } from '../../../../util/timeHelpers'
import { ContentOutcomeView, EnrichedContent } from '../../../../api/client'
import { CopyToClipboardWrapper } from '../../../CopyToClipboard'
import { getSocialMediaContentLink } from '../ContentLink'
import { ContentMetadataRow, SectionHeader } from './ContentMetadataModal'

type Props = {
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
}

export default function ContentMetadataDetails({
    enrichedContent,
    content,
}: Props) {
    const datePosted = formatISO9075(getInLocalTime(content.datePosted))
    const socialMediaContentLink =
        content.socialMediaContentLink ||
        getSocialMediaContentLink(enrichedContent, content)

    return (
        <div>
            <SectionHeader title="Content details" />
            <div className="mt-2">
                <dl>
                    <ContentMetadataRow
                        title="Content ID:"
                        zebra_dark={true}
                        children={
                            <CopyToClipboardWrapper>
                                {content.serviceContentId}
                            </CopyToClipboardWrapper>
                        }
                    />

                    <ContentMetadataRow
                        title="Content:"
                        zebra_dark={false}
                        children={
                            <CopyToClipboardWrapper>
                                {content.content}
                            </CopyToClipboardWrapper>
                        }
                    />

                    <ContentMetadataRow
                        title="Date posted:"
                        zebra_dark={true}
                        children={
                            <CopyToClipboardWrapper>
                                {datePosted}
                            </CopyToClipboardWrapper>
                        }
                    />

                    <ContentMetadataRow
                        title="Content ID:"
                        zebra_dark={false}
                        children={
                            socialMediaContentLink ? (
                                <CopyToClipboardWrapper
                                    children={
                                        <a
                                            href={socialMediaContentLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary hover:text-primary-900 underline"
                                        >
                                            {socialMediaContentLink}
                                        </a>
                                    }
                                    text={socialMediaContentLink}
                                />
                            ) : (
                                'This field is not set'
                            )
                        }
                    />

                    <ContentMetadataRow
                        title="Arwen ID:"
                        zebra_dark={true}
                        children={
                            <CopyToClipboardWrapper>
                                {content.id}
                            </CopyToClipboardWrapper>
                        }
                    />
                </dl>
            </div>
        </div>
    )
}
