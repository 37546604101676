import { useFormContext, useFormState } from 'react-hook-form'
import {
    SocialMediaServiceEnum,
    useFetchUserDetailsLazyQuery,
} from '../../api/client'
import ValidatingInput from './ValidatingInput'
import { useEffect } from 'react'
import { AtSymbolIcon } from '@heroicons/react/24/solid'

function TwitterUsernameInput() {
    const form = useFormContext()
    const { errors } = useFormState({ control: form.control })

    const [fetchUserDetails, { loading: loadingDetails, error }] =
        useFetchUserDetailsLazyQuery({
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                const error = data?.fetchUserDetails?.error
                const details = data?.fetchUserDetails?.userDetails
                if (error) {
                    form.setError('username', {
                        type: error?.type!,
                        message: error?.message!,
                    })
                } else if (details) {
                    form.setValue('serviceAccountId', details.serviceAccountId)
                    form.setValue('name', details.name)
                } else {
                    form.setError('username', {
                        type: 'unknown',
                        message: 'Unable to check username',
                    })
                }
            },
            onError: () => {
                form.setError('username', {
                    type: 'unknown',
                    message: error?.message || 'Unable to check username',
                })
            },
        })

    useEffect(() => {
        // Register the 'hidden' fields programmatically
        form.register('username')
        form.register('serviceAccountId')
        form.register('name')
    }, [form])

    const clearForm = () => {
        form.setValue('serviceAccountId', '')
        form.setValue('name', '')
    }

    const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const username = event.target.value
        clearForm()
        form.clearErrors('username')
        form.setValue('username', username, { shouldValidate: true })

        if (!username) {
            form.setError('username', {
                type: 'required',
                message: 'Username is required',
            })
        } else {
            fetchUserDetails({
                variables: {
                    socialMediaService: SocialMediaServiceEnum.Twitter,
                    serviceAccountUsername: username,
                },
            })
        }
    }

    const usersName = form.watch('name')
    return (
        <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col items-left">
            <ValidatingInput
                onChange={onUsernameChange}
                name="username"
                minimumLength={2}
                isLoading={loadingDetails}
                error={errors.username}
                leadingIcon={
                    <AtSymbolIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                    />
                }
            />
            <div>
                {usersName && (
                    <span className="text-sm font-bold text-green-700">
                        {usersName}
                    </span>
                )}
                {errors.username && (
                    <span className="text-yellow-600 text-sm">
                        {typeof errors.username.message === 'string'
                            ? errors.username.message
                            : 'Unknown error'}
                    </span>
                )}
            </div>
        </div>
    )
}

export default TwitterUsernameInput
