export default function SuggestedRepliesUpsell() {
    return (
        <div className="ml-8 ">
            <div className="mt-10 flex flex-row content-between text-gray-600">
                <div className="flex flex-row items-center">
                    <div>
                        <a
                            href="mailto:support@arwen.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="underline font-semibold"
                        >
                            Contact us
                        </a>{' '}
                        to enable suggested replies in your brand voice.
                    </div>
                </div>
            </div>
        </div>
    )
}
