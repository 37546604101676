import { useState } from 'react'
import { SocialMediaService } from '../../../api/client'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    allSocialMediaServices: Array<SocialMediaService>
    selectedSocialMediaServices: Array<SocialMediaService>
    onSocialMediaServiceListChange: (
        socialMediaServiceIds: Array<number>
    ) => void
}

export default function SocialMediaServiceFilter(props: Props) {
    const {
        allSocialMediaServices,
        selectedSocialMediaServices,
        onSocialMediaServiceListChange,
    } = props
    const [searchTerm, setSearchTerm] = useState('')

    function handleUpdateSelectedSocialMediaServices(
        selectedItems: Array<FilterItem>
    ) {
        onSocialMediaServiceListChange(selectedItems.map((item) => item.id))
    }

    return (
        <>
            <FilterInput
                filterTypeName="Social Media Services"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => {
                    setSearchTerm(term)
                }}
                onUpdateSelectedItems={handleUpdateSelectedSocialMediaServices}
                selectedItems={selectedSocialMediaServices.map(
                    mapSocialMediaServiceToFilterItem
                )}
                searchResults={allSocialMediaServices
                    .filter((service) => {
                        return service.shortName
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    })
                    .map(mapSocialMediaServiceToFilterItem)}
                searching={false}
            />
        </>
    )
}

function mapSocialMediaServiceToFilterItem(
    service: SocialMediaService
): FilterItem {
    return {
        id: service.id,
        name: service.shortName || '',
        displayName: service.displayName || '',
    }
}
