import { ClassifierDetails } from '../../api/client'

export function createClassifierDetailsMap(
    classifierDetails: ClassifierDetails[]
): Map<string, ClassifierDetails> {
    return new Map(
        classifierDetails.map((classifierDetail) => [
            classifierDetail.shortName,
            classifierDetail,
        ])
    )
}
