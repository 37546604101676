import clsx from 'clsx'
import toast, { ToastOptions } from 'react-hot-toast'

function generateErrorMessage(error: string) {
    return `${error} \n \n Please try again and if this problem persists contact support@arwen.ai.`
}

type DismissButtonProps = {
    onDismiss: () => void
}

export function DismissButton(props: DismissButtonProps) {
    return (
        <button
            onClick={props.onDismiss}
            className={clsx(
                'mt-4',
                'rounded-lg border border-gray-200',
                'py-2 px-3 flex items-center justify-center',
                'text-sm font-medium text-white hover:text-gray-200 hover:bg-gray-900',
                'focus:outline-none focus:ring-2 focus:ring-white'
            )}
        >
            Dismiss
        </button>
    )
}

// All toast styles should be defined here to ensure consistency in styling
export function toastSuccess(message: string) {
    toast.success(message, successConfiguration)
}

export function toastWarning(message: string) {
    toast(
        (t) => (
            <div onClick={() => toast.dismiss(t.id)}>
                {generateErrorMessage(message)}
                <DismissButton
                    onDismiss={() => {
                        toast.dismiss(t.id)
                    }}
                />
            </div>
        ),
        warningConfiguration
    )
}

export function toastError(message: string) {
    toast(
        (t) => (
            <div onClick={() => toast.dismiss(t.id)}>
                {generateErrorMessage(message)}
                <DismissButton onDismiss={() => toast.dismiss(t.id)} />
            </div>
        ),
        errorConfiguration
    )
}

export function toastBespokeError(message: string) {
    toast(
        (t) => (
            <div onClick={() => toast.dismiss(t.id)}>
                {message}
                <DismissButton onDismiss={() => toast.dismiss(t.id)} />
            </div>
        ),
        errorConfiguration
    )
}

export const darkStyle = {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
    maxWidth: 800,
}

export const successConfiguration: ToastOptions = {
    icon: '👍',
    style: darkStyle,
}

export const warningConfiguration: ToastOptions = {
    icon: '👋',
    style: darkStyle,
    duration: 4000,
}

export const errorConfiguration: ToastOptions = {
    icon: '❌',
    style: darkStyle,
    duration: 4000,
}
