import { Link } from 'react-router-dom'

type Props = {
    accountId: number
}

/**
 * Displays a Dashoard button, linking to the given account's dashboard.
 */
export default function DashboardButton({ accountId }: Props) {
    return (
        <Link
            className="text-primary-text hover:text-primary-text bg-primary p-2 rounded inline-flex items-center"
            key={accountId}
            to={`/account/${accountId}`}
            data-cy="dashboard_button"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
            </svg>
            <span>Dashboard</span>
        </Link>
    )
}
