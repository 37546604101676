import { Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getEmail } from '../../util/branding'
import { classNames } from '../../util/classNames'

type Props = {
    signOut: () => void
}

function ProfileButton(props: Props) {
    const [email, setEmail] = useState('')
    useEffect(() => {
        getEmail(setEmail)
    }, [])

    const { signOut } = props
    return (
        <>
            <Menu as="div" className="z-50 " aria-label="Profile menu">
                <div>
                    <Menu.Button
                        className="p-2 hover:bg-gray-200 rounded-full focus:outline-none text-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
                        aria-label="profile button"
                        data-cy="profile_button"
                    >
                        <UserIcon className="h-8 w-8" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="z-20 divide-y origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <div className="text-sm text-gray-700 block px-4 py-4 font-bold">
                                        {email}
                                    </div>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to="/customer-payment-portal"
                                        className={classNames(
                                            active
                                                ? 'bg-gray-100 text-gray-950'
                                                : 'text-gray-700',
                                            'block px-4 py-2 text-sm '
                                        )}
                                        data-cy="manage_billing_button"
                                    >
                                        Manage billing
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={signOut}
                                        aria-label="Sign out"
                                        data-cy="sign_out_button"
                                        className={classNames(
                                            active
                                                ? 'bg-gray-100 text-gray-950'
                                                : 'text-gray-700',
                                            'block px-4 py-2 text-primary-700 text-sm w-full text-left'
                                        )}
                                    >
                                        Sign Out
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    )
}

export default ProfileButton
