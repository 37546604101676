import { useEffect, useState } from 'react'
import {
    ContentOutcomeView,
    useGetSuggestedRepliesQuery,
} from '../../api/client'
import SimpleLoader from '../SimpleLoader'
import SuggestedReply from './SuggestedReply'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import ReactTooltip from 'react-tooltip'

type Props = {
    onSelection: (message: string) => void
    suggestedReplies?: string[]
    setSuggestedReplies: (messsages?: string[]) => void
    content: ContentOutcomeView
}

export default function SuggestedRepliesList(props: Props) {
    const { onSelection, suggestedReplies, setSuggestedReplies, content } =
        props
    const [getReplyError, setGetReplyError] = useState<String>()

    const {
        data: suggestedReply,
        loading: suggestedReplyLoading,
        refetch,
    } = useGetSuggestedRepliesQuery({
        variables: {
            contentId: content.id,
            numberOfSuggestions: 3,
        },
        onError: (error) => {
            setGetReplyError(error.message)
        },
        onCompleted: (data) => {
            setSuggestedReplies(data.getSuggestedReplies)
        },
        fetchPolicy: 'no-cache',
    })

    const [isRefetching, setIsRefetching] = useState(false)

    useEffect(() => {
        setSuggestedReplies(suggestedReply?.getSuggestedReplies)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestedReply])

    useEffect(() => {
        // The tooltip function we use doesn't have good support for modals or infinite scroll.
        // We force the tooltips to be rebuilt after every render
        ReactTooltip.rebuild()
    })

    return (
        <div className="ml-8 ">
            {!getReplyError && !suggestedReplyLoading && !isRefetching ? (
                <div className="mt-3 flex flex-row content-between text-gray-600">
                    <div className="flex flex-row items-center text-sm">
                        <div>
                            <a
                                href="mailto:support@arwen.ai"
                                target="_blank"
                                rel="noreferrer"
                                className="underline font-semibold"
                            >
                                Contact us
                            </a>{' '}
                            to customise your suggested replies.
                        </div>
                        <button
                            aria-label="Refresh"
                            data-tip="Click to refresh the page"
                            type="button"
                            className={clsx(
                                'inline-flex justify-self-end px-1 py-1 ml-2 leading-4  text-sm',
                                'rounded-full bg-white  text-gray-700  hover:bg-gray-100 ',
                                'focus:ring-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2'
                            )}
                            onClick={async () => {
                                setIsRefetching(true)
                                setSuggestedReplies([])
                                await refetch()
                                setIsRefetching(false)
                            }}
                        >
                            <ArrowPathIcon
                                className="h-4 w-4 text-gray-700"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </div>
            ) : null}
            {!getReplyError && (isRefetching || suggestedReplyLoading) ? (
                <div className="ml-3 mt-10 w-50 items-bottom flex flex-row text-gray-700 self-start">
                    Loading suggestions
                    <SimpleLoader classNames="ml-2" loading={true} />
                </div>
            ) : null}
            {getReplyError ? (
                <div className="pt-5">
                    {' '}
                    Suggested replies not working currently, please refresh and
                    try again...{' '}
                </div>
            ) : null}
            {!suggestedReplyLoading && !isRefetching && suggestedReplies
                ? suggestedReplies.map((reply) => {
                      return (
                          <div key={reply}>
                              <SuggestedReply
                                  replySuggestion={reply}
                                  onSelection={onSelection}
                              ></SuggestedReply>
                          </div>
                      )
                  })
                : null}
        </div>
    )
}
