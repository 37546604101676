import TikTokAuthorisationButton from '../components/authorise/platforms/TikTokAuthorisationButton'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

type NewTikTokAccountPageProps = {
    isAdsPage?: boolean
}

function NewTikTokAccountPage({ isAdsPage }: NewTikTokAccountPageProps) {
    return (
        <TextPageContainer>
            <TextPageHeader
                title={
                    isAdsPage
                        ? 'Add a TikTok Ad Account'
                        : 'Add a TikTok User profile'
                }
            />
            <p>To add a TikTok account please:</p>
            <ol>
                <li>
                    ensure you are logged into the TikTok profile you want to
                    add
                </li>

                <li>click the button below</li>
            </ol>
            <TikTokAuthorisationButton isAdsPage={isAdsPage} />
        </TextPageContainer>
    )
}

export default NewTikTokAccountPage
