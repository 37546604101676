import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Transition,
} from '@headlessui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

function YouTubeChannelIDHelpDisclosure() {
    return (
        <div className="">
            <Disclosure>
                <DisclosureButton className=" text-sm text-primary-600">
                    How do I find my channel ID?
                </DisclosureButton>
                <Transition
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 -translate-y-6"
                    enterTo="opacity-100 translate-y-0"
                    leave="duration-300 ease-out"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-6"
                >
                    <DisclosurePanel className="origin-top transition">
                        <div className="mt-2 max-w-96">
                            <p className="text-sm">
                                Go to the{' '}
                                <a
                                    rel="noreferrer"
                                    href="https://www.youtube.com/account_advanced"
                                    target={'_blank'}
                                    className="underline text-primary hover:text-primary-800 visited:text-purple-600"
                                >
                                    YouTube advanced settings page
                                    <ArrowTopRightOnSquareIcon className="w-4 inline" />
                                </a>{' '}
                                and copy the Channel ID as shown below.
                            </p>
                            <img
                                src="./youtube_advanced_settings.png"
                                className="bg-white border rounded w-96"
                                alt="A screenshot that shows where to find the Channel ID on YouTube's advanced settings page."
                            />
                        </div>
                    </DisclosurePanel>
                </Transition>
            </Disclosure>
        </div>
    )
}
export default YouTubeChannelIDHelpDisclosure
