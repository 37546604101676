import { atom } from 'recoil'

import User from '../models/User'
import Team from '../models/Team'

export const userState = atom({
    key: 'userState',
    default: {} as User,
})

export const userTeamsState = atom({
    key: 'userTeamsState',
    default: {} as Team[],
})

export const isUserAdminState = atom({
    key: 'isUserAdminState',
    default: false,
})

export const canUserLikeContentState = atom({
    key: 'canUserLikeContentState',
    default: false,
})

export const canUserReplyToContentState = atom({
    key: 'canUserReplyToContentState',
    default: false,
})

export const isPlatformAppReviewerState = atom({
    key: 'isPlatformAppReviewerState',
    default: false,
})

export const canViewIntelligencePageState = atom({
    key: 'canViewIntelligencePageState',
    default: false,
})
