import {
    AccountRecord,
    ContentOutcomeView,
    SocialMediaServiceEnum,
} from '../../../api/client'
import User from '../../../models/User'

type Props = {
    content: ContentOutcomeView
    isSelected: boolean
    onSelectionChange: (
        content: ContentOutcomeView,
        isSelected: boolean
    ) => void
}

/**
 * This is used in the ContentCard component to allow the user to select content.
 */
export default function ContentSelector(props: Props) {
    const { content, isSelected, onSelectionChange } = props

    const classes = isSelected
        ? ' opacity-100 '
        : ' opacity-0 group-hover:opacity-100 '

    return (
        <div
            className={`flex transition-opacity duration-300 items-center justify-center absolute h-full  w-12 hover:cursor-pointer  bg-primary-500 ${classes}`}
            onClick={() => {
                onSelectionChange(content, !isSelected)
            }}
        >
            <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={isSelected}
                onChange={() => {
                    onSelectionChange(content, !isSelected)
                }}
                className="opacity-100 h-4 w-4 rounded border-gray-300 text-accent-2-500 focus:ring-accent-2-600"
            />
        </div>
    )
}

export function canUseBulkActions(user: User, account?: AccountRecord) {
    /**
     * The User should only be able to see the selector and bulk actions toolbar under the following conditions:
     * - The account is a Facebook account as currently there is only one bulk action, liking, which is only available on Facebook
     * -    => If the account is not defined then the bulk action should be displayed as the user may be searching across multiple accounts
     * - The user has permission to like content - as there is currently only one bulk action, liking, then if they don't have permission it makes no sense to see the selector
     */
    if (!user.canLikeContent) {
        // This overrides the other conditions.
        return false
    }
    if (!account && user.canLikeContent) {
        // User is on the Engage page or the multi-account search page
        return true
    }
    if (
        account?.socialMediaServiceShortName?.toLowerCase() ===
            SocialMediaServiceEnum.Facebook.toLowerCase() &&
        user.canLikeContent
    ) {
        // Account is facebook and user can like content
        return true
    }

    return false
}
