import { ContentOutcomeView } from '../../../../api/client'
import {
    ConditionalCopyToClipboard,
    CopyToClipboardWrapper,
} from '../../../CopyToClipboard'
import { ContentMetadataRow, SectionHeader } from './ContentMetadataModal'

type Props = {
    content: ContentOutcomeView
}

export default function ContentMetadataPostDetails({ content }: Props) {
    return (
        <div>
            <SectionHeader title="Original post details" />
            <div className="mt-2">
                <dl>
                    <ContentMetadataRow
                        title="Original post ID:"
                        zebra_dark={true}
                        children={
                            <ConditionalCopyToClipboard
                                text={content.originalPostId}
                                fallback="This field is not set"
                            />
                        }
                    />
                    <ContentMetadataRow
                        title="Original post URL:"
                        zebra_dark={false}
                        children={
                            content.socialMediaPostLink ? (
                                <CopyToClipboardWrapper
                                    children={
                                        <a
                                            href={content.socialMediaPostLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary hover:text-primary-900 underline"
                                        >
                                            {content.socialMediaPostLink}
                                        </a>
                                    }
                                    text={content.socialMediaPostLink}
                                />
                            ) : (
                                'This field is not set'
                            )
                        }
                    />
                </dl>
            </div>
        </div>
    )
}
