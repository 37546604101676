export function getPageDetails(
    maxPagesToDisplay: number,
    total: number,
    offset: number,
    limit: number
) {
    const totalPages = Math.ceil(total / limit)

    const visiblePageButtons =
        totalPages > maxPagesToDisplay ? maxPagesToDisplay : totalPages

    const currentPage = Math.round(offset / limit) + 1

    let firstPageToDisplay =
        currentPage > visiblePageButtons - Math.floor(visiblePageButtons / 2)
            ? currentPage - Math.floor(visiblePageButtons / 2)
            : 1

    let lastPageToDisplay =
        currentPage > visiblePageButtons
            ? currentPage + Math.floor(visiblePageButtons / 2)
            : visiblePageButtons + Math.floor(currentPage / 2)

    // Will the last page be greater than the total number of pages?
    if (lastPageToDisplay > totalPages) {
        // Yes, so we need to adjust the first page
        firstPageToDisplay =
            firstPageToDisplay - (lastPageToDisplay - totalPages)
        lastPageToDisplay = totalPages
    }

    if (firstPageToDisplay < 1) {
        firstPageToDisplay = 1
    }

    return {
        firstPageToDisplay,
        currentPage,
        lastPageToDisplay,
        visiblePageButtons,
        totalPages,
    }
}
