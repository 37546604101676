import { Popover, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'
import { faIconName } from '../../../util/classNames'
import SimpleLoader from '../../SimpleLoader'

export type FilterItem = {
    id: number
    name?: string
    displayName: string
    iconName?: string
    hideName?: boolean
    rank?: number
}

type FilterItemProps = {
    item: FilterItem
    selected: boolean
    hideName?: boolean
    onItemDeselect: (item: FilterItem) => void
    onItemSelect: (item: FilterItem) => void
}

function FilterItemDisplay(props: FilterItemProps) {
    const { item, selected, onItemDeselect, onItemSelect, hideName } = props
    let iconName = item.iconName ? faIconName(item.iconName, true) : undefined

    return (
        <div
            key={item.id}
            className="group relative flex flex-row gap-x-6 rounded-lg p-2 hover:bg-gray-50 items-center"
        >
            <input
                type="checkbox"
                checked={selected}
                onChange={(event) => {
                    const selected = event.target.checked
                    if (selected) {
                        // Item is now selected - this is the new state
                        onItemSelect(item)
                    } else {
                        onItemDeselect(item)
                    }
                }}
            />
            <p className="mt-1 text-gray-800">
                {iconName && (
                    <i
                        className={`text-base mr-2 text-gray-700 fab ${iconName}`}
                    ></i>
                )}
                {item.displayName}{' '}
                {item.name && !hideName ? (
                    <span className="text-gray-700">@{item.name}</span>
                ) : null}
            </p>
        </div>
    )
}

type Props = {
    filterTypeName: string
    searchTerm: string
    onSearchTermChange: (searchTerm: string) => void
    selectedItems: Array<FilterItem>
    searchResults: Array<FilterItem>
    onUpdateSelectedItems: (selectedItems: Array<FilterItem>) => void
    searching: boolean
    hideName?: boolean
}

export default function FilterInput(props: Props) {
    const {
        filterTypeName,
        searchTerm,
        onSearchTermChange,
        selectedItems,
        onUpdateSelectedItems,
        searchResults,
        searching,
        hideName,
    } = props

    function handleChange(event: React.FormEvent<HTMLInputElement>) {
        const newKeyword = event.currentTarget.value

        onSearchTermChange(newKeyword)
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            // doSearch()
        }
    }

    function handleItemDeselect(deselectedItem: FilterItem) {
        onUpdateSelectedItems(
            selectedItems.filter(
                (previouslySelectedItem) =>
                    previouslySelectedItem.id !== deselectedItem.id
            )
        )
    }
    function handleItemSelect(selectedItem: FilterItem) {
        onUpdateSelectedItems([...selectedItems, selectedItem])
    }

    function handleSelectAll() {
        onUpdateSelectedItems(searchResults)
    }

    function handleDeselectAll() {
        onUpdateSelectedItems([])
    }

    function generatePopoverButtonTitle() {
        if (selectedItems.length === 0) {
            return <span> {filterTypeName} </span>
        } else if (selectedItems.length === 1) {
            return <span>{selectedItems[0].displayName}</span>
        } else if (selectedItems.length === 2) {
            return (
                <span>{`${selectedItems[0].displayName}, ${selectedItems[1].displayName}`}</span>
            )
        }
        return (
            <span>
                {' '}
                {`${selectedItems.length} ${filterTypeName} selected`}{' '}
            </span>
        )
    }

    const unselectedItems = searchResults.filter((result) => {
        return selectedItems.find((item) => item.id === result.id) === undefined
    })

    return (
        <div className="shadow-md">
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Popover.Button className="inline-flex items-center gap-x-1 text-sm  leading-6 text-gray-950">
                            <span className="relative inline-flex items-center space-x-2 px-4 py-2 text-sm  rounded-md text-gray-950 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary">
                                <span>{generatePopoverButtonTitle()}</span>
                                <ChevronDownIcon
                                    className={
                                        open
                                            ? 'rotate-180 transform h-5 w-5'
                                            : 'h-5 w-5'
                                    }
                                    aria-hidden="true"
                                />
                            </span>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-56 z-30 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4 ">
                                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        <div className="flex flex-row items-center">
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleChange}
                                                onKeyPress={handleKeyPress}
                                                placeholder={`Find ${filterTypeName}...`}
                                                className="border-0 w-full"
                                                autoFocus={true}
                                            />
                                            <MagnifyingGlassIcon
                                                className="h-5 w-5 text-gray-400 fixed right-8"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>

                                    <div className="pl-4 ">
                                        {searching ? (
                                            <SimpleLoader
                                                loading={true}
                                                classNames="pt-12 w-8"
                                            />
                                        ) : null}
                                        <div className="overflow-y-scroll h-44 mb-4">
                                            {selectedItems.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="group relative flex gap-x-6 rounded-lg hover:bg-gray-50"
                                                >
                                                    <FilterItemDisplay
                                                        item={item}
                                                        selected={true}
                                                        onItemDeselect={
                                                            handleItemDeselect
                                                        }
                                                        onItemSelect={
                                                            handleItemSelect
                                                        }
                                                        hideName={hideName}
                                                    />
                                                </div>
                                            ))}
                                            {!searching &&
                                                unselectedItems.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        className="group relative flex gap-x-4 rounded-lg  hover:bg-gray-50"
                                                    >
                                                        <FilterItemDisplay
                                                            item={item}
                                                            selected={false}
                                                            onItemDeselect={
                                                                handleItemDeselect
                                                            }
                                                            onItemSelect={
                                                                handleItemSelect
                                                            }
                                                            hideName={hideName}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                        <button
                                            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-950 hover:bg-gray-100"
                                            onClick={handleSelectAll}
                                        >
                                            Select all
                                        </button>
                                        <button
                                            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-950 hover:bg-gray-100"
                                            onClick={handleDeselectAll}
                                        >
                                            Deselect all
                                        </button>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
}
