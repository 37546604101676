import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Fragment, useState } from 'react'
import {
    ClassifierDetails,
    ModerationBoundary,
    useBulkUpdateModerationBoundaryMutation,
    useGetAccountQuery,
    useGetClassifierDetailsQuery,
    useGetModerationBoundariesQuery,
} from '../../api/client'
import SimpleModal from '../SimpleModal'
import EditModerationBoundary from './EditModerationBoundary'
import ModerationBoundaryList from './ModerationBoundaryList'

import { Listbox } from '@headlessui/react'
import { Link } from 'react-router-dom'
import SimpleLoader from '../SimpleLoader'
import getModerationTemplates, {
    ModerationTemplate,
} from './ModerationTemplates'
import { createClassifierDetailsMap } from './classifierDetailsMapper'
import { mapTemplateValue } from './moderationBoundaryMapper'

enum Queue {
    SAFE = 'SAFE',
    SUSPECT = 'SUSPECT',
    SEVERE = 'SEVERE',
}

type Props = {
    accountId: number
}

export default function ModerationContent(props: Props) {
    const { accountId } = props

    const [editOpen, setEditOpen] = useState(false)
    const [selectedBoundary, setSelectedBoundary] =
        useState<ModerationBoundary | undefined>()

    const [showApplyTemplateDialog, setShowApplyTemplateDialog] =
        useState<boolean>(false)

    const { data: accountData } = useGetAccountQuery({
        variables: { accountId },
    })

    const { data, loading, refetch } = useGetModerationBoundariesQuery({
        variables: { accountId: accountId },
    })

    const { data: classifierDetails } = useGetClassifierDetailsQuery()
    const classifierDetailsData = classifierDetails?.getClassifierDetails || []
    const classifierDetailsMap: Map<string, ClassifierDetails> =
        createClassifierDetailsMap(classifierDetailsData)

    const [
        bulkUpdateModerationBoundaryMutation,
        { loading: bulkUpdatingModerationBoundary },
    ] = useBulkUpdateModerationBoundaryMutation()

    const handleApplyTemplate = async () => {
        const mappedSevereBoundaries = mapTemplateValue(
            accountId,
            classifierDetailsMap,
            selectedTemplate?.severe.values(),
            Queue.SEVERE
        )

        const mappedSuspectBoundaries = mapTemplateValue(
            accountId,
            classifierDetailsMap,
            selectedTemplate?.suspect.values(),
            Queue.SUSPECT
        )

        const mappedSafeBoundaries = mapTemplateValue(
            accountId,
            classifierDetailsMap,
            selectedTemplate?.safe.values(),
            Queue.SAFE
        )

        await bulkUpdateModerationBoundaryMutation({
            variables: {
                accountId: accountId,
                moderationBoundaries: [
                    ...mappedSevereBoundaries,
                    ...mappedSuspectBoundaries,
                    ...mappedSafeBoundaries,
                ],
            },
        })

        refetch()

        setShowApplyTemplateDialog(false)
    }

    const boundaries = data?.getModerationBoundaries

    const severeBoundaries = boundaries?.filter(
        (boundary) => boundary.queue === Queue.SEVERE
    )

    const suspectBoundaries = boundaries?.filter(
        (boundary) => boundary.queue === Queue.SUSPECT
    )

    const safeBoundaries = boundaries?.filter(
        (boundary) => boundary.queue === Queue.SAFE
    )

    const templates = getModerationTemplates(
        accountData?.getAccount?.socialMediaServiceShortName || ''
    )

    const [selectedTemplate, setSelectedTemplate] =
        useState<ModerationTemplate | undefined>()

    return (
        <div>
            <div className="prose prose-stone prose-xlg mb-6">
                <h1>Moderation Boundaries</h1>
                <Link to={`/account/${accountId}/settings`}>
                    Back to settings
                </Link>
            </div>
            <div className="mb-6 flex w-3/4 flex-row  items-baseline justify-start content-evenly">
                <div className="pr-4">Compare with </div>
                <Listbox
                    value={selectedTemplate}
                    onChange={setSelectedTemplate}
                >
                    <div className="relative mt-1 w-1/2">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                            <span className="block truncate">
                                {selectedTemplate?.displayName || 'Select...'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Listbox.Option
                                    key="none_selected"
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-4 pr-4 ${
                                            active
                                                ? 'bg-amber-100 text-amber-900'
                                                : 'text-gray-950'
                                        }`
                                    }
                                    value=""
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate pl-6 ${
                                                    selected
                                                        ? ''
                                                        : 'font-normal'
                                                }`}
                                            >
                                                None
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                                {templates.map((template, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-4 pr-4 ${
                                                active
                                                    ? 'bg-amber-100 text-amber-900'
                                                    : 'text-gray-950'
                                            }`
                                        }
                                        value={template}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block truncate pl-6 ${
                                                        selected
                                                            ? ''
                                                            : 'font-normal'
                                                    }`}
                                                >
                                                    {template.displayName}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
                <div className="pl-4">
                    <button
                        className="text-primary-text hover:text-primary-text bg-primary-600 p-2 rounded inline-flex items-center"
                        onClick={() => setShowApplyTemplateDialog(true)}
                    >
                        <span>Apply template</span>
                    </button>
                </div>
            </div>
            <div className="my-6">
                <a href={`#/account/${accountId}/keywords`}>
                    <span className="text-primary-600 underline">
                        Manage keywords
                    </span>
                </a>
            </div>
            <SimpleModal
                open={editOpen}
                onClose={() => {
                    setSelectedBoundary(undefined)
                    setEditOpen(false)
                }}
            >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <EditModerationBoundary
                        onClose={() => {
                            setEditOpen(false)
                        }}
                        onCloseAndSave={() => {
                            setEditOpen(false)
                            refetch()
                        }}
                        selectedBoundary={selectedBoundary}
                        onSelectedBoundaryUpdate={setSelectedBoundary}
                    />
                </Dialog.Panel>
            </SimpleModal>

            <SimpleModal
                open={showApplyTemplateDialog}
                onClose={() => {
                    setShowApplyTemplateDialog(false)
                }}
            >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6  text-gray-950"
                    >
                        Are you sure?
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Applying this template will change all existing
                            moderation boundaries to those shown
                        </p>
                    </div>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            You cannot undo this action.
                        </p>
                    </div>
                    <div className="flex flex-row">
                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm  text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                                onClick={handleApplyTemplate}
                            >
                                Confirm and apply changes
                            </button>
                        </div>

                        {bulkUpdatingModerationBoundary ? (
                            <div className="ml-6 mt-4">
                                <SimpleLoader loading={true} />
                            </div>
                        ) : null}
                    </div>
                </Dialog.Panel>
            </SimpleModal>

            <div className="prose prose-stone prose-lg mb-6">
                <h2>Severe queue </h2>
            </div>
            {loading}
            <div className="w-6/12">
                <ModerationBoundaryList
                    boundaries={severeBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-primary-600"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.severe || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                />
            </div>

            <div className="prose prose-stone prose-lg mt-8 mb-6">
                <h2>Suspect queue </h2>
            </div>
            {loading}
            <div className="w-6/12">
                <ModerationBoundaryList
                    boundaries={suspectBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-suspect-400"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.suspect || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                />
            </div>

            <div className="prose prose-stone prose-lg mt-8 mb-6">
                <h2>Safe queue </h2>
            </div>
            {loading}
            <div className="w-6/12">
                <ModerationBoundaryList
                    boundaries={safeBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-safe-400"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.safe || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                />
            </div>
        </div>
    )
}
