import { ContentActionView, ContentOutcomeView } from '../../api/client'
import { faIconName } from '../../util/classNames'
import ContentSidebar from '../content/content-card/ContentSidebar'
import ReplyMetadata from './ReplyMetadata'

type Props = {
    action: ContentActionView
    content: ContentOutcomeView
}

/**
 * An Arwen User's reply to a message.
 */
export default function ReplyUs(props: Props) {
    const { content, action } = props
    let iconName = faIconName(
        content.socialMediaServiceName.toLowerCase(),
        true
    )

    return (
        <div className="h-full grow overflow-hidden  ">
            <div className="flex h-full flex-row">
                <ContentSidebar
                    iconName={iconName}
                    moderationStyle={'safe'}
                    isPublic={true}
                    socialMediaServiceName={content.socialMediaServiceName}
                    rounded={true}
                    isSelected={false}
                />
                <div className="flex flex-grow flex-col  pb-2 pl-4 pr-4 pt-3 h-24 h-full">
                    <div>
                        <div className="mb-1 flex flex-row justify-between">
                            <ReplyMetadata action={action} />
                        </div>
                    </div>
                    <div className="mt-1 text-gray-950">{action.message}</div>
                </div>
            </div>
        </div>
    )
}
