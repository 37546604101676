import SupportEmailText from '../components/help/SupportEmailText'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

function NewTikTokAccountPage() {
    return (
        <TextPageContainer>
            <TextPageHeader title="Add a TikTok User account" />
            <p>
                To be able to moderate with Arwen, you must add TikTok on-call
                with one of our team. Please email us to arrange a call.
            </p>
            <SupportEmailText />
        </TextPageContainer>
    )
}

export default NewTikTokAccountPage
