export function SettingReadonly({
    name,
    description,
    value,
}: {
    name: string
    description: string
    value: string
}) {
    return (
        <div className="flex flex-col mb-3">
            <div className="flex flex-row">
                <div className="w-56">{name}</div>
                <div>{value}</div>
            </div>

            <div className="text-sm text-gray-600">{description}</div>
        </div>
    )
}
