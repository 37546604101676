import { useState } from 'react'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    allTopics: string[]
    selectedTopics: string[]
    onUpdateSelectedTopics: (topics: Array<string>) => void
}

export default function EmotionFilter(props: Props) {
    const { allTopics, selectedTopics, onUpdateSelectedTopics } = props
    const [searchTerm, setSearchTerm] = useState('')

    function handleUpdateSelectedTopics(selectedItems: Array<FilterItem>) {
        onUpdateSelectedTopics(selectedItems.map((item) => item.displayName))
    }

    return (
        <>
            <FilterInput
                filterTypeName="Topics"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => {
                    setSearchTerm(term)
                }}
                onUpdateSelectedItems={handleUpdateSelectedTopics}
                selectedItems={selectedTopics.map(mapStringItemToFilterItem)}
                searchResults={allTopics.map(mapStringItemToFilterItem)}
                searching={false}
            />
        </>
    )
}

function mapStringItemToFilterItem(emotion: string, index: number): FilterItem {
    return {
        id: index,
        displayName: emotion,
    }
}
