import { useState } from 'react'
import { AccountRecord, useUpdateFetchPostsMutation } from '../../api/client'
import Toggle from './Toggle'

const FetchPostsToggle = ({ account }: { account: AccountRecord }) => {
    const [updateFetchPosts, { loading }] = useUpdateFetchPostsMutation()

    const [enabled, setEnabled] = useState(account.fetchPosts)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateFetchPosts({
            variables: {
                accountId: account.id,
                fetchPosts: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`fetch-posts-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="Fetch posts"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enable or disable fetching of posts for Meta profiles"
            size="large"
        />
    )
}

export default FetchPostsToggle
