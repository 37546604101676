import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ApolloProvider } from '@apollo/client/react'
import '@aws-amplify/ui-react/styles.css'
import { Authenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { fetchAuthSession } from 'aws-amplify/auth'
import { createAuthLink } from 'aws-appsync-auth-link'
import { AUTH_TYPE } from 'aws-appsync-auth-link/lib/auth-link'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import App from './App'
import config from './config'
import './index.css'
import AuthEventListener from './listener/AuthEventListener'
import reportWebVitals from './reportWebVitals'
import { RecoilRoot } from 'recoil'
import { TitleProvider } from './components/TitleProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'

validateConfig()
startup()

async function startup() {
    const urlHashExists = !!window.location.hash
    const urlQueryExists = !!window.location.search
    if (urlQueryExists && !urlHashExists) {
        /*
        If the user navigates to our page with a query param. Re-write the URL so the query
        params come after the hash. (This is what react router expects.)
        */
        window.location.href =
            window.location.protocol +
            '//' +
            window.location.host +
            '/#/' +
            window.location.search
        return
    }

    try {
        mixpanel.init(config.analytics.token, {
            debug: true,
            track_pageview: true,
            persistence: 'localStorage',
            ignore_dnt: true,
        })
    } catch (e) {
        console.error('Mixpanel initialisation error', e)
    }

    const getAccessToken = async (): Promise<string> => {
        const authSession = await fetchAuthSession()
        if (authSession) {
            return authSession.tokens?.idToken?.toString() || ''
        }
        return ''
    }

    console.info(
        `Configuring amplify with UserPoolID: ${config.cognito.USER_POOL_ID}, IdentityPoolID ${config.cognito.IDENTITY_POOL_ID}, AppClientID ${config.cognito.APP_CLIENT_ID}, region ${config.cognito.REGION}`
    )

    console.info(`Using unauth_api ${config.unauthApi.url}`)
    // We use Amplify to manage user logins but we don't use the AWSAppSyncClient to authorise GraphQL queries/mutations.
    // There are dependency incompatibilities between AWSAppSyncClient and Apollo so we use Apollo Links to manage this
    // See https://www.apollographql.com/docs/react/api/link/apollo-link-http/

    Amplify.configure({
        Auth: {
            Cognito: {
                // These will all have been tested for existence in validateConfig()
                userPoolId: config.cognito.USER_POOL_ID || '',
                identityPoolId: config.cognito.IDENTITY_POOL_ID || '',
                userPoolClientId: config.cognito.APP_CLIENT_ID || '',
                allowGuestAccess: false,

                loginWith: {
                    // This is required for SSO
                    oauth: {
                        domain: config.cognito.COGNITO_DOMAIN || '',
                        // This is used to build the "Sign in with your corporate ID" redirect link
                        redirectSignIn: [
                            'https://localhost:3000',
                            config.domain.url,
                        ],
                        redirectSignOut: ['https://arwen.ai'],
                        // This Amplify know to expect a token (rather than a code) in the redirection from the Hosted UI to our app.
                        // The amplify lib will pick up the token and use it for authentication.
                        responseType: 'token',
                        scopes: [
                            'phone',
                            'email',
                            'profile',
                            'openid',
                            'aws.cognito.signin.user.admin',
                        ],
                        providers: [],
                    },
                    email: true,
                },
            },
        },
    })

    console.info(
        `Configuring Apollo with url ${config.appSync.aws_appsync_graphqlEndpoint}, region ${config.appSync.region} `
    )
    const httpLink = new HttpLink({
        uri: config.appSync.aws_appsync_graphqlEndpoint,
    })

    // Batching requests is possible with GraphQL but needs to be carefully tuned.
    // Mixing slow and fast requests will cause a slow update across all requests.
    // const link = new BatchHttpLink({
    //     uri: config.appSync.aws_appsync_graphqlEndpoint,
    //     batchMax: 5, // No more than 5 operations per batch
    //     batchInterval: 20, // Wait no more than 20ms after first batched operation
    // })

    const authLink = createAuthLink({
        url: config.appSync.aws_appsync_graphqlEndpoint,
        region: config.appSync.region,
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: getAccessToken,
        },
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                        locations
                    )}, Path: ${path} `
                )
            )

        if (networkError) console.error(`[Network error]: ${networkError} `)
    })

    const client = new ApolloClient({
        //@ts-ignore - so another incompatibility with Apollo
        link: from([authLink, errorLink, httpLink]),
        cache: new InMemoryCache(),
    })

    // We use the I18n features of Amplify UI to customise the lables and placeholders.
    I18n.putVocabulariesForLanguage('en', {
        Username: 'Email address', // Username label
        Password: 'Password', // Password label
        'Forgot your password?': 'Reset password',
    })

    new AuthEventListener(client).listen()
    const queryClient = new QueryClient()
    const container = document.getElementById('root')
    const root = createRoot(container!)
    root.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={client}>
                    <HashRouter>
                        <Authenticator.Provider>
                            <RecoilRoot>
                                <TitleProvider>
                                    <App />
                                </TitleProvider>
                            </RecoilRoot>
                        </Authenticator.Provider>
                    </HashRouter>
                </ApolloProvider>
            </QueryClientProvider>
        </React.StrictMode>
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
}

function validateConfig() {
    console.assert(
        config.domain.url,
        'CloudFront URL is not defined - cannot continue.'
    )
    console.assert(
        config.appSync.aws_appsync_graphqlEndpoint,
        'GraphQL Endpoint is not defined - cannot continue.'
    )
    console.assert(
        config.appSync.region,
        'AWS Region is not defined - cannot continue.'
    )
    console.assert(
        config.cognito.REGION,
        'AWS Region is not defined - cannot continue.'
    )

    console.assert(
        config.cognito.COGNITO_DOMAIN,
        'Cognito domain is not defined - cannot continue.'
    )
    console.assert(
        config.cognito.USER_POOL_ID,
        'User Pool ID is not defined - cannot continue.'
    )
    console.assert(
        config.cognito.APP_CLIENT_ID,
        'App Client ID is not defined - cannot continue.'
    )
    console.assert(
        config.cognito.IDENTITY_POOL_ID,
        'Identity Pool ID is not defined - cannot continue.'
    )
    console.assert(
        config.stripe.publishableKey,
        'Stripe publishable key is not defined - cannot continue.'
    )
    console.assert(
        config.analytics.token,
        'Product Analytics token is not defined - cannot continue.'
    )
}
