export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

/**
 * The name in our social media service enums might not map exactly to
 * a name in the font awesome library.
 * @param socialMediaServiceName
 * @returns An icon name, like 'fa-tiktok'
 */
export function faIconName(
    socialMediaServiceName: string | undefined,
    showColours: boolean
) {
    let faIconName = ''

    switch (socialMediaServiceName) {
        case 'all platforms':
            break
        case 'tikapi':
            faIconName = `fa-tiktok ${showColours ? 'tiktok' : ''}`
            break
        case 'twitter':
            faIconName = `fa-x-twitter ${showColours ? 'text-[#000000]' : ''}`
            break
        case 'tiktokad':
            faIconName = `fa-tiktok ${showColours ? 'tiktok' : ''}`
            break
        case 'tiktokuser':
            faIconName = `fa-tiktok ${showColours ? 'tiktok' : ''}`
            break
        case 'youtube':
            faIconName = `fa-youtube ${showColours ? 'text-[#ff0000]' : ''}`
            break
        case 'instagram':
            faIconName = `fa-instagram ${showColours ? 'text-[#000000]' : ''}`
            break
        case 'facebook':
            faIconName = `fa-facebook ${showColours ? 'text-[#0866ff]' : ''}`
            break
        default:
            faIconName = `fa-${socialMediaServiceName?.toLocaleLowerCase()}`
    }
    return faIconName
}
