import { classNames } from '../../../util/classNames'

export const LAST_HOUR_MINUTES = 60
export const LAST_12_HOURS_MINUTES = 60 * 12
export const LAST_DAY_MINUTES = 1 * 24 * 60
export const LAST_7_DAYS_MINUTES = 7 * 24 * 60
export const LAST_14_DAYS_MINUTES = 14 * 24 * 60
export const LAST_30_DAYS_MINUTES = 43140 //30 * 24 * 60

export type VALID_TIME_PERIODS = 60 | 1440 | 10080 | 20160 | 43200

type Props = {
    minutes: number
    onChange: (minutes: number) => void
}

const VALID_TIME_PERIODS_DISPLAY = [
    { minutes: LAST_HOUR_MINUTES, display: '1h', caption: 'Last hour' },
    {
        minutes: LAST_12_HOURS_MINUTES,
        display: '12h',
        caption: 'Last 12 hours',
    },
    { minutes: LAST_DAY_MINUTES, display: '1d', caption: 'Last day' },
    { minutes: LAST_7_DAYS_MINUTES, display: '7d', caption: 'Last 7 days' },
    { minutes: LAST_14_DAYS_MINUTES, display: '14d', caption: 'Last 14 days' },
    { minutes: LAST_30_DAYS_MINUTES, display: '30d', caption: 'Last 30 days' },
]

function RelativeTimePeriod(props: Props) {
    const { minutes, onChange } = props

    function handleClick(minutes: number) {
        onChange(minutes)
    }

    return (
        <div className="text-center text-gray-950 items-center flex ">
            {VALID_TIME_PERIODS_DISPLAY.map((period, index) => {
                let roundedClasses = 'rounded-md'

                let classes =
                    'relative inline-flex items-center px-2 py-2 border-0 bg-white text-sm text-gray-700 hover:bg-gray-100 hover:border-gray-400 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                if (minutes === period.minutes) {
                    classes =
                        'relative inline-flex items-center px-2 py-2 bg-gray-200  border-0 bg-white text-sm hover:bg-gray-100 hover:border-gray-400 text-gray-700  focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                }

                return (
                    <button
                        key={period.display}
                        className={classNames(roundedClasses, classes)}
                        onClick={() => handleClick(period.minutes)}
                    >
                        {period.display}
                    </button>
                )
            })}
        </div>
    )
}
export default RelativeTimePeriod
