import { TrashIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
} from '../../../api/client'
import { isActionApplied } from '../../content/content-card/actions'
import ActionGenericButton from './GenericActionButton'

type Props = {
    content: ContentOutcomeView
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    actions: ContentActionView[]
}

export default function DeleteButton(props: Props) {
    const { content, onActionClick, actions } = props

    const actionAlreadyApplied = isActionApplied(actions, ContentAction.Delete)

    if (actionAlreadyApplied) {
        return (
            <>
                <div
                    data-tip="This content has been deleted. This action cannot be undone."
                    className={`bg-red-700 text-red-200 w-10 rounded-full p-2`}
                >
                    {<TrashIcon />}
                </div>
            </>
        )
    }

    return (
        <ActionGenericButton
            content={content}
            onActionClick={onActionClick}
            action={ContentAction.Delete}
            tooltip="Delete this content"
            colour="red"
            icon={<TrashIcon />}
            actions={actions}
        />
    )
}
