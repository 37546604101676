import { AccountRecord } from '../../../api/client'
import YouTubeAuthorisationButton from './YouTubeAuthorisationButton'

type Props = {
    account: AccountRecord
}
/**
 * Shows the authorisation status of a YouTube account.
 *
 * This type of account is added to Arwen in two steps:
 * 1. Add the account
 * 2. Authorise the account
 *
 * So it might in fact be missing an authorisation row.
 */
function YouTubeAuthorisation(props: Props) {
    const { account } = props

    let alreadyAuthorised = account.hasAuthorisation
    return (
        <>
            {alreadyAuthorised ? (
                <>
                    <p>This profile is authorised.</p>
                    <div className="mt-4">
                        <p>
                            If there is an issue with authorisation you may
                            reauthorise by clicking this button:
                        </p>
                        <div className="mt-4">
                            <YouTubeAuthorisationButton account={account} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        This profile has no authorisation! Please contact
                        Customer Support by emailing{' '}
                        <a
                            href="mailto:support@arwen.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="text-red-700 underline font-bold"
                        >
                            support@arwen.ai
                        </a>
                    </p>
                    <div className="mt-4">
                        <YouTubeAuthorisationButton account={account} />
                    </div>
                </>
            )}
        </>
    )
}

export default YouTubeAuthorisation
