import { AccountRecord } from '../../../api/client'

type Props = {
    account: AccountRecord
}
/**
 * Shows the authorisation status of a TikAPI account.
 *
 * TikAPI accounts and authorisation are all managed manually, and it will
 * always have an authorisation row, whether or not it's valid. If it
 * has no authorisation then someone has _probably_ been messing with
 * the database, and we should advise the user to contact support.
 */
function TikAPIAuthorisation(props: Props) {
    const { account } = props

    let alreadyAuthorised = account.hasAuthorisation
    return (
        <>
            {alreadyAuthorised ? (
                <>
                    <p>This profile is authorised.</p>
                    <div className="mt-4">
                        <p>
                            Re-authorisation for TiKAPI-driven TikTok needs to
                            be done manually. Please contact support.
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        This profile has no authorisation! Please contact
                        support.
                    </p>
                </>
            )}
        </>
    )
}

export default TikAPIAuthorisation
