import * as React from 'react'
import { SyncLoader } from 'react-spinners'

export interface Props {
    loading: boolean
    classNames?: string
}

function SimpleLoader(props: Props) {
    const { loading, classNames } = props

    let classes = 'w-10'
    if (!!classNames) {
        classes = classNames
    }

    if (loading) {
        return (
            <div className="flex justify-center ">
                <SyncLoader color="#38941c" size="4px" className={classes} />
            </div>
        )
    }

    return <div />
}

export default SimpleLoader
