import {
    ProfileView,
    SearchProfilesQueryVariables,
    useCountProfilesQuery,
    useSearchProfilesQuery,
} from '../../api/client'

import {
    PaginationState,
    createColumnHelper,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table'

import React, { useEffect, useState } from 'react'
import SimpleTable from '../../components/table/SimpleTable'
import { fuzzyFilter } from '../../components/table/helpers'
import { toastError } from '../Notification'
import SearchInput from '../SearchInput'
import Spinner from '../Spinner'
import SimplePagination from '../table/SimplePagination'
import CommenterProfileImage from './CommenterProfileImage'

const columnHelper = createColumnHelper<ProfileView>()

const CommenterTable: React.FC = () => {
    const [sortField] = useState<string>('')
    const [sortOrder] = useState<string>('')
    const [globalFilter, setGlobalFilter] = useState('')

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const queryParams: SearchProfilesQueryVariables = {
        limit: pageSize,
        offset: pageIndex,
    }

    if (globalFilter && globalFilter.length > 0) {
        queryParams.filter = { username: globalFilter }
    }

    if (
        sortField &&
        sortField.length > 0 &&
        sortOrder &&
        sortOrder.length > 0
    ) {
        queryParams.sort = {
            field: sortField,
            order: sortOrder,
        }
    }

    const {
        data: searchProfileData,
        loading: searchProfileLoading,
        refetch,
    } = useSearchProfilesQuery({
        variables: queryParams,
        onError: (error) => {
            console.log(error)
            toastError('There was an error fetching commenter data.')
        },
    })

    const {
        data: countProfileData,
        loading: countProfileLoading,
        refetch: countProfileRefetch,
    } = useCountProfilesQuery({
        variables: queryParams,
        onError: (error) => {
            console.log(error)
            toastError('There was an error fetching commenter data.')
        },
    })

    let total: number = countProfileData?.countProfiles || 0

    useEffect(() => {
        refetch()
        countProfileRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter, sortField, sortOrder])

    const profiles = searchProfileData?.searchProfiles || []

    const columns = [
        columnHelper.accessor('profileImageUrl', {
            header: () => 'Profile Image',
            enableSorting: false,
            cell: (info) => {
                return (
                    <span>
                        {info.getValue() && (
                            <div className="w-10 h-10">
                                <CommenterProfileImage
                                    profile={info.row.original}
                                />
                            </div>
                        )}
                    </span>
                )
            },
        }),
        columnHelper.accessor('username', {
            header: () => 'Username',
            enableSorting: false,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('followers', {
            header: () => 'Followers',
            enableSorting: false,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('following', {
            header: () => 'Following',
            enableSorting: false,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('posts', {
            header: () => 'Posts',
            enableSorting: false,
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor('profileUrl', {
            header: () => 'Profile URL',
            enableSorting: false,
            cell: (info) => {
                return (
                    <span>
                        {info.getValue() && info.getValue().length > 0 && (
                            <a
                                href={info.getValue()}
                                target="_blank"
                                className="text-primary-500"
                                rel="noreferrer"
                            >
                                Link
                            </a>
                        )}
                    </span>
                )
            },
        }),
    ]

    const table = useReactTable({
        data: profiles,
        columns: columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        pageCount: Math.ceil(total / pageSize),
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        manualPagination: true,
        debugTable: false,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    return (
        <>
            <div className="m-10 prose prose-stone">
                <div className="flex flex-row items-baseline">
                    <SearchInput
                        keyword={globalFilter ?? ''}
                        onKeywordChange={(value) =>
                            setGlobalFilter(String(value))
                        }
                        onSearch={(value) => setGlobalFilter(String(value))}
                    />

                    <div>
                        {(searchProfileLoading || countProfileLoading) && (
                            <Spinner size={6} />
                        )}
                    </div>
                </div>
            </div>
            <div className="mx-10 prose prose-stone max-w-5xl">
                <SimpleTable table={table} />
                <SimplePagination table={table} />
            </div>
        </>
    )
}

export default CommenterTable
