import { useState } from 'react'
import {
    AccountRecord,
    useUpdateProfileModerationEnabledMutation,
} from '../../api/client'
import Toggle from './Toggle'

const ProfileModerationToggle = ({ account }: { account: AccountRecord }) => {
    const [updateProfileModerationEnabled, { loading }] =
        useUpdateProfileModerationEnabledMutation()

    const [enabled, setEnabled] = useState(account.profileModerationEnabled)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)

        await updateProfileModerationEnabled({
            variables: {
                accountId: account.id,
                profileModerationEnabled: isEnabled,
            },
        })
    }

    return (
        <Toggle
            id={`profile-moderation-enabled-${account.id.toString()}`}
            disabled={false}
            checked={enabled}
            label="Profile moderation enabled (Instagram only)"
            loading={loading}
            onToggle={onToggle}
            tooltip="Enables and disables profile moderation and classification for instagram"
            size="large"
        />
    )
}

export default ProfileModerationToggle
