import { format } from 'date-fns'

type Props = {
    dateTime: Date
    onChange: (dateTime: Date) => void
}

function DateTimePicker(props: Props) {
    const { dateTime, onChange } = props

    // The default date time picker supports only a local version of the ISO standard
    // That is YYY-MM-DDThh:mm:ss.SSS - that is without the Z.
    // If we include the seconds and milliseconds then the picker will also display these which is a confusing interface.
    // So we convert the date to a string in the format YYYY-MM-DDThh:mm
    // This is basically ISO 8601 without the seconds and milliseconds.

    return (
        <input
            type="datetime-local"
            id="dateTime"
            name="dateTime"
            value={format(dateTime, `yyyy-MM-dd'T'HH:mm`)}
            className=" text-sm hover:bg-gray-100  border-0 hover:border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
            onChange={(event) => {
                if (!event.target['validity'].valid) return
                const dt = event.target['value']
                onChange(new Date(dt))
            }}
        ></input>
    )
}

export default DateTimePicker
