import { ClockIcon } from '@heroicons/react/24/solid'
import ActionLoading from '../ActionLoading'

type Props = {
    applied?: boolean
    tooltip: string
    loading: boolean
    colour: string
    actionAlreadyApplied: boolean
    icon: JSX.Element
    onActionClick: () => void
    isActionQueued: boolean
}

/**
 * This component contains all generic behavior and displays for the actions
 * buttons.
 */
export default function BaseActionButton(props: Props) {
    const {
        tooltip,
        loading,
        colour,
        actionAlreadyApplied,
        onActionClick,
        icon,
        isActionQueued,
    } = props

    // Determine how this button should be displayed based on whether it is queued or has already been applied.
    let selectedClass: 'queued' | 'selected' | 'not-selected'

    if (isActionQueued) {
        selectedClass = 'queued'
    } else if (actionAlreadyApplied) {
        selectedClass = 'selected'
    } else {
        selectedClass = 'not-selected'
    }

    if (loading) {
        return <ActionLoading icon={icon} />
    }

    return (
        <>
            <button
                data-tip={tooltip}
                className={`btn-icon transition duration-300 ease-in-out btn-icon-${selectedClass}-${colour} `}
                type="button"
                onClick={() => onActionClick()}
            >
                <div className="relative">{icon}</div>
            </button>
            {isActionQueued ? (
                <div className="absolute bottom-5 left-8 text-gray-700 ">
                    <ClockIcon className="h-6" />
                </div>
            ) : null}
        </>
    )
}
