export default function ContentSkeleton() {
    return (
        <div className="border-gray-300 border-solid  border-b  pr-5 flex animate-pulse flex-row items-stretch content-center h-full w-full justify-center space-x-5">
            <div className="w-12 bg-gray-300 h-36 rounded-sm"></div>
            <div className="flex flex-col space-y-3 flex-grow pt-6">
                <div className="justify-between flex flex-row">
                    <div className="w-48 bg-gray-300 h-5 rounded-md "></div>
                    <div className="w-48 bg-gray-300 h-5 rounded-md "></div>
                </div>
                <div className="flex flex-row ">
                    <div className="flex flex-col flex-grow pr-48">
                        <div className=" bg-gray-300 h-5 rounded-md "></div>
                        <div className="mt-4 bg-gray-300 h-5 rounded-md "></div>
                    </div>
                    <div className="w-48  h-5 rounded-md "></div>
                </div>
            </div>
        </div>
    )
}
