import { useEffect } from 'react'
import { useGetCustomerPaymentPortalUrlQuery } from '../api/client'
import SimpleLoader from '../components/SimpleLoader'

function CustomerPaymentPortalPage() {
    const { data, error } = useGetCustomerPaymentPortalUrlQuery({
        variables: { returnUrl: `${window.location.origin}` },
    })

    if (error) {
        throw new Error(`Unable to reach Customer Payment Portal, error`)
    }

    useEffect(() => {
        if (
            data?.getCustomerPaymentPortalUrl?.url &&
            data?.getCustomerPaymentPortalUrl?.url?.length > 0
        )
            window.location.href = data.getCustomerPaymentPortalUrl.url!
    }, [data])

    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
                <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-950 sm:text-4xl">
                    <span className="flex flex-col">
                        <div className="pb-20">
                            Loading the Stripe customer portal...
                        </div>
                        <SimpleLoader loading={true} />
                    </span>
                </h2>
            </div>
        </div>
    )
}

export default CustomerPaymentPortalPage
