import { Input } from '@headlessui/react'
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/24/solid'
import { useFormContext, Controller } from 'react-hook-form'
import { classNames } from '../../util/classNames'

type Props = {
    name: string
    isLoading: boolean
    /**
     * The minimum length of the input. Won't display validation until this length is reached.
     */
    minimumLength?: number
    /**
     * Icon to display on the left of the input. Optional.
     */
    leadingIcon?: React.ReactNode
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    error?: any // Adjust the type as needed based on react-hook-form's error type
}

/**
 * An input to use in a form, displaying a loading spinner or a checkmark or cross depending
 * on whether or not it's valid.
 *
 * Does not dictate when you validate the input, only how it's displayed.
 *
 * To make this invalid you need to set the appropriate error on the form. So if the `name`
 * of this input is `username`, you would set the error like this:
 * ```ts
 * form.setError('username', {
 *   type: 'errorType',
 *   message: 'Your username is stupid and I will not accept it',
 * })
 * ```
 *
 * Note: this component uses `useFormContext` to get the state and form control objects.
 * To it relies on being nested within a `FormProvider` component. That's going to look
 * like this in the enclosing component, and is easy to add:
 * ```tsx
 * <FormProvider {...form}>
 *   <form onSubmit={form.handleSubmit(onSubmit)}>
 *     <ValidatingInput name="username" isLoading={loadingTwitterDetails} />
 *     [... other inputs and validation messages ... ]
 *   </form>
 * </FormProvider>
 * ```
 */
function ValidatingInput({
    name,
    isLoading,
    leadingIcon,
    minimumLength,
    onChange,
    error,
}: Props) {
    const { control } = useFormContext()
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
                <div className="relative mt-2 rounded-md shadow-sm w-80">
                    {leadingIcon && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            {leadingIcon}
                        </div>
                    )}
                    <Input
                        {...field}
                        type="text"
                        placeholder="Enter username"
                        className={classNames(
                            leadingIcon ? 'pl-10' : '',
                            'block w-full text-gray-900 placeholder:text-gray-400 focus:ring-primary-500 focus:border-primary-500 rounded-md shadow-sm'
                        )}
                        onChange={onChange}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {field.value.length >= (minimumLength ?? 0) && (
                            <>
                                {error ? (
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                ) : (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500" />
                                )}
                            </>
                        )}
                        {isLoading && (
                            <svg
                                className="animate-spin h-5 w-5 text-blue-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        )}
                    </div>
                </div>
            )}
        />
    )
}

export default ValidatingInput
