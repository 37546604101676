type Props = {
    message?: string
}
export default function GenericErrorMessage(props: Props) {
    const { message } = props
    return (
        <main className="sm:flex prose prose-stone prose-lg">
            <p className="text-4xl font-extrabold text-red-700 sm:text-5xl">
                Error
            </p>
            <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                    <h1 className="text-4xl font-extrabold text-gray-950 tracking-tight sm:text-5xl">
                        {message ? message : 'Something went wrong'}
                    </h1>
                    <p className="">Please reload and try again.</p>
                    <p>
                        If this problem persists please contact Customer Support
                        by emailing{' '}
                        <a
                            href="mailto:support@arwen.ai"
                            target="_blank"
                            rel="noreferrer"
                            className="text-red-700 underline font-bold"
                        >
                            support@arwen.ai
                        </a>
                    </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                    <a
                        href="/"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm  rounded-md shadow-sm text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        Home
                    </a>
                </div>
            </div>
        </main>
    )
}
