import { DialogPanel, DialogTitle } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { clsx } from 'clsx'
import { ContentOutcomeView, EnrichedContent } from '../../../../api/client'
import CloseButton from '../../../CloseButton'
import SimpleModal from '../../../SimpleModal'

import ContentMetadataAuthor from './ContentMetadataAuthor'
import ContentMetadataDetails from './ContentMetadataDetails'
import ContentMetadataPostDetails from './ContentMetadataPostDetails'

type Props = {
    open: boolean
    setOpen: (open: boolean) => void
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
}

type RowProps = {
    title: string
    children: React.ReactNode
    zebra_dark: boolean
}

/**
 * A single, zebra-striped row in the content metadata modal.
 * Just a place to put all the CSS really.
 *
 * Use like this:
 * ```
 *   <ContentMetadataRow
 *      title="Account ID:"
 *      zebra_dark={true}
 *      children={<ProfileId content={content} />}
 *  />
 * ```
 */
export function ContentMetadataRow({ title, children, zebra_dark }: RowProps) {
    const rowColour = zebra_dark ? 'bg-gray-50' : 'bg-white'
    return (
        <div
            className={`${rowColour} px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0`}
        >
            <dt className="text-sm font-medium leading-6 text-gray-900">
                {title}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {children}
            </dd>
        </div>
    )
}

export function SectionHeader({ title }: { title: string }) {
    return (
        <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7">{title}</h3>
        </div>
    )
}

/**
 * Modal that display content details.
 */
export default function ContentMetadataModal(props: Props) {
    const { open, setOpen, content, enrichedContent } = props

    return (
        <SimpleModal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogPanel
                className={clsx(
                    'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300',
                    'min-w-min max-w-4xl sm:w-fit'
                )}
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 mb-6">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle
                                as="h1"
                                className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                            >
                                <div className="flex flex-row w-full">
                                    <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                                        <InformationCircleIcon
                                            className="h-8 w-8 text-gray-700"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-2 ml-6">
                                        All content details
                                    </div>
                                    <div className="ml-auto">
                                        <CloseButton
                                            onClick={() => setOpen(false)}
                                        />
                                    </div>
                                </div>
                            </DialogTitle>

                            <div className="mt-8 mb-8 ml-2 mr-6 overflow-y-scroll max-h-[50vh] min-w-[750px] space-y-4">
                                <ContentMetadataAuthor content={content} />
                                <ContentMetadataDetails
                                    enrichedContent={enrichedContent}
                                    content={content}
                                />
                                <ContentMetadataPostDetails content={content} />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="block bg-white border-2 rounded-md shadow-sm text-base hover:text-gray-950 ml-auto px-4 py-1 mr-6"
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </button>
                </div>
            </DialogPanel>
        </SimpleModal>
    )
}
