import { DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import {
    useCreateUserMutation,
    useDeleteUserMutation,
    useEditUserMutation,
    useGetUserTeamsQuery,
} from '../../api/client'
import TeamSelectMenu from '../../components/team/TeamSelectMenu'
import { UserAdminView } from '../../models/User'
import Button from '../Button'
import { ComboboxMenuOption } from '../inputs/ComboboxMenuOption'
import { toastError, toastSuccess } from '../Notification'
import SimpleLoader from '../SimpleLoader'
import SimpleModal from '../SimpleModal'

interface IFormInput {
    username: string
    admin?: boolean
    canLikeContent?: boolean
    canReplyToContent?: boolean
    canViewIntelligencePage?: boolean
    dashboardEnabled: boolean
    dashboardId?: string
    allowAccessOverride: boolean
    selectedTeam?: { id: number; name: string }
    selectedTeams?: { id: number; name: string }[]
}

type Props = {
    user?: UserAdminView
}

/**
 * This component is used to create or edit a user.
 *
 * This page uses the react-hook-form to create a new user.
 * https://www.react-hook-form.com/get-started/
 */
function UserDetailsForm(props: Props) {
    const { user } = props

    let navigate = useNavigate()
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false)
    const [selectedTeams, setSelectedTeams] = useState<ComboboxMenuOption[]>([])

    const [createUserMutation, { loading: createUserLoading }] =
        useCreateUserMutation()

    const [editUserMutation, { loading: editUserLoading }] =
        useEditUserMutation()

    const [deleteUserMutation, { loading: deleteUserLoading }] =
        useDeleteUserMutation({
            onError: (error) => {
                toastError(error.message)
            },
        })

    const { loading: userTeamsLoading, refetch: refetchUserTeams } =
        useGetUserTeamsQuery({
            variables: { userId: user?.id || 0 },
            skip: !user,
            onCompleted: (data) => {
                setSelectedTeams(
                    data.getUserTeams?.map((team) => ({
                        id: team.id,
                        name: team.name,
                    })) || []
                )
            },
        })

    // Are we creating a new user or editing an existing one?
    const isCreateMode = !!!user

    const values = user
        ? {
              ...user,
              canViewIntelligencePage: user.intelligencePageViewer,
          }
        : undefined

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
    } = useForm<IFormInput>({
        defaultValues: { allowAccessOverride: true },
        values,
    })

    const handleCreate: SubmitHandler<IFormInput> = async (data) => {
        await createUserMutation({
            variables: {
                userParams: {
                    emailAddress: data.username,
                    admin: data.admin ? data.admin : false,
                    canLike: data.canLikeContent ? data.canLikeContent : false,
                    canReplyToContent: data.canReplyToContent
                        ? data.canReplyToContent
                        : false,
                    canViewIntelligencePage: data.canViewIntelligencePage
                        ? data.canViewIntelligencePage
                        : false,
                    dashboardEnabled: data.dashboardEnabled,
                    dashboardId: data.dashboardId,
                    allowAccessOverride: data.allowAccessOverride,
                    selectedTeam: data.selectedTeam?.id,
                    selectedTeams: selectedTeams.map((team) => team.id),
                },
            },
            onCompleted: () => {
                toastSuccess(
                    `User ${data.username} created. They have been sent an email with a temporary password which they will be required to reset.`
                )
                refetchUserTeams()
            },
            onError: (error) => {
                console.error(error)
                toastError(`Unable to create a user. ${error.message}`)
            },
        })
    }

    const handleEdit: SubmitHandler<IFormInput> = async (data) => {
        if (user?.id) {
            await editUserMutation({
                variables: {
                    userParams: {
                        id: user?.id,
                        emailAddress: data.username,
                        admin: data.admin ? data.admin : false,
                        canLike: data.canLikeContent
                            ? data.canLikeContent
                            : false,
                        canReplyToContent: data.canReplyToContent
                            ? data.canReplyToContent
                            : false,
                        canViewIntelligencePage: data.canViewIntelligencePage
                            ? data.canViewIntelligencePage
                            : false,

                        dashboardEnabled: data.dashboardEnabled,
                        dashboardId: data.dashboardId,
                        allowAccessOverride: data.allowAccessOverride,
                        selectedTeam: data.selectedTeam?.id,
                        selectedTeams: selectedTeams.map((team) => team.id),
                    },
                },
                onCompleted: () => {
                    toastSuccess(`User ${data.username} edited successfully.`)
                    refetchUserTeams()
                },
                onError: (error) => {
                    console.error(error)
                    toastError(`Unable to edit a user. ${error.message}`)
                },
            })
        } else {
            console.error(
                "The user or the id is undefined - cannot call edit mutation. This shouldn't happen."
            )
        }
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        if (isCreateMode) {
            await handleCreate(data)
        } else {
            await handleEdit(data)
        }
    }

    const onDeleteUser = async () => {
        if (user) {
            await deleteUserMutation({
                variables: {
                    userId: user.id,
                },
                onCompleted: () => {
                    toastSuccess(`User ${user.username} deleted successfully.`)

                    navigate(-1)
                },
                onError: (error) => {
                    console.error(error)
                    toastError(`Unable to delete the user. ${error.message}`)
                },
            })
        }
    }

    const handleSelectedTeamChange = (selectedTeams: ComboboxMenuOption[]) => {
        setSelectedTeams(selectedTeams)
        setValue('selectedTeams', selectedTeams, { shouldDirty: true })
    }

    return (
        <div className="m-10 prose prose-stone">
            <h2 className="text-gray-950 initial">
                {`${isCreateMode ? 'Create a new User' : 'Edit a User'}`}
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-8">
                    <div>
                        <label
                            htmlFor="username"
                            className="block  leading-6 text-gray-700"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                type="email"
                                id="username"
                                disabled={!isCreateMode}
                                {...register('username', {
                                    required: true,
                                    minLength: 3,
                                })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                            />
                        </div>
                        {errors.username?.type === 'required' && (
                            <p role="alert" className="mb-0">
                                Email address is required
                            </p>
                        )}
                    </div>

                    <div>
                        {userTeamsLoading && <SimpleLoader loading={true} />}
                        {!userTeamsLoading && (
                            <TeamSelectMenu
                                selectedTeams={selectedTeams}
                                setSelectedTeams={handleSelectedTeamChange}
                            />
                        )}
                    </div>

                    {/* We are removing this option to set admin status. The risk of accidentally giving a user admin status is too high. */}
                    {isCreateMode ? null : (
                        <div className="mt-2">
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        disabled={true}
                                        id="admin"
                                        aria-describedby="admin-description"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600 cursor-not-allowed opacity-50"
                                        {...register('admin', {})}
                                    />
                                </div>
                                <div className="ml-3 leading-6">
                                    <label
                                        htmlFor="Admin"
                                        className=" text-gray-950 opacity-50"
                                    >
                                        Admin user
                                    </label>
                                    <p
                                        id="admin-description"
                                        className="mt-1 mb-0 text-gray-500 text-sm"
                                    >
                                        Admin users get access to all profiles
                                        in Arwen. If you would like to make a
                                        user an admin please contact Customer
                                        Success.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div>
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id="allowAccessOverride"
                                    aria-describedby="allowAccessOverride-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600"
                                    {...register('allowAccessOverride', {})}
                                />
                            </div>
                            <div className="ml-3 leading-6">
                                <label
                                    htmlFor="allowAccessOverride"
                                    className=" text-gray-950"
                                >
                                    Allow access without Stripe subscription?
                                </label>
                                <p
                                    id="allowAccessOverride-description"
                                    className="mt-1 mb-0 text-gray-500 text-sm"
                                >
                                    This allows a user access to Arwen without
                                    requiring a Stripe subscription. Users only
                                    need a Stripe subscription if they want to
                                    pay by credit card. For almost all our users
                                    this should be enabled. If in doubt ask
                                    Customer Success.
                                </p>
                            </div>
                        </div>
                    </div>

                    <h3 className="text-gray-950 initial">
                        Feature permissions
                    </h3>
                    <div>
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id="canLikeContent"
                                    aria-describedby="canLike-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600"
                                    {...register('canLikeContent', {})}
                                />
                            </div>
                            <div className="ml-3 leading-6">
                                <label
                                    htmlFor="canLikeContent"
                                    className=" text-gray-950"
                                >
                                    Like content
                                </label>
                                <p
                                    id="canLike-description"
                                    className="mt-1 mb-0 text-gray-500 text-sm"
                                >
                                    Currently only implemented for Facebook this
                                    allows a user to like content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id="canReplyToContent"
                                    aria-describedby="canLike-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600"
                                    {...register('canReplyToContent', {})}
                                />
                            </div>
                            <div className="ml-3 leading-6">
                                <label
                                    htmlFor="canReplyToContent"
                                    className=" text-gray-950"
                                >
                                    Reply to content
                                </label>
                                <p
                                    id="canLike-description"
                                    className="mt-1 mb-0 text-gray-500 text-sm"
                                >
                                    Currently only implemented for Meta this
                                    allows a user to reply to content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id="canViewIntelligencePage"
                                    aria-describedby="canLike-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600"
                                    {...register('canViewIntelligencePage', {})}
                                />
                            </div>
                            <div className="ml-3 leading-6">
                                <label
                                    htmlFor="canViewIntelligencePage"
                                    className=" text-gray-950"
                                >
                                    Engage page
                                </label>
                                <p
                                    id="canLike-description"
                                    className="mt-1 mb-0 text-gray-500 text-sm"
                                >
                                    Allows this user to see and use the Engage
                                    page
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="relative flex items-start">
                            <div className="flex items-center">
                                <input
                                    id="dashboardEnabled"
                                    aria-describedby="dashboardEnabled-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-600"
                                    {...register('dashboardEnabled', {})}
                                />
                            </div>
                            <div className="ml-3 leading-6">
                                <label
                                    htmlFor="dashboardEnabled"
                                    className=" text-gray-950"
                                >
                                    In-app report
                                </label>
                                <p
                                    id="dashboardEnabled-description"
                                    className="mt-1 mb-0 text-gray-500 text-sm"
                                >
                                    This lets the user see the in-app report for
                                    their team.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="email"
                            className="block  leading-6 text-gray-700"
                        >
                            Custom in-app report
                        </label>
                        <div className="mt-1 mb-0">
                            <input
                                type="text"
                                id="dashboardId"
                                {...register('dashboardId', {
                                    required: false,
                                    minLength: 6,
                                })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                            />
                        </div>
                        <p
                            id="customReport-description"
                            className="mt-1 mb-0 text-gray-500 text-sm"
                        >
                            If this user has a custom Tableau report then put
                            the id of the report here. This will override the
                            default report. Almost all of our users have the
                            default report. If in doubt ask Customer Success.
                        </p>
                    </div>

                    <div className="items-center flex flex-row mt-8">
                        {createUserLoading || editUserLoading ? (
                            <div>Saving...</div>
                        ) : (
                            <input
                                disabled={!isDirty}
                                type="submit"
                                value={'Save'}
                                className={clsx(
                                    'text-base inline-flex items-center px-3 py-2 ',
                                    'leading-4 rounded-md border border-transparent shadow-sm',
                                    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ',
                                    isDirty
                                        ? 'text-white bg-primary hover:bg-primary-700 cursor-pointer'
                                        : 'text-white bg-gray-700 hover:bg-gray-700 cursor-not-allowed'
                                )}
                            />
                        )}

                        <button
                            type="reset"
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="py-2 px-6 underline"
                        >
                            Cancel
                        </button>

                        {isCreateMode ? null : (
                            <Button
                                primary={false}
                                text="Delete user account"
                                onClick={() => setDeleteUserModalOpen(true)}
                            ></Button>
                        )}
                    </div>

                    {isCreateMode ? null : (
                        <>
                            <SimpleModal
                                open={deleteUserModalOpen}
                                onClose={() => {
                                    // No action necessary here, modal redirects back to list on delete.
                                }}
                            >
                                <DialogPanel className=" border-primary700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon
                                                    className="h-6 w-6 text-red-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <DialogTitle
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-950"
                                                >
                                                    Delete user account
                                                </DialogTitle>
                                                <div className="mt-2 space-y-4">
                                                    <p className="text-sm text-gray-500">
                                                        Are you sure you want to
                                                        delete this account?
                                                        This action cannot be
                                                        undone.
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        All of the users
                                                        profiles will be deleted
                                                        as well as their
                                                        content.
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        If you want to limit
                                                        this user's access
                                                        consider disabling them
                                                        by contact Customer
                                                        Success.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:px-6 space-x-6">
                                        <Button
                                            primary={true}
                                            disabled={deleteUserLoading}
                                            onClick={onDeleteUser}
                                            text="Delete"
                                        />

                                        <Button
                                            primary={false}
                                            onClick={() =>
                                                setDeleteUserModalOpen(false)
                                            }
                                            text="Cancel"
                                        />

                                        {deleteUserLoading ? (
                                            <div className="mx-4">
                                                <SyncLoader
                                                    loading={true}
                                                    color="#CF3C28"
                                                    size={'4px'}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </DialogPanel>
                            </SimpleModal>
                        </>
                    )}
                </div>
            </form>
        </div>
    )
}

export default UserDetailsForm
