import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AccountRecord, useGetAccountQuery } from '../api/client'
import SimpleLoader from '../components/SimpleLoader'
import { useSetRecoilState } from 'recoil'
import { selectedAccountState } from '../store/UIStore'
import { useTitle } from '../components/TitleProvider'
import FacebookAuthorisation from '../components/authorise/platforms/FacebookAuthorisation'
import InstagramAuthorisation from '../components/authorise/platforms/InstagramAuthorisation'
import TikAPIAuthorisation from '../components/authorise/platforms/TikAPIAuthorisation'
import TikTokAuthorisation from '../components/authorise/platforms/TikTokAuthorisation'
import TwitterAuthorisation from '../components/authorise/platforms/TwitterAuthorisation'
import YouTubeAuthorisation from '../components/authorise/platforms/YouTubeAuthorisation'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

type ParamTypes = {
    accountId: string
}

/**
 * Shows the authorisation status for a given account.
 */
function AccountAuthorisationPage() {
    const setSelectedAccount = useSetRecoilState(selectedAccountState)
    const [account, setAccount] = useState<AccountRecord | null>(null)
    const { accountId } = useParams<ParamTypes>()

    const {
        data: accountData,
        loading,
        error,
    } = useGetAccountQuery({
        variables: { accountId: parseInt(accountId as string) },
    })

    let titleAccountName: string =
        accountData?.getAccount?.accountName || 'Account'
    let titlePlatformName: string | undefined =
        accountData?.getAccount?.socialMediaServiceDisplayName || undefined
    titlePlatformName = titlePlatformName ? `(${titlePlatformName})` : ''
    useTitle(`${titleAccountName} ${titlePlatformName} - Authorisation`)

    useEffect(() => {
        if (accountData?.getAccount) {
            setAccount(accountData.getAccount)
            setSelectedAccount(accountData.getAccount)
        } else {
            setAccount(null)
        }
    }, [accountData, setSelectedAccount])

    if (loading) return <SimpleLoader loading={loading} />
    if (error) return <p>Error: {error.message}</p>
    if (!account) return <p>No account data found or account is undefined.</p>

    let platformSpecificAuth = <></>
    switch (account.socialMediaServiceShortName?.toLowerCase()) {
        case 'youtube':
            platformSpecificAuth = <YouTubeAuthorisation account={account} />
            break
        case 'twitter':
            platformSpecificAuth = <TwitterAuthorisation account={account} />
            break
        case 'facebook':
            platformSpecificAuth = <FacebookAuthorisation account={account} />
            break
        case 'instagram':
            platformSpecificAuth = <InstagramAuthorisation account={account} />
            break
        case 'tiktok':
            platformSpecificAuth = <TikTokAuthorisation account={account} />
            break
        case 'tikapi':
            platformSpecificAuth = <TikAPIAuthorisation account={account} />
            break
        default:
            platformSpecificAuth = (
                <p>
                    This particular function is not yet supported for
                    <em>{account.socialMediaServiceDisplayName}</em>. Please
                    contact support.
                </p>
            )
            console.error('Unknown social media service!')
    }
    return (
        <TextPageContainer>
            <TextPageHeader title="Authorisation status" />
            {platformSpecificAuth}
        </TextPageContainer>
    )
}

export default AccountAuthorisationPage
