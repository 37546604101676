import { ArrowRightIcon } from '@heroicons/react/24/solid'
import DateTimePicker from './DateTimePicker'
import RelativeTimePeriod from './RelativeTimePeriod'
import { differenceInMinutes, sub } from 'date-fns'

type Props = {
    startDateTime: Date
    setStartDateTime: (startDateTime: Date) => void
    endDateTime: Date
    setEndDateTime: (endDateTime: Date) => void
    onDateRangeChange: (startDateTime: Date, endDateTime: Date) => void
}

function DateTimePickerContainer(props: Props) {
    const {
        startDateTime,
        setStartDateTime,
        endDateTime,
        setEndDateTime,
        onDateRangeChange,
    } = props
    return (
        <>
            <DateTimePicker
                dateTime={startDateTime}
                onChange={setStartDateTime}
            />
            <div className="font-normal self-center px-3">
                <ArrowRightIcon
                    className="w-5 h-5 text-gray-700"
                    aria-hidden="true"
                />
            </div>
            <DateTimePicker dateTime={endDateTime} onChange={setEndDateTime} />
            <div className="ml-2">
                <RelativeTimePeriod
                    minutes={differenceInMinutes(endDateTime, startDateTime)}
                    onChange={(timePeriodMinutes) => {
                        onDateRangeChange(
                            sub(new Date(), {
                                minutes: timePeriodMinutes,
                            }),
                            new Date()
                        )
                    }}
                />
            </div>
        </>
    )
}

export default DateTimePickerContainer
