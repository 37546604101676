type Props = {
    icon: JSX.Element
}

/**
 * Loading indicator for actions.
 */
export default function ActionLoading(props: Props) {
    const { icon } = props
    return (
        <div className="h-10 w-10">
            <div className={`btn-icon relative text-gray-400`}>{icon}</div>
            <div className="absolute left-0 top-0">
                <div
                    className={`inline-block h-10 w-10 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>
        </div>
    )
}
