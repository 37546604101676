import { SyncLoader } from 'react-spinners'
import { AccountRecord, useMetaHistoricFetchMutation } from '../../api/client'
import { subHours } from 'date-fns'
import Button from '../Button'

export default function MetaFetchHistoricButton({
    account,
}: {
    account: AccountRecord
}) {
    // The GraphQL endpoint can take two arbitrary dates. But this is a super-simple
    // fetch button that only does the last 24 hours. So we're going to hard-code
    // that here.
    const to = new Date()
    const from = subHours(to, 24)

    const [metaHistoricFetchMutation, { loading }] =
        useMetaHistoricFetchMutation({
            variables: {
                accountId: account.id,
                from,
                to,
            },
        })

    return (
        <Button
            primary={false}
            text={
                <>
                    {loading && <SyncLoader color="#BF4833" size="4px" />}
                    {!loading && <div>Fetch historic meta 24hrs</div>}
                </>
            }
            onClick={() => metaHistoricFetchMutation()}
            disabled={loading}
        ></Button>
    )
}
