import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useRecoilState } from 'recoil'
import {
    AdsOrOrganicFilterView,
    ContentActionView,
    ContentOutcomeView,
    EnrichedContent,
    InputMaybe,
    SearchParamsForContentOutcome,
    SpamSearchResult,
    TranslatedTextView,
    useEnrichContentQuery,
    useGetProfilesByIdsQuery,
    useSearchContentOutcomeCountQuery,
    useSearchContentOutcomeQuery,
    useTranslateQuery,
} from '../../api/client'
import { targetLanguageState } from '../../store/UIStore'
import { toastError } from '../Notification'
import Pagination from '../Pagination'
import NoResults from './NoResults'
import QueryFailed from './QueryFailed'
import SelectedContentBar from './SelectedContentBar'
import ContentCard from './content-card/ContentCard'
import ContentSkeleton from './content-card/ContentSkeleton'
import { SearchParameters } from './search/ContentSearchBar'
import mixpanel from 'mixpanel-browser'

type Props = {
    searchParameters: SearchParameters
    showOwningAccount: boolean
    enableBulkActions: boolean
    scores?: SpamSearchResult[]
}

function ContentList(props: Props) {
    const { searchParameters, showOwningAccount, enableBulkActions, scores } =
        props
    const [targetLanguage] = useRecoilState(targetLanguageState)

    // Establish offset and limit
    const [offset, setOffset] = useState<number>(0)
    const DEFAULT_LIMIT = 20
    const [limit, setLimit] = useState(DEFAULT_LIMIT)

    // We have an action cache as actions we can change the actions for an item of content
    // without reloading that content. This means we need to maintain it as state.
    const [actionCache, setActionCache] = useState<
        Map<number, ContentActionView[]>
    >(new Map<number, ContentActionView[]>())

    const [selectedContent, setSelectedContent] = useState<
        Map<number, ContentOutcomeView>
    >(new Map<number, ContentOutcomeView>())

    const selectedSocialMediaServiceIds =
        searchParameters.selectedSocialMediaServices.map(
            (service) => service.id
        )

    // Convert to a map to make lookup faster
    const scoresMap = new Map<number, number>()
    if (scores) {
        scores.forEach((score) => {
            if (
                score.contentId !== undefined &&
                score.similarityScore !== undefined
            ) {
                //@ts-ignore - compile throwing a wobbly saying contentId might be undefined.
                scoresMap.set(score.contentId, score.similarityScore)
            }
        })
    }

    /** ******************************************************
    SEARCH CONTENT
    */

    const searchParamsCount: SearchParamsForContentOutcome = {
        accountIds: searchParameters.selectedAccountIds,
        teamIds: searchParameters.selectedTeams,
        moderationString: [searchParameters.moderation?.toLowerCase()],
        since: searchParameters.startDateTime,
        before: searchParameters.endDateTime,
        socialMediaServiceIds: selectedSocialMediaServiceIds,
        containsText: searchParameters.keyword,
        classifierSearchParams: searchParameters.selectedClassifiers,
        adsOrOrganic: searchParameters.adOrOrganic as
            | InputMaybe<AdsOrOrganicFilterView>
            | undefined,
        // If there are any contentIds here only those specified will be returned. This essentially limits the search to these items.
        contentIds: searchParameters.contentIds,
        owned: searchParameters.owned,
    }

    const searchParams = cloneDeep(searchParamsCount)
    searchParams.maxResults = limit
    searchParams.skipResults = offset

    const { data, loading, error } = useSearchContentOutcomeQuery({
        fetchPolicy: 'no-cache',
        variables: {
            params: searchParams,
        },
        onError: (error) => {
            // We don't need to put up a toast here - there will be an error message displayed where the content should be.
            console.error("Couldn't fetch content", error)
        },
    })

    const { data: countData, loading: countLoading } =
        useSearchContentOutcomeCountQuery({
            fetchPolicy: 'no-cache',
            variables: {
                params: searchParamsCount,
            },
            onError: (error) => {
                console.error("Couldn't fetch content count", error)
                toastError('There was an error fetching pagination data.')
            },
        })

    // This is used by the profile, enricher and translator queries
    const contents = (data?.searchContentOutcome || []) as ContentOutcomeView[]
    const total = countData?.searchContentOutcomeCount || 0

    /** ******************************************************
    GET PROFILES 
    */

    // Fetch the profiles
    const {
        data: profileData,
        // Errors handled by the error callback below. Loading display is via the default avatar profile image - it simply gets replaced if/when an image is loaded.
    } = useGetProfilesByIdsQuery({
        variables: {
            profileIds: contents.map(
                (content) => content.authorProfileId || -1
            ),
        },

        onError: (error) => {
            console.error('There was an error fetching profiles', error)
            toastError('There was an error fetching profiles.')
        },
    })

    const profiles = profileData?.getProfilesByIds || []

    /** ******************************************************
    ENRICH CONTENT - this fetches additional data from the social media services
    */

    const contentsToEnrich = contents.map((content) => {
        return {
            // Mandatory fields required to perform enrichment
            id: content.id,
            accountId: content.account.id,
            socialMediaServiceShortName: content.socialMediaServiceName,

            // Fields that might need enriching
            serviceContentId: content.serviceContentId,
            serviceAuthorId: content.serviceAuthorId,
            serviceAuthorName: content.serviceAuthorName,
            serviceAuthorUsername: content.serviceAuthorDisplayUsername,
            originalPostLinkId: content.socialMediaContentLink,
            originalPostId: content.originalPostId,
        }
    })

    // We need to now enrich the content
    const {
        data: enrichedContentResults,
        loading: loadingEnrichedContentResults,
    } = useEnrichContentQuery({
        variables: {
            contents: contentsToEnrich,
        },
        onError: (error) => {
            console.error('There was an error connecting to the server', error)
            toastError('There was an error connecting to the server. ')
        },
    })

    const enrichedContents = enrichedContentResults?.enrichContent || []

    /** ******************************************************
    TRANSLATE CONTENT
    */

    // Perform the translation
    const { data: translatedData, loading: translateLoading } =
        useTranslateQuery({
            variables: {
                sourceTexts: contents.map((content) => content.content),
                targetLanguage: targetLanguage,
            },
            onError: (error) => {
                console.error(
                    'There was an error connecting to the server',
                    error
                )
                toastError('There was an error connecting to the server. ')
            },
        })

    const translations = translatedData?.translate || []

    /** ******************************************************
     */
    function resetSearch() {
        setOffset(0)
    }

    useEffect(() => {
        // If any of these parameters change then we need clear the search cache and offset and fetch again.
        resetSearch()
        // eslint complaining that resetSearch is not in this list:
        //  - resetSearch is a function and doesn't need to be
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParameters.keyword,
        searchParameters.moderation,
        searchParameters.startDateTime,
        searchParameters.endDateTime,
        searchParameters.selectedClassifiers,
        searchParameters.account,
        //FIXME: we probably need to reset when any of these parameters change.
    ])

    useEffect(() => {
        // We need to add these to the actions cache
        // Identify the new actions
        const newActions = new Map<number, ContentActionView[]>(
            data?.searchContentOutcome.map((content) => [
                content.id,
                content.actions,
            ])
        )
        // // Set the action cache
        setActionCache(newActions)

        // Disabling this warning as it asks for contents - however this
        // effect modifies content which would mean an infinite loop if included in the
        // dependency array.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        // The tooltip function we use doesn't have good support for modals or infinite scroll.
        // We force the tooltips to be rebuilt after every render
        ReactTooltip.rebuild()
    })

    const handleContentAction = function (actions: ContentActionView[]) {
        // Lets make sure we track these actions in our product analytics tool
        for (const action of actions) {
            mixpanel.track(
                `Content action - ${action.action} - ${
                    action.active ? 'on' : 'off'
                }`,
                { action }
            )
        }

        const contentIdsToUpdate = actions.map((a) => a.contentId)

        // This action has already been performed by the button but lets update the cache to reflect this.
        for (const contentId of contentIdsToUpdate) {
            const contentActions = actionCache.get(contentId) || []

            // Update the content based on the current action
            const updatedActions = cloneDeep(contentActions)

            const newActions = actions.filter((a) => (a.contentId = contentId))

            for (const newAction of newActions) {
                // Does this action already exist?
                const indexOfExistingAction: number = updatedActions.findIndex(
                    (existingAction) => existingAction.id === newAction.id
                )

                if (indexOfExistingAction >= 0) {
                    // ... it does - toggle the action
                    updatedActions[indexOfExistingAction].active =
                        !updatedActions[indexOfExistingAction].active
                } else {
                    updatedActions.push(newAction)
                }
            }
            const modifiedContentActions = new Map<
                number,
                ContentActionView[]
            >()
            modifiedContentActions.set(contentId, updatedActions)

            setActionCache((actionCacheState) => {
                const updatedActionCache = new Map<number, ContentActionView[]>(
                    [
                        ...Array.from(actionCacheState.entries()),
                        ...Array.from(modifiedContentActions.entries()),
                    ]
                )
                return updatedActionCache
            })
        }
    }

    // The key here is the content id - we convert to a map as it is a faster lookup.
    const enrichedContentMap = new Map<number, EnrichedContent>(
        enrichedContents.map((enrichedContent) => [
            enrichedContent.id,
            enrichedContent,
        ])
    )

    const translationMap = new Map<string, TranslatedTextView>(
        translations.map((translatedContent) => [
            translatedContent.sourceText,
            translatedContent,
        ])
    )

    const handleSelectionChange = (
        content: ContentOutcomeView,
        isSelected: boolean
    ) => {
        if (isSelected) {
            setSelectedContent(
                new Map([...selectedContent, [content.id, content]])
            )
        } else {
            const newSelectedContent = new Map([...selectedContent])
            newSelectedContent.delete(content.id)
            setSelectedContent(newSelectedContent)
        }
    }

    /* Show no results message if all queries have returned nothing and loading has finished
     */
    const showNoResults =
        !error && // There are no errors
        contents.length === 0 && // There are no contents
        !loading // The loading has finished

    // There are some results
    const showPagination = !showNoResults

    return (
        <div className={`stretchy`}>
            {/* Show the no results display if all queries have returned nothing and loading has finished */}
            {showNoResults ? <NoResults /> : null}
            {error ? (
                <QueryFailed errorMessage={error?.message} />
            ) : (
                <>
                    {enableBulkActions && (
                        <SelectedContentBar
                            selectedContent={selectedContent}
                            onClearSelectedContent={() =>
                                setSelectedContent(
                                    new Map<number, ContentOutcomeView>()
                                )
                            }
                            onActionClick={handleContentAction}
                            onSelectAll={() => {
                                const newSelectedContent = new Map<
                                    number,
                                    ContentOutcomeView
                                >()
                                contents.forEach((content) => {
                                    newSelectedContent.set(content.id, content)
                                })
                                setSelectedContent(
                                    new Map<number, ContentOutcomeView>([
                                        ...Array.from(
                                            selectedContent.entries()
                                        ),
                                        ...Array.from(
                                            newSelectedContent.entries()
                                        ),
                                    ])
                                )
                            }}
                        ></SelectedContentBar>
                    )}

                    {showPagination && (
                        <div className="p-3 bg-white z-2">
                            <Pagination
                                limit={limit}
                                offset={offset}
                                total={total}
                                onChange={(offset, limit) => {
                                    setOffset(offset < 0 ? 0 : offset)
                                    setLimit(limit)
                                }}
                                loading={countLoading}
                            />
                        </div>
                    )}

                    {loading && <ContentSkeleton />}

                    {contents.map((content) => {
                        // This item is selected if it exists in the map.
                        const isSelected = selectedContent.has(content.id)

                        let translatedText = translationMap.get(content.content)
                        return (
                            <div
                                className="flex flex-col items-stretch  hover:bg-gray-50 border-t border-solid  border-gray-300 "
                                key={content.id}
                            >
                                <ContentCard
                                    key={content.id}
                                    content={content}
                                    showAccount={showOwningAccount}
                                    onContentAction={handleContentAction}
                                    enriching={loadingEnrichedContentResults}
                                    translatedText={translatedText}
                                    translating={translateLoading}
                                    profileCache={profiles}
                                    enrichedContentMap={enrichedContentMap}
                                    actionCacheMap={actionCache}
                                    isSelected={isSelected}
                                    onSelectionChange={handleSelectionChange}
                                    enableBulkActions={enableBulkActions}
                                    searchParameters={searchParameters}
                                    score={scoresMap.get(content.id)}
                                />
                            </div>
                        )
                    })}

                    {showPagination && (
                        <div className="m-3">
                            <Pagination
                                limit={limit}
                                offset={offset}
                                total={total}
                                onChange={(offset, limit) => {
                                    setOffset(offset < 0 ? 0 : offset)
                                    setLimit(limit)
                                }}
                                loading={countLoading}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ContentList
