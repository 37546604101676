import manCityLogo from '../assets/man-city-logo.svg'
import liverpoolLogo from '../assets/liverpool-logo.png'
import sisuLogo from '../assets/SISU Logo_FINAL.png'
import lmaLogo from '../assets/lma-logo-web.png'
import { fetchUserAttributes } from '@aws-amplify/auth'

// This is a temporary hack, using locally stored logos, purely for demo purposes.
// If this is required beyond the demo we should improve the method we use, if not required it should be removed.
export async function getBrandLogo(
    setBrandLogo: (logo: string) => void
): Promise<void> {
    const userAttributes = await fetchUserAttributes()
    const email = userAttributes.email

    if (email?.includes('liverpool.com')) {
        setBrandLogo(liverpoolLogo)
    } else if (email?.includes('man-city.com')) {
        setBrandLogo(manCityLogo)
    } else if (email?.includes('sisu.com')) {
        setBrandLogo(sisuLogo)
    } else if (email?.includes('leaguemanagers.com')) {
        setBrandLogo(lmaLogo)
    }
}
export async function getEmail(
    setEmail: (logo: string) => void
): Promise<void> {
    const userAttributes = await fetchUserAttributes()
    const email = userAttributes.email || ""
    setEmail(email)
}
