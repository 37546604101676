import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export const SERVER_ERROR_TIMEOUT =
    'Your query to the database took too long to run'
export const NO_ACCOUNTS_FOR_TEAM_ERROR_TIMEOUT =
    'No accounts found for this team'
type Props = {
    errorMessage: string | undefined
}

function QueryFailed(props: Props) {
    const { errorMessage } = props

    const hasQueryTimedOut = !!errorMessage?.includes(SERVER_ERROR_TIMEOUT)
    const noAccountsForTeam = !!errorMessage?.includes(
        NO_ACCOUNTS_FOR_TEAM_ERROR_TIMEOUT
    )

    function generateErrorMessage() {
        if (hasQueryTimedOut) {
            return (
                <>
                    <h3 className=" flex flex-row text-gray-950">
                        <ExclamationTriangleIcon className="w-10 h-10 text-gray-950 mr-4" />
                        This search took too long and cannot be completed.
                    </h3>
                    <p>
                        Complex or large date range queries can sometimes time
                        out. Please try again with a smaller date range or a
                        simpler query.
                    </p>
                </>
            )
        }
        if (noAccountsForTeam) {
            return (
                <>
                    <h3 className=" flex flex-row text-gray-950">
                        <ExclamationTriangleIcon className="w-10 h-10 text-gray-950 mr-4" />
                        No profiles found for this team.
                    </h3>
                    <p>Please change the team and try again</p>
                </>
            )
        } else {
            return (
                <>
                    <h3 className=" flex flex-row text-gray-950">
                        <ExclamationTriangleIcon className="w-10 h-10 text-gray-950 mr-4" />
                        This search failed to complete.
                    </h3>
                    <p>
                        Please try again. If this problem persists please
                        contact{' '}
                        <a
                            href="mailto:support@arwen.ai"
                            className='           className="text-red-700 underline font-bold"'
                        >
                            support@arwen.ai
                        </a>
                    </p>
                </>
            )
        }
    }

    return (
        <div className="prose prose-stone prose-xlg mb-6 self-center">
            <div className="w-full">{generateErrorMessage()}</div>
        </div>
    )
}

export default QueryFailed
