import { ModerationBoundary } from '../../api/client'
import { ModerationBoundaryTemplate } from '../../models/Moderation'

type Props = {
    boundary: ModerationBoundary
    onEditClick: (boundary: ModerationBoundary) => void
    showCompare: boolean
    compareWith?: ModerationBoundaryTemplate
}
function ModerationBoundaryRow(props: Props) {
    const { boundary, onEditClick, showCompare, compareWith } = props

    const sameBgClassName = 'bg-green-100'
    const differentBgClassName = 'bg-amber-100'

    let compareWithClassName = []
    if (showCompare) {
        if (compareWith?.probability && boundary.probability) {
            if (compareWith?.probability === boundary.probability) {
                compareWithClassName.push(sameBgClassName)
            } else {
                compareWithClassName.push(differentBgClassName)
            }
        } else if (compareWith?.severityCategory && boundary.severityCategory) {
            if (compareWith?.severityCategory === boundary.severityCategory) {
                compareWithClassName.push(sameBgClassName)
            } else {
                compareWithClassName.push(differentBgClassName)
            }
        }

        if (!!!compareWith) {
            // It is not defined in the template - this field will be deleted
            compareWithClassName.push(differentBgClassName)
        }
    }
    const compareWithDisplay = compareWith ? (
        <div className={compareWithClassName.join(' ')}>
            {compareWith.probability
                ? compareWith.probability
                : compareWith.severityCategory}
        </div>
    ) : null

    return (
        <tr key={boundary.id} className={compareWithClassName.join(' ')}>
            <td className="whitespace-nowrap px-2 py-2 text-sm  text-gray-950">
                {boundary.classifier.displayName}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {boundary.severityCategory}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {boundary.probability}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {compareWithDisplay}
            </td>
            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm  sm:pr-6">
                <button
                    onClick={() => {
                        onEditClick(boundary)
                    }}
                    className="text-primary hover:text-primary-900"
                >
                    Edit
                </button>
            </td>
        </tr>
    )
}

export default ModerationBoundaryRow
