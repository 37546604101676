import { XMarkIcon } from '@heroicons/react/24/solid'
import DebouncedInput from './table/DebouncedInput'

type Props = {
    keyword: string
    onKeywordChange: (keyword: string) => void
    onSearch: (keyword: string) => void
}

/**
 * This is a simple component - it is just to do with UI and externalises the search keyword and search function
 *
 * So it not responsible for storing the search keyword in the URL, not is it responsible for deciding when
 * to initiate a search.
 *
 */
function SearchInput(props: Props) {
    const { keyword, onKeywordChange, onSearch } = props

    function handleChange(value: string | number) {
        onKeywordChange(value.toString())
    }

    function doSearchReset() {
        onKeywordChange('')
        onSearch('')
    }

    return (
        <div className="flex">
            <div className="flex shadow-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10  ">
                    <DebouncedInput
                        value={keyword}
                        onChange={handleChange}
                        onKeyUp={(event) => {
                            if (event.key === 'Escape') {
                                onKeywordChange('')
                                onSearch('')
                            }
                        }}
                        placeholder="Search..."
                        className="focus:ring-primary-500 focus:border-primary block rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300 hover:border-gray-400"
                    />
                </div>
                <button
                    type="button"
                    className="-ml-px relative inline-flex items-center border rounded-r  text-gray-500 hover:text-gray-950 space-x-2 px-2 py-2 text-sm  hover:bg-gray-100  bg-white hover:border-gray-400 border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary"
                    onClick={doSearchReset}
                >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
    )
}

export default SearchInput
