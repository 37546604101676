import { useNavigate, useParams } from 'react-router-dom'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import SimpleLoader from '../components/SimpleLoader'
import {
    AccountRecord,
    useGetAccountQuery,
    useUpdateShadowBanningMutation,
} from '../api/client'
import ShadowBanningForm from '../components/account/settings/ShadowBanningForm'
import { toastError, toastSuccess } from '../components/Notification'

type ParamTypes = {
    accountId: string
}

function AccountShadowBanningSettingsPage() {
    const { accountId } = useParams<ParamTypes>()
    const navigate = useNavigate()

    const parsedAccountId = parseInt(accountId as string)
    const { data: accountData, loading: accountLoading } = useGetAccountQuery({
        variables: { accountId: parsedAccountId },
    })

    const [updateShadowBanningMutation, { loading: savingShadowBanning }] =
        useUpdateShadowBanningMutation()

    const account = accountData?.getAccount

    async function onSave(updatedAccount: AccountRecord) {
        console.log('save', updatedAccount)
        await updateShadowBanningMutation({
            variables: {
                accountId: updatedAccount.id,
                enabled: updatedAccount.shadowBanningEnabled,
                // The absolute state of this. Typescript says this is a number and its defined as a number all the way to the form. But it is a string when it gets sent. So.... convert to a number.
                threshold: Number(updatedAccount.shadowBanningThreshold),
                rangeInDays: Number(updatedAccount.shadowBanningRangeInDays),
            },
            onCompleted: () => {
                toastSuccess('Save successful')

                navigate(`/account/${accountId}/settings/`)
            },
            onError: (error) => {
                console.error(error)
                toastError(
                    'Failed to update shadow banning settings - please contact support.'
                )
            },
        })
    }

    function onCancel() {
        navigate(-1)
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="Edit shadow-banning settings" />
            {accountLoading && <SimpleLoader loading={true} />}
            {account && (
                <>
                    <div className="flex flex-col mb-32">
                        <ShadowBanningForm
                            account={account}
                            onSave={onSave}
                            onCancel={onCancel}
                            saving={savingShadowBanning}
                        />
                    </div>
                </>
            )}
        </TextPageContainer>
    )
}

export default AccountShadowBanningSettingsPage
