import ArwenLogoWhite from '../../assets/brand/arwen-logo-white-large.png'
import SocialSwapLogo from '../../assets/referrer/heineken/logonuovo_3.png'
import HeinekenLogoWhite from '../../assets/referrer/heineken/heineken_logo_white.png'
import Background from '../../assets/referrer/heineken/background_1.png'
import CrossIcon from '../../assets/referrer/heineken/cross.png'
import { classNames } from '../../util/classNames'

type Props = {
    authenticator: React.ReactNode
    allowTrial: boolean
    signUpEnabled: boolean
    hasReachedUserLimit: boolean
}

function HeinekenWelcome(props: Props) {
    const { authenticator, allowTrial, signUpEnabled, hasReachedUserLimit } =
        props

    const generateAuthenticator = () => {
        if (!signUpEnabled) {
            return (
                <div className="rounded-lg lg:p-16 p-4 text-center backdrop-blur-xl">
                    <h1
                        className="text-white text-7xl font-abril"
                        style={{ fontFamily: 'HeinekenBold' }}
                    >
                        Sorry - this campaign has now ended.
                    </h1>

                    <p className="text-white text-3xl pt-4">
                        But you can find out more about how to stay safe online
                        at{' '}
                        <a
                            href="http://arwen.ai"
                            className="text-primary-700 underline"
                        >
                            arwen.ai
                        </a>
                    </p>
                </div>
            )
        }

        if (hasReachedUserLimit) {
            return (
                <div className="rounded-lg border-white  text-center backdrop-blur-xl lg:p-16 p-4">
                    <h1
                        className="text-white text-7xl"
                        style={{ fontFamily: 'HeinekenBold' }}
                    >
                        Sorry - we’re full today!
                    </h1>
                    <p className="text-white text-3xl pt-4">
                        Come back tomorrow when more Arwen accounts will be
                        available.
                    </p>
                </div>
            )
        }

        return (
            <div className="border rounded-lg border-white p-4">
                {authenticator}
            </div>
        )
    }

    const generateProductDetails = (classes: string) => {
        let trialDetails = 'Cheers to your 90 day free trial!'
        let productDescription = `The Arwen tool lets users filter negativity from
                                their social media feeds by hiding any unwanted or
                                offensive comments and spam. Sign up for a 90-day
                                free trial and enjoy real-time protection from spam
                                and toxic comments on X, Instagram, Facebook, and
                                YouTube.`

        if (!allowTrial) {
            trialDetails = 'Protect your socials for just £29.99 per month!'
            // Exclude mention of a free trial
            productDescription = `The Arwen tool lets users filter negativity from
            their social media feeds by hiding any unwanted or
            offensive comments and spam. Sign up now and enjoy real-time protection from spam
            and toxic comments on X, Instagram, Facebook, and
            YouTube.`
        }
        return (
            <div
                className={classNames(
                    'xl:ml-14 xl:mb-10 align-middle object-contain max-w-sm text-white pt-6',
                    classes
                )}
            >
                <h2
                    className="text-4xl font-bold text-center"
                    style={{ fontFamily: 'HeinekenBold' }}
                >
                    {signUpEnabled && !hasReachedUserLimit ? trialDetails : ''}
                </h2>

                {/* HACK alert: Heineken are no longer offering a paid trail so some of the code in this file is unnecessary. At the moment the paid trial referrer has a 90-day trail notice here.
                 */}
                {signUpEnabled && !hasReachedUserLimit ? (
                    <div className="flex flex-col mt-6 text-white rounded-lg max-w-2xl px-6 py-6 backdrop-blur-xl space-y-4 ">
                        <p>{productDescription}</p>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    const generateContent = () => {
        return (
            <div className="flex flex-col lg:pt-32 items-center flex-grow">
                <div className="flex flex-col lg:mt-32 items-center">
                    <div className="flex xl:flex-row flex-col xl:items-baseline items-center xl:mb-14">
                        <img
                            alt="logo"
                            src={HeinekenLogoWhite}
                            className="xl:mx-4 mb-4 align-middle object-contain md:w-44 w-40"
                        />
                        <img
                            alt="logo"
                            src={CrossIcon}
                            className="xl:mx-4 mb-4 align-middle object-contain md:w-10 w-7"
                        />
                        <img
                            alt="logo"
                            src={ArwenLogoWhite}
                            className="xl:mx-4 mb-4 align-middle object-contain md:w-44 w-40"
                        />
                    </div>

                    <div className="flex xl:flex-row items-center flex-col">
                        <img
                            alt="logo"
                            src={SocialSwapLogo}
                            className="xl:mr-14 xl:mb-10 align-middle object-contain md:w-96 xl:w-96 w-80"
                        />

                        {generateProductDetails(
                            'xl:hidden mb-12 md:w-80 w-72 '
                        )}
                        {generateAuthenticator()}
                        {generateProductDetails(' xl:inline hidden')}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div
                className="flex flex-col items-center  bg-white h-screen w-screen fixed "
                style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: 'cover',
                    zIndex: -1,
                }}
            ></div>
            {generateContent()}
        </>
    )
}

export default HeinekenWelcome
