import { InputHTMLAttributes, useEffect, useState } from 'react'

/**
 * This component is designed to debounce the onChange event of an input.
 * It will avoid firing the onChange event until the user has stopped typing for a certain amount of time.
 * This can be useful when a change kicks off a rerender or a network request.
 *
 * See README.md in this directory
 *
 * NOTE: This code comes from The tTanstack React Table v8 filters example https://tanstack.com/table/v8/docs/examples/react/filters
 */
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-medium focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    )
}

export default DebouncedInput
