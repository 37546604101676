import { SpeakerXMarkIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
} from '../../../api/client'
import ActionGenericButton from './GenericActionButton'

type Props = {
    content: ContentOutcomeView
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    actions: ContentActionView[]
}

export default function MuteButton(props: Props) {
    const { content, onActionClick, actions } = props

    return (
        <ActionGenericButton
            content={content}
            onActionClick={onActionClick}
            action={ContentAction.Mute}
            tooltip="Mute/unmute this user"
            colour="yellow"
            icon={<SpeakerXMarkIcon />}
            actions={actions}
        />
    )
}
