import { Dialog } from '@headlessui/react'
import cloneDeep from 'lodash/cloneDeep'
import {
    ModerationBoundary,
    useUpdateModerationBoundaryMutation,
} from '../../api/client'
import { BoundaryType, SeverityCategory } from '../../models/Moderation'

type Props = {
    onClose: () => void
    onCloseAndSave: () => void
    selectedBoundary?: ModerationBoundary
    onSelectedBoundaryUpdate: (moderationBoundary: ModerationBoundary) => void
}

function EditModerationBoundary(props: Props) {
    const {
        onClose,
        onCloseAndSave,
        selectedBoundary,
        onSelectedBoundaryUpdate,
    } = props

    const [updateModerationBoundaryMutation] =
        useUpdateModerationBoundaryMutation()

    async function handleSave() {
        if (selectedBoundary && selectedBoundary.id && selectedBoundary?.type) {
            await updateModerationBoundaryMutation({
                variables: {
                    moderationBoundaryInput: {
                        id: selectedBoundary?.id,
                        type: selectedBoundary?.type,
                        probability: selectedBoundary?.probability,
                        severityCategory: selectedBoundary?.severityCategory,
                    },
                },
            })
        }
        onCloseAndSave()
    }

    return (
        <>
            <div>
                <div className="mt-2 sm:mt-4">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6  text-gray-950"
                    >
                        Editing Moderation Boundary
                    </Dialog.Title>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {selectedBoundary?.type === BoundaryType.NUMERICAL ? (
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="probability"
                                    className="block text-sm  text-gray-700"
                                >
                                    Probability
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="number"
                                        name="probability"
                                        id="probability"
                                        className="w-full shadow-sm focus:ring-primary-500 focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                                        value={
                                            // Default to 0 if probability is undefined. We previously used an empty string which thoroughly confused the input control.
                                            selectedBoundary?.probability
                                                ? selectedBoundary?.probability
                                                : 0
                                        }
                                        step="0.01"
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            const boundaryCopy =
                                                cloneDeep(selectedBoundary)
                                            if (boundaryCopy) {
                                                boundaryCopy.probability =
                                                    Number.parseFloat(
                                                        event.target.value
                                                    )
                                                onSelectedBoundaryUpdate(
                                                    boundaryCopy
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {selectedBoundary?.type === BoundaryType.CATEGORICAL ? (
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="severityCategory"
                                    className="block text-sm  text-gray-700"
                                >
                                    Severity Category
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="severityCategory"
                                        name="severityCategory"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary sm:text-sm rounded-md"
                                        value={
                                            selectedBoundary?.severityCategory
                                                ? selectedBoundary?.severityCategory
                                                : ''
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<HTMLSelectElement>
                                        ) => {
                                            const boundaryCopy =
                                                cloneDeep(selectedBoundary)
                                            if (boundaryCopy) {
                                                boundaryCopy.severityCategory =
                                                    event.target.value
                                                onSelectedBoundaryUpdate(
                                                    boundaryCopy
                                                )
                                            }
                                        }}
                                    >
                                        <option></option>
                                        <option>
                                            {SeverityCategory.EXTREME}
                                        </option>
                                        <option>{SeverityCategory.HIGH}</option>
                                        <option>
                                            {SeverityCategory.MEDIUM}
                                        </option>
                                        <option>{SeverityCategory.LOW}</option>
                                        <option>{SeverityCategory.NONE}</option>
                                        <option>
                                            {SeverityCategory.NOTDETECTED}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base  text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                    onClick={() => handleSave()}
                >
                    Save
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base  text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:text-sm"
                    onClick={() => onClose()}
                >
                    Cancel
                </button>
            </div>
        </>
    )
}

export default EditModerationBoundary
